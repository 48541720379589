import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-art',
  templateUrl: './art.component.html'
})
export class ArtComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
