import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'offerUploadHistory',
  templateUrl: './offer-upload-history.component.html',
  styleUrls: ['./offer-upload-history.component.css']
})
export class OfferUploadHistoryComponent implements OnInit {
  pageTitle='OfferUploadHistory'

  @Input('allowedActions') allowedActions: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
