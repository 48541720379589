import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private url = 'http://jsonplaceholder.typicode.com/posts';
  public token!: "BKm7qwb3UwrXZO2BSp42utobkUpJK3eV";




  userAuthentication(user: { clientId: any; username: any; password: any; }){
    let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('apiToken')});
    return this.http.post(
          environment.apiCredentials.apiDomain+'/common/userAuth',
          JSON.stringify({
                clientId:user.clientId,
                username:user.username,
                password:user.password
          }),
          { headers: headers }
    
    );
}

userTokenAuthentication(){
    let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
    return this.http.post(
          environment.apiCredentials.apiDomain+'/common/userSessionAuth',
          JSON.stringify({
          }),
          { headers: headers }
    ).pipe(
    );
}




  goToAccessories(): any {
    throw new Error('Method not implemented.');
  }
  goToApparel(): any {
    throw new Error('Method not implemented.');
  }
  goToAll(): any {
    throw new Error('Method not implemented.');
  }


  goToArt(): any {
    throw new Error('Method not implemented.');
  }
  goToBoutique(): any {
    throw new Error('Method not implemented.');
  }
  goToCosmetics(): any {
    throw new Error('Method not implemented.');
  }
  goToChocolateSupplier(): any {
    throw new Error('Method not implemented.');
  }
  goToDailyNeeds(): any {
    throw new Error('Method not implemented.');
  }

  http: any;
  constructor(private httpClient: HttpClient) { }

  fetchoffers(): Observable<Object> {
    return this.http.get('https://app.candidoffers.com:3636/api/co-trans/getVendorRegistration');
  }
// Try
getPosts(){
  return this.httpClient.get(this.url);
}


}