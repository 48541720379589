import { Component, ContentChildren, QueryList, AfterContentInit, ViewChild, ComponentFactoryResolver, ViewContainerRef, ElementRef } from '@angular/core';

import { TabComponent } from './tab.component';
import { DynamicTabsDirective } from './dynamictab.directive';

@Component({
      selector: 'tabs-panel',
      template: `
            <div id="header-navigtion">
                  <ul>
                  <li *ngFor="let tab of tabs" (click)="selectTab(tab)" [class.active]="tab.active">
                              <a>Dashboard</a>
                        </li>
                        <!-- dynamic tabs -->
                        <li *ngFor="let tab of dynamicTabs" (click)="selectTab(tab)" [class.active]="tab.active">
                              <a>
                                    {{tab.title}} <input type="hidden" value="{{tab.code}}" #dynamicTabCheck  />
                                    <span class="tab-close" *ngIf="tab.isCloseable" (click)="closeTab(tab)">
                                          <i class="fas fa-times"></i>
                                    </span>
                              </a>
                        </li>
                  </ul>
            </div>
            <ng-content></ng-content>
            <ng-template dynamic-tabs></ng-template>
      `
})

export class TabsPanelComponent implements AfterContentInit {
      dynamicTabs: TabComponent[] = [];

      @ContentChildren(TabComponent)
      tabs: QueryList<TabComponent>;

      @ViewChild(DynamicTabsDirective)
      dynamicTabPlaceholder: DynamicTabsDirective;

      @ViewChild('dynamicTabCheck') dynamicTabCheck: ElementRef<any>;

      constructor(private _componentFactoryResolver: ComponentFactoryResolver) { }
      ngAfterContentInit() {
            let activeTabs = this.tabs.filter((tab) => tab.active);
            if (activeTabs.length === 0) {
                  this.selectTab(this.tabs.first);
            }
      }

      openTab(title: string, template, isCloseable = false, code) {
            //console.log(template);
            let isTabOpened:boolean = false;
            this.dynamicTabs.forEach((tab,i) => {
                  if(tab.code === code){
                        this.selectTab(this.dynamicTabs[i]);
                        isTabOpened = true;
                  }
            });
            if(!isTabOpened){
                  let componentFactory = this._componentFactoryResolver.resolveComponentFactory(TabComponent);
                  let viewContainerRef = this.dynamicTabPlaceholder.viewContainer;

                  let componentRef = viewContainerRef.createComponent(componentFactory);
                  //console.log(componentRef);
                  let instance: TabComponent = componentRef.instance as TabComponent;
                  instance.title = title;
                  instance.template = template;
                  instance.isCloseable = isCloseable;
                  instance.code = code;
                  this.dynamicTabs.push(componentRef.instance as TabComponent);
                  
                  this.selectTab(this.dynamicTabs[this.dynamicTabs.length - 1]);
                  //console.log(this.dynamicTabCheck.nativeElement.value);
            }
      }

      selectTab(tab: TabComponent) {
            this.tabs.toArray().forEach(tab => tab.active = false);
            this.dynamicTabs.forEach(tab => tab.active = false);
            tab.active = true;
      }

      closeTab(tab: TabComponent) {
            for (let i = 0; i < this.dynamicTabs.length; i++) {
                  if (this.dynamicTabs[i] === tab) {
                        this.dynamicTabs.splice(i, 1);
                        let viewContainerRef = this.dynamicTabPlaceholder.viewContainer;
                        viewContainerRef.remove(i);
                        if(this.dynamicTabs.length > 0){
                              this.selectTab(this.dynamicTabs[this.dynamicTabs.length - 1]);
                        }else{
                              this.selectTab(this.tabs.first);
                        }
                        break;
                  }
            }
      }

      closeActiveTab() {
            let activeTabs = this.dynamicTabs.filter((tab) => tab.active);
            if (activeTabs.length > 0) {
                  this.closeTab(activeTabs[0]);
            }
      }
}