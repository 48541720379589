import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Renderer2, ElementRef } from "@angular/core";
import { CommonService } from "src/app/shared/services/common.service";
import { Router } from "@angular/router";

@Component({
  selector: "forgot-password-web",
  templateUrl: "./forgot-password-web.component.html",
})
export class ForgotPasswordWebComponent {
  loading: boolean = false;
  generatedMailOTP: string = "";
  mailVerified: boolean = false;
  deleteForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private commonService: CommonService,
    private renderer: Renderer2,
    private el: ElementRef,
    private router: Router
  ) {}

  ngOnInit() {
    this.deleteForm = this.fb.group(
      {
        email: ["", [Validators.required, Validators.email]],
        newPassword: ["", [Validators.required, Validators.minLength(8)]],
        confirmPassword: ["", [Validators.required, Validators.minLength(8)]],
        emailOTP: ["", Validators.maxLength(4)],
      },
      {
        validator: this.passwordMatchValidator, // Add custom validator
      }
    );
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const newPassword = formGroup.get("newPassword")?.value;
    const confirmPassword = formGroup.get("confirmPassword")?.value;

    if (newPassword !== confirmPassword) {
      formGroup.get("confirmPassword")?.setErrors({ mismatch: true });
    } else {
      formGroup.get("confirmPassword")?.setErrors(null);
    }
  }

  get cf() {
    return this.deleteForm.controls;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  sendEmailOTP() {
    this.showLoading();
    const email = this.deleteForm.get("email")?.value;
    const emailSubject = "Candid Reset Password";
    this.generatedMailOTP =
      Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000 + "";
    const template = `<!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Forgot Password</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            background-color: #f4f4f4;
            color: #333;
          }
          .container {
            max-width: 600px;
            margin: 0 auto;
            padding: 20px;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          h1 {
            color: #4285f4;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <h1>Forgot Password</h1>
          <p>Hello User,</p>
          <p>You have requested to reset your password.</p>
          <p>Your OTP (One-Time Password) for verification is: <strong>${this.generatedMailOTP}</strong></p>
          <p>Please use this OTP to complete the password reset process.</p>
      
          <p>If you didn't request this OTP, please ignore this email.</p>
      
          <footer>
            <p>Best regards,<br> Your Company Name</p>
          </footer>
        </div>
      </body>
      </html>`;

    this.commonService
      .backendAPICall("/common/forgotPasswordMail", {
        email1: email,
        emailSubject: emailSubject,
        template: template,
      })
      .subscribe(
        (response) => {
          this.hideLoading();
          console.log(response);

          if (response["responseStatus"] == "Ok") {
            alert(response["message"]);
            this.toggleClass("emailOTPVerification", "hidden", false);
            this.disableSendOTPButton("sendEmailOTP", 30);
            this.deleteForm
              .get("emailOTP")
              ?.setValidators([Validators.required, Validators.maxLength(4)]);
          } else {
            alert(response["message"]);
          }
        },
        (error) => {
          console.error("Error sending OTP to email:", error);
          alert("Failed to send OTP to email.");
          this.hideLoading();
        }
      );
  }

  verifyEmailOTP() {
    this.showLoading();
    const emailOTP = this.deleteForm.get("emailOTP")?.value;
    if (emailOTP === this.generatedMailOTP) {
      this.mailVerified = true;
      this.toggleClass("emailOTPVerification", "hidden", true);
      this.toggleClass("isMailVerified", "hidden", false);
      this.toggleClass("sendEmailOTP", "hidden", true);
      this.hideLoading();
    } else {
      alert("Incorrect OTP. Please try again.");
      this.hideLoading();
    }
  }

  disableSendOTPButton(buttonId: string, durationSeconds: number) {
    const button = this.el.nativeElement.querySelector(`#${buttonId}`);

    if (button) {
      let remainingSeconds = durationSeconds;
      const interval = setInterval(() => {
        remainingSeconds--;
        if (remainingSeconds <= 0) {
          clearInterval(interval);
          this.renderer.setProperty(button, "disabled", false);
          this.renderer.setProperty(button, "innerText", "Send OTP");
        } else {
          this.renderer.setProperty(
            button,
            "innerText",
            `Resend OTP (${remainingSeconds}s)`
          );
        }
      }, 1000);

      this.renderer.setProperty(button, "disabled", true);
    }
  }

  toggleClass(elementId: string, className: string, addClass: boolean) {
    const element = this.el.nativeElement.querySelector(`#${elementId}`);
    if (element) {
      if (addClass) {
        this.renderer.addClass(element, className);
      } else {
        this.renderer.removeClass(element, className);
      }
    }
  }

  submitRequest() {
    if (this.mailVerified) {
      this.showLoading();
      const formData = this.deleteForm.value;

      this.commonService
        .backendAPICall("/common/forgotPasswordByEmail", {
          email1: formData.email,
          password: formData.newPassword,
        })
        .subscribe(
          (response) => {
            this.hideLoading();
            console.log(response);

            if (response["responseStatus"] == "Ok") {
              alert(response["message"]);
              this.router.navigate(["/admin/login"]);
            } else {
              alert(response["message"]);
            }
          },
          (error) => {
            console.error("Error submitting account deletion request:", error);
            alert("Failed to reset password try after some time.");
            this.hideLoading();
          }
        );
    } else {
      alert("Please verify your email before submitting.");
    }
  }
}
