import {
  Component,
  ViewChild,
  Input,
  HostListener,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { environment } from "../../environments/environment";

@Component({
  selector: "payment-common",
  templateUrl: "payment-common.component.html",
  styleUrls: ["payment-common.component.scss"],
})
export class PaymentCommonComponent implements AfterViewInit {
  @ViewChild("paymentForm") paymentForm: ElementRef;
  @ViewChild("paymentIFrame") paymentIFrame;
  @Output() closePaymentAction = new EventEmitter();
  @Input() paymentData: any;

  transactionAmount: number = 2;
  refId: number = Math.floor(Math.random() * 1000);
  postUrl: string = "";
  paymentSubsbscription;
  tabRef: any;

  constructor() {
    this.postUrl =
      environment.apiCredentials.apiDomain.substring(
        0,
        environment.apiCredentials.apiDomain.lastIndexOf("/")
      ) + "/payment/request";
    (<any>window).reciveDataFromIframe = this.reciveDataFromIframe.bind(this);
  }

  ngAfterViewInit() {
    this.postUrl +=
      "?token=" +
      this.paymentForm.nativeElement.querySelector('input[name="token"]').value;
  }

  reciveDataFromIframe(event: MessageEvent) {
    console.log(event);
    this.load(event.data);
  }

  load(data) {
    console.log("data", data);
    // console.log("taRef", this.tabRef);
    this.closePayment();
  }

  procesPayment() {
    this.tabRef = window.open("", "paymentProcessTab");
    this.paymentForm.nativeElement.submit();
  }

  closePayment() {
    this.tabRef.close();
  }

  cancelPayment() {
    this.closePaymentAction.emit(false);
  }
}
