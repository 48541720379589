

<div class="contain">
    <div class="row d-flex align-items-center justify-content-center">
      <form class="col-md-6">
        <div class="card px-5 py-5">
          <div class="form-input">
            <input type="text" name="phoneNumber" placeholder="Phone Number" class="form-control"
              placeholder="Enter Phone Number" [(ngModel)]="phoneNumber" min="10" max="15">
          </div>
          <button class="btn btn-success mt-4 signup" (click)="getOTP()">Get OTP</button>
          <div id="sign-in-button"></div>
        </div>
      </form>
    </div>