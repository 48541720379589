export class Config {
  static sideMenuConfig = {
    paddingAtStart: true,
    classname: 'my-custom-class',
    listBackgroundColor: '#ffffff',
    fontColor: 'rgb(8, 54, 71)',
    backgroundColor: '#ffffff',
    selectedListFontColor: '#b9350d',
  };

  static customerRegtype: any[] = [
    { value: 1, display: 'Regular' },
    { value: 2, display: 'Prime Customer' },
  ];

  static registerUnderType: any[] = [
    { value: 1, display: 'Company' },
    { value: 2, display: 'Under Champion' },
  ];

  static partyType: any[] = [
    { value: 1, display: 'Debtors' },
    { value: 2, display: 'Creditors' },
  ];
  static offerType: any[] = [
    { value: '1', display: 'Product' },
    { value: '2', display: 'Services' },
  ];

  static itemImportFor: any[] = [
    {
      value: 1,
      display: 'Item',
      sampleFileUrl:
        'https://roseapi-production.s3.ap-south-1.amazonaws.com/imports_sample/item_import_sample.csv',
    },
    {
      value: 2,
      display: 'Item Rates',
      sampleFileUrl:
        'https://roseapi-production.s3.ap-south-1.amazonaws.com/imports_sample/item_rates_import_sample.csv',
    },
    {
      value: 3,
      display: 'Unit wise Rate',
      sampleFileUrl:
        'https://roseapi-production.s3.ap-south-1.amazonaws.com/imports_sample/item_unitwise_rates_import_sample.csv',
    },
    {
      value: 4,
      display: 'Unit Conversion',
      sampleFileUrl:
        'https://roseapi-production.s3.ap-south-1.amazonaws.com/imports_sample/item_unit_conversions_import_sample.csv',
    },
    {
      value: 5,
      display: 'Party Group wise Discount',
      sampleFileUrl:
        'https://roseapi-production.s3.ap-south-1.amazonaws.com/imports_sample/item_partygroupwise_discounts_import_sample.csv',
    },
  ];
  static partyImportFor: any[] = [
    {
      value: 1,
      display: 'Party',
      sampleFileUrl:
        'https://roseapi-production.s3.ap-south-1.amazonaws.com/imports_sample/party_import_sample.csv',
    },
    {
      value: 2,
      display: 'Party Shipping Location',
      sampleFileUrl:
        'https://roseapi-production.s3.ap-south-1.amazonaws.com/imports_sample/party_shipping_location_import_sample.csv',
    },
  ];

  static packageValidityPeriodType: any[] = [
    { value: 'D', display: 'Day' },
    { value: 'M', display: 'Month' },
    { value: 'Y', display: 'Year' },
  ];

  static tncFor: any[] = [
    { value: '1', display: 'End User' },
    { value: '2', display: 'Vendor' },
    { value: '3', display: 'Champion' },
    { value: '5', display: 'Super Champion' },
    { value: '4', display: 'Staff' },
  ];
  static gstRegType: any[] = [
    { value: 1, display: 'Un Register' },
    { value: 2, display: 'Regular' },
    { value: 3, display: 'Composition' },
    { value: 4, display: 'Consumer' },
  ];
  //new
  // static targetType: any[] = [
  //     {value:'N',display:"Number"},
  //     {value:'R',display:"Rupees"}
  // ]
  // kind ref types
  static kindRefType: any[] = [
    { value: 'V', display: "Viewer" },
    { value: 'O', display: "Offer" }
  ]
  // debitCredit types
  static debitCreditType: any[] = [
    { value: 'D', display: "Debit" },
    { value: 'C', display: "Credit" }
  ]

}
