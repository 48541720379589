import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../../shared/services/alert.service';
import { CommonService } from '../../../shared/services/common.service';

@Component({
  // selector: 'router-outlet',
  templateUrl: './login.component.html',
  styleUrls: ['./login.scss'],
})
export class LoginComponent implements OnInit {
  title = 'Login Component';
  user: any = {};
  loading = false;
  error = '';
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private commonService: CommonService,
    private alertSerive: AlertService,
    private activatedRoute: ActivatedRoute
  ) {
    this.user = {
      username: '',
      password: '',
    };
  }

  // dialogRef;
  ngOnInit() {}

  login() {
    this.loading = true;
    this.commonService.userAuthentication(this.user).subscribe(
      (result) => {
        //console.log(result);
        if (result['responseStatus'] == 'Ok') {
          localStorage.setItem('userToken', result['userToken']);
          localStorage.setItem('isLogged', 'true');
          localStorage.setItem('userData', JSON.stringify(result['userData']));
          localStorage.setItem(
            'currentModuleData',
            JSON.stringify(
              result['userData']['user']['currentSelectionData']['module']
            )
          );
          // Set Current BranchData ( Default Branch )
          //location.reload();
          let defaultModule =
            result['userData']['user']['currentSelectionData']['module'][
              'moduleName'
            ].toLowerCase();
            
          this.router.navigate(['admin', defaultModule]);
        } else {
          this.alertSerive.create(
            'Authentication Failed',
            'error',
            0,
            result['message']
          );
        }
        this.loading = false;
      },
      (error) => {
        //console.log("E::"+error);
        this.loading = false;
        this.alertSerive.create('API Error', 'error', 0, error);
      }
    );
  }

  // openDialog() {
  //       this.dialogRef = this.dialog.open(ForgotComponent, {
  //         panelClass: 'companySelectionPanel',
  //         autoFocus: false,
  //         disableClose: true,
  //       });
  //     }
  //     close() {
  //       this.dialogRef.close();
  //     }

  //     forgotPassword(){
  //       this.dialogRef = this.dialog.open(ForgotComponent, {
  //         panelClass: 'companySelectionPanel',
  //         autoFocus: false,
  //         disableClose: true,
  //       });
  //     }
}
