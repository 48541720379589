// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiCredentials: {
  //   apiDomain: "http://localhost:3636/api",
  //   user: "candid",
  //   pass: "offers@Candid$938@123%",
  //   key: "879065"
  // },
  apiCredentials: {
    apiDomain: "https://candidoffers.com:3636/api",
    user: "candid",
    pass: "offers@Candid$938@123%",
    key: "879065"
  },
  firebase: {
    apiKey: "AIzaSyBVrS_MD3iRoe3I6OL3mqeTmwcoMIn4I8c",
    authDomain: "candid-cf9fc.firebaseapp.com",
    databaseURL: "https://candid-cf9fc-default-rtdb.firebaseio.com",
    projectId: "candid-cf9fc",
    storageBucket: "candid-cf9fc.appspot.com",
    messagingSenderId: "657344197924",
    appId: "1:657344197924:web:0a35bd056af3d9b6362fb1",
    measurementId: "G-WTQC8F6Y12"
  },
  razorpay: {
    key: "rzp_live_mXMqD6Uq31IPNc"
  }
  // firebase : {
  // apiKey: "AIzaSyBVrS_MD3iRoe3I6OL3mqeTmwcoMIn4I8c",
  // authDomain: "candid-cf9fc.firebaseapp.com",
  // projectId: "candid-cf9fc",
  // storageBucket: "candid-cf9fc.appspot.com",
  // messagingSenderId: "657344197924",
  // appId: "1:657344197924:web:0a35bd056af3d9b6362fb1",
  // measurementId: "G-WTQC8F6Y12"
  // }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
