import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { retry } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { AlertService } from "./alert.service";
import { ListActionsComponentService } from "./list-actions-component.service";

interface ReportResponse {
  status: string;
  data: any[];
  message?: string;
}

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public token: string = "Test Token";
  constructor(
    private http: HttpClient,
    private router: Router,
    private alertService: AlertService,
    private listActionsComponentService: ListActionsComponentService
  ) { }

  // Following Is Common Event For api
  backendAPICall(eventName: string = "", dataObj: any = {}) {
    if (eventName) {
      let headers = new HttpHeaders({ "Content-Type": "application/json" });
      return this.http.post(
        environment.apiCredentials.apiDomain + eventName.trim(),
        JSON.stringify(dataObj),
        { headers: headers }
      );
    }
  }
  uploadImage(url: string, formData: FormData) {
    if (url) {
      return this.http.post(
        environment.apiCredentials.apiDomain + url.trim(),
        formData
      );
    }
  }
  backendApiAuth(eventName: string = "", dataObj: any = {}) {
    if (eventName) {
      let headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      });
      return this.http.post(
        environment.apiCredentials.apiDomain + eventName.trim(),
        JSON.stringify(dataObj),
        { headers: headers }
      );
    }
  }

  apiAuthenticate() {
    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain + "/common/apiAuth",
        JSON.stringify({
          user: environment.apiCredentials.user,
          pass: environment.apiCredentials.pass,
          key: environment.apiCredentials.key,
        }),
        { headers: headers }
      )
      .pipe(retry(2));
  }
  userAuthentication(user) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain + "/common/userAuth",
        JSON.stringify({
          clientId: user.clientId,
          username: user.username,
          password: user.password,
        }),
        { headers: headers }
      )
      .pipe(retry(2));
  }

  userTokenAuthentication() {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain + "/common/userSessionAuth",
        JSON.stringify({}),
        { headers: headers }
      )
      .pipe();
  }

  getUserMenus(forModuleId) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain + "/common/getUserMenus",
        JSON.stringify({
          forModuleId: forModuleId,
        }),
        { headers: headers }
      )
      .pipe();
  }
  documentRequiredCheck(filter) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain + "/support/documentRequired",
        JSON.stringify(filter),
        { headers: headers }
      )
      .pipe();
  }

  signUpUser(userObj) {
    // console.log('common service userobj',userObj ) ;
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain + "/common/addUser",
        JSON.stringify({
          //    clientId:userObj.clientId,
          username: userObj.userName,
          password: userObj.password,
          cityId: userObj.cityId,
          email: userObj.email,
          gender: userObj.gender,
          mobile1: userObj.mobile1,
          userrole: userObj.LoginAs,
        }),
        { headers: headers }
      )
      .pipe();
  }

  updateCurrentSelectionData(currentSelectionData) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain +
        "/common/updateUserCurrentSelection",
        JSON.stringify({
          currentSelectionData: currentSelectionData,
        }),
        { headers: headers }
      )
      .pipe();
  }

  logout() {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http
      .post(
        environment.apiCredentials.apiDomain + "/common/userLogout",
        JSON.stringify({}),
        { headers: headers }
      )
      .pipe(retry(2));
  }

  redirectToLogin(): void {
    this.alertService.create(
      "Session Expired",
      "error",
      20,
      "Your session expired, please login again.",
      () => {
        localStorage.removeItem("userToken");
        localStorage.removeItem("isLogged");
        localStorage.removeItem("userData");
        localStorage.removeItem("currentBranchData");
        localStorage.removeItem("currentCompanyData");
        localStorage.removeItem("currentModuleData");
        localStorage.removeItem("currentFinancialYear");
        localStorage.removeItem("menuActionsData");
        //console.log('callback');
        this.router.navigate(["/"]);
      }
    );
  }

  subscribeMenuActionsToEvent(actionDataForMenu, forMenu): Array<any> {
    let actionSubscription: Array<any> = [];
    if (actionDataForMenu) {
      for (let [key, actObj] of Object.entries(actionDataForMenu)) {
        //console.log(actObj['clickEvent']);
        actionSubscription[key] = this.listActionsComponentService
          .on(actObj["clickEvent"])
          .subscribe(() => forMenu[`${actObj["clickEvent"]}`]());
      }
    }
    return actionSubscription;
  }

  unSubscribeMenuActions(subscriberObj) {
    for (let k in subscriberObj) {
      subscriberObj[k].unsubscribe();
    }
  }

  setAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched();
    for (let i in group.controls) {
      if (group.controls[i] instanceof FormControl) {
        group.controls[i].markAsTouched();
      } else {
        this.setAsTouched(group.controls[i]);
      }
    }
  }

  getCities(filter: object = { status: "A" }) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/common/getCities",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  getStates(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/common/getStates",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  tempAuth(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/common/tempAuth",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  getChampions(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/common/getChampions",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  verifyReferralId(referralId: string): Observable<any> {
    return this.http.post<any>(
      `${environment.apiCredentials.apiDomain}/co-trans/verifyReferralId`,
      { referralId },
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
        }),
      }
    );
  }

  addChampion(token: string = "", eventName: string = "", dataObj: any = {}) {
    if (eventName) {
      let headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      });
      return this.http.post(
        environment.apiCredentials.apiDomain + eventName.trim(),
        JSON.stringify(dataObj),
        { headers: headers }
      );
    }
  }

  championSubscriptionPackageRequest(
    token: string = "",
    eventName: string = "",
    dataObj: any = {}
  ) {
    if (eventName) {
      let headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
      return this.http.post(
        environment.apiCredentials.apiDomain + eventName.trim(),
        JSON.stringify(dataObj),
        { headers: headers }
      );
    }
  }

  sendRejectionSMS(name: string, reason: string, mobile: string, championName: string, championMobile: string) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });

    // Make sure each parameter goes to the correct place
    const data = {
      name,
      mobile,  // This should be the user's mobile number
      vars: {
        reason,  // This should be "INCOMPLETEDOCUMENTS" or similar text
        championName,  // This should be the name of the champion
        championMobile  // This should be the champion's mobile number
      }
    };

    return this.http.post(
      environment.apiCredentials.apiDomain + "/common/send-rejection-sms",
      JSON.stringify(data),
      { headers: headers }
    );
  }

  sendkycsms(name: string, mobile: string, championName: any, championMobile: any) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    const data = { name, mobile, vars: { championName, championMobile } };
    return this.http.post(
      environment.apiCredentials.apiDomain + "/common/send-kyc-sms",
      JSON.stringify(data),
      { headers: headers }
    );
  }

  sendselleridsms(name: string, mobile: string, vendorUid: any, championName: any, championMobile: any) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    const data = { name, mobile, vars: { vendorUid, championName, championMobile } };
    return this.http.post(
      environment.apiCredentials.apiDomain + "/common/send-sellerid-sms",
      JSON.stringify(data),
      { headers: headers }
    );
  }

  showSuccessMsg(message: string) {
    // You can customize this to use your preferred notification system
    // For now, we'll use a simple alert
    alert(message);
  }

  showErrorMsg(message: string) {
    // You can customize this to use your preferred notification system
    // For now, we'll use a simple alert
    alert(message);
  }

  generateReport(payload: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });

    // For file downloads, we need to specify responseType as 'blob'
    const responseType = payload.ResponseType === 'file' ? 'blob' : 'json';

    return this.http.post(
      environment.apiCredentials.apiDomain + "/support/generateReport",
      JSON.stringify(payload),
      {
        headers: headers,
        responseType: responseType as 'json'
      }
    );
  }
}
