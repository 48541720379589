import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class GridService {

    constructor(private datePipe: DatePipe) { }

    dateFormater(date: Date, format: string): string {
      return this.datePipe.transform(date, format);
    }

    statusFormatter(param) {
        let statusObj = { 'A': 'Active', 'D': 'De-Active' };
        return statusObj[param.data.status];
    }
    debitCreditFormatter(param) {
        let debitCreditObj = { 'D': 'Debit', 'C': 'Credit' };
        return debitCreditObj[param.data.debitCredit];
    }

    payoutTypeFormatter(param) {
        let statusObj = { 'V': 'Viewer', 'O': 'Offers' };
        return statusObj[param.data.kindRefType];
    }

    isDistrictFormatter(param) {
        let isDistrictObj = { 'Y': 'Yes', 'N': 'No' };
        return isDistrictObj[param.data.isDistrict];
    }

    serviceActiveFormatter(param) {
        let serviceActiveObj = { 'Y': 'Yes', 'N': 'No' };
        return serviceActiveObj[param.data.serviceActive];
    }

    yesnoFormatter(param) {
        let ynObj = { 'Y': 'Yes', 'N': 'No' };
        return ynObj[param.data.includeSmallCarton];
    }

    menuTypeFormatter(param) {
        let menuTypeObj = { 'P': 'Parent', 'S': 'Sub Menu' };
        return menuTypeObj[param.data.menuType];
    }

    moduleDeviceFormatter(param) {
        let deviceAppObj = { "1": "Desktop", "2": "Mobile" };
        let deviceAppArr = param.data.device.split(',');
        let deviceAppStr = "";
        for (let k of deviceAppArr) {
            deviceAppStr += deviceAppObj[k] + ' ,';
        }
        return deviceAppStr.substr(0, deviceAppStr.length - 1);
    }

    userGroupTypeFormatter(param) {
        let userGroupTypeObj = { 'S': 'System', 'U': 'User' };
        return userGroupTypeObj[param.data.type];
    }

    gstTypeFormatter(param) {
        let gstTypeObj = { "1": "Un-Registered", "2": "Regular", "3": "Composition", "4": "Consumer" };
        return gstTypeObj[param.data.gstType];
    }
    customerTypeFormatter(param) {
        let customerTypeObj = { "1": "Regular", "2": "Prime-Customer" };
        return customerTypeObj[param.data.customerType];
    }

    partyTypeFormatter(param) {
        let partyTypeObj = { "1": "Debtors", "2": "Creditors" };
        return partyTypeObj[param.data.partyType];
    }

    offerTypeFormatter(param) {
        let offerTypeObj = { "1": "Product", "2": "Services" };
        return offerTypeObj[param.data.offerType];
    }

    homedeliveryFormatter(param) {
        let homedeliveryObj = { "1": "Yes", "2": "No" };
        return homedeliveryObj[param.data.homeDelivery];
    }

    genderFormatter(param) {
        let genderObj = { 'M': 'Male', 'F': 'Female' };
        return genderObj[param.data.gender];
    }
    primeCustomerFormatter(param) {
        let primeCustomerObj = { 'Y': 'Yes', 'N': 'No' };
        return primeCustomerObj[param.data.primeCustomer];
    }

    //Inventory Module Helpers

    invUOMTypeFormatter(param) {
        let invUOMTypeObj = { 'S': 'Standard', 'C': 'Custom' };
        return invUOMTypeObj[param.data.unitType];
    }

    itemImportForFormatter(param) {
        let importStatusObj = { '1': 'Item Details', '2': 'Item Rates', '3': 'Unit wise Rates ', '4': 'Unit Conversion', '5': 'Party Group wise Rate' };
        return importStatusObj[param.data.importFor];
    }

    partyImportForFormatter(param) {
        let importStatusObj = { '1': 'Party Details', '2': 'Party Shipping Locations' };
        return importStatusObj[param.data.importFor];
    }

    importProcessRowStatusFormatter(param) {
        let importProcessRowStatus = { 1: 'Processing', 2: 'Added', 3: 'Added With Errors', 4: 'Updated', 5: 'Updated With Errors', 6: 'Failed', 7: 'Ignored' }
        return importProcessRowStatus[param.data.rowStatus];
    }

    importProcessLogStringFormatter(param) {
        return param.data.rowProcessLog.length > 0 ? param.data.rowProcessLog.join(',') : '';
    }

    importStatusFormatter(param) {
        let importStatusObj = { '1': 'Uploaded', '2': 'Processing', '3': 'Completed', '4': 'Failed', '5': 'Partial Completed' };
        return importStatusObj[param.data.status];
    }

    balanceSheetScheduleTypeFormatter(param) {
        let userGroupTypeObj = { 'S': 'System', 'U': 'User' };
        return userGroupTypeObj[param.data.bssType];
    }

    isSubHeadFormatter(param) {
        let ynObj = { 'Y': 'Yes', 'N': 'No' };
        return ynObj[param.data.bssIsSubHead];
    }

    isCompulsoryFormatter(param) {
        let ynObj = { 'true': 'Yes', 'false': 'No' };
        return ynObj[param.data.isCompulsory];
    }

    answerChoiceFormatter(param) {
        let ynObj = { 'P': 'Predefined', 'T': 'Text Input' };
        return ynObj[param.data.answerChoice];
    }

    possibleAnswerFormatter(param) {
        let ynObj = { 'S': 'Single', 'M': 'Multiple' };
        return ynObj[param.data.possibleAnswer];
    }

    documentsApplicableForFormatter(param) {
        let docAppForObj = { "C": "Champions", "V": "Vendor", "S": "Staff" };
        let docAppForArr = param.data.applicableFor.split(',');
        let docAppForStr = "";
        for (let k of docAppForArr) {
            docAppForStr += docAppForObj[k] + ' ,';
        }
        return docAppForStr.substr(0, docAppForStr.length - 1);
    }

    // For Candid Offers

    registerFromFormatter(param) {
        let regFromObj = { "1": "Backend", "2": "Website", "3": "App" };
        return regFromObj[param.data.registerFrom];
    }
    registerUnderFormatter(param) {
        let regUnderObj = { "C": "Company", "U": "Champion" };
        return regUnderObj[param.data.registerUnder];
    }
    lastLoginFromFormatter(param) {
        let loginFromObj = { "1": "Website", "2": "App" };
        return loginFromObj[param.data.lastLoginFrom];
    }

    endUserPhotoApprovedFormatter(param) {
        let docAppForObj = { "P": "Pending", "A": "Approved", "R": "Rejected", "N": "No Photo" };
        return docAppForObj[param.data.registerFrom];
    }
    profileApprovedFormatter(param) {
        let docAppForObj = { "P": "Pending", "A": "Approved", "R": "Rejected", "N": "No Photo" };
        return docAppForObj[param.data.profilePhotoApproved];
    }
    aadharApprovedFormatter(param) {
        let docAppForObj = { "P": "Pending", "A": "Approved", "R": "Rejected", "N": "No Photo" };
        return docAppForObj[param.data.aadharPhotoApproved];
    }
    panApprovedFormatter(param) {
        let docAppForObj = { "P": "Pending", "A": "Approved", "R": "Rejected", "N": "No Photo" };
        return docAppForObj[param.data.panPhotoApproved];
    }
    endUserStatusFormatter(param) {
        let euStatusObj = { "1": "Active", "2": "De Active", "3": "Suspended", "4": "Blocked" };
        return euStatusObj[param.data.status];
    }
    customerUserPhotoApprovedFormatter(param) {
        let docAppForObj = { "P": "Pending", "A": "Approved", "R": "Rejected", "N": "No Photo" };
        return docAppForObj[param.data.registerFrom];
    }
    customerUserStatusFormatter(param) {
        let euStatusObj = { "1": "Active", "2": "De Active", "3": "Suspended", "4": "Blocked" };
        return euStatusObj[param.data.status];
    }

    dataStatusFormatter(param) {
        let dataStatusObj = { "1": "Pending", "2": "Verified", "3": "Rejected" };
        return dataStatusObj[param.data.dataStatus];
    }
    mobileDataStatusFormatter(param) {
        let dataStatusObj = { true: "Activated", false: "Deactivated" };
        return dataStatusObj[param.data.isActive];
    }
    paymentStatusFormatter(param) {
        let dataStatusObj = { "1": "Pending", "2": "Paid", "3": "Refunded", "4": "Partial Paid" };
        return dataStatusObj[param.data.paymentStatus];
    }

    documentStatusFormatter(param) {
        let documentStatusObj = { "0": "Not Uploaded", "1": "Pending", "2": "Verified", "3": "Rejected", "4": "Partial Verified" };
        return documentStatusObj[param.data.documentStatus];
    }
    uploadFromFormatter(param) {
        let regFromObj = { "1": "Backend", "2": "Website", "3": "App" };
        return regFromObj[param.data.uploadFrom];
    }
    documentUploadStatus(param) {
        let documentStatusObj = { "0": "Not Uploaded", "1": "Pending", "2": "Verified", "3": "Rejected" };
        return documentStatusObj[param.data.status];
    }
    includeKitFormatter(param) {
        let ynObj = { 'Y': 'Yes', 'N': 'No' };
        return ynObj[param.data.includeKit];
    }
    validityPeriodFormatter(param) {
        let vpObj = { 'Y': 'Year', 'M': 'Month', 'D': 'Day' };
        return param.data.validityPeriod + ' ' + vpObj[param.data.validityPeriodType] + (param.data.validityPeriod > 1 ? 's' : '');
    }
    packageValidityTypeFormatter(param) {
        let pvtObj = { 'R': 'Regular', 'T': 'Time Period' };
        return pvtObj[param.data.packageValidityType] + (param.data.packageValidityType === 'T' ? ' / Till : ' + param.data.packageValidTill : '');
    }

    packageStatusFormatter(param) {
        let packageStatusObj = { "1": "Pending", "2": "Approved", "3": "Rejected", "4": "Deactive" };
        return packageStatusObj[param.data.status];
    }

    packageForFormatter(param) {
        let pfObj = { 'V': 'Vendor', 'C': 'Champions' };
        return pfObj[param.data.packageFor];
    }
    kitIssueToTypeFormatter(param) {
        let pfObj = { 'V': 'Vendor', 'C': 'Champion', 'S':'Super Champion','ST':'Staff' };
        return pfObj[param.data.kitIssueToType];
    }

    includeOfferUploadPackageFormatter(param) {
        let ynObj = { 'Y': 'Yes', 'N': 'No' };
        return ynObj[param.data.includeOfferUploadPackage];
    }
}