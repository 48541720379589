<div class="displayTable">
    <div class="displayTableCell">
      <div class="authBlock">
        <h3>You're Logged in</h3>
        <div class="formGroup" *ngIf="authService.userData as user">
          <p class="text-center">Hello <strong>{{user.email}}</strong>.</p>
          <!-- <p class="text-center">Please check your email and click on the link to verfiy your email address.</p> -->
        </div>
        
        <!-- Calling SendMail() method using authService Api -->
        <div class="formGroup">
          <button type="button" class="btn btnPrimary" (click)="authService.SendMail()">
            <i class="fas fa-redo-alt"></i>
            Resend Email
          </button>
        </div>
      </div>
      <div class="redirectToLogin">
        <span>Go back to?<span class="redirect" routerLink="/sign-in"> Sign in</span></span>
      </div>
    </div>
  </div>