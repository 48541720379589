<html>
  <style>
    .content b {
      font-size: large;
      color: white;
      font-weight: 300;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }
  </style>
  <Body>
    <section class="home-footer-about-candid">
      <div class="container">
        <div class="d-flex">
          <div class="align-self-center">
            <div class="logo">
              <img
                src="\assets\images\candid-offer-footer-logo.png"
                width="147"
                alt="candid-offer-footer-logo"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="content">
            

            <b>1.Entity </b>
            <p>
              Candid Offers is registered under the entity name of 'CANDIDOFFERS
              PRIVATE LIMITED'. Our CIN Number is U72900MH2019PTC333456.
            </p>
            <br />
            <b>2.Our Address and Phone</b>
            <p>
              FLAT 201 RAMA SAHNIWAS,RAM NAGAR,HILL ROAD NR LONDHE
              JEWELLERS,NAGPUR-440033
              <br />
              For any kind of information needed, you can connect us at
              91-8600799991 Toll Free
            </p>
            <br />
            <b>3.Our Email </b>
            <p>We can be contacted any time at rupesh@candidoffers.com</p>
            <br />
            <b> 4. Our Chief Executive Officer </b>
            <p>
              Rupesh kumar is our chief executive officer. He is in this
              industry for last 25 years
            </p>
            <br />

            <b>5. Our Chief Operating Officer</b><br />
            <p>
              Vedank Kumar is our chief Operating officer. Vedank is an
              Engineering Graduate and MBA from a premier institute
            </p>
            <br>
            <b >6.Our Chief Customer Officer</b>
            <p>Kriti Malpani is our Chief Customer Officer</p>

            <br />
            <b> 7. Our Chief Technology officer</b> <br />
            <p>
              Shekhar Priyadarshi is our Chief Technology officer and is in Tech
              industry for 25 years
            </p>
            <br />
          </div>
        </div>
      </div></section
  ></Body>
</html>
