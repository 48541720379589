import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'vendorProfile',
  templateUrl: './vendor-profile.component.html',
  styleUrls: ['./vendor-profile.component.css']
})
export class VendorProfileComponent implements OnInit {
  pageTitle='vendorProfile'

  @Input('allowedActions') allowedActions: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
