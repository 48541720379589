<div style="background-color: #ffffff;border:1px solid rgba(96, 94, 94, 0.933);">
<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
    <input #fileInput type="file" (change)="fileChangeEvent($event)" hidden />
    <div style="border:1px solid #000000;display: flex;justify-content: center;width: 100%;overflow: auto;">
        <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="data.maintainAspectRatio ? data.maintainAspectRatio : false"
            [containWithinAspectRatio]="data.containWithinAspectRatio ? data.containWithinAspectRatio : false"
            [aspectRatio]="data.aspectRatio ? data.aspectRatio : 4 / 3"
            [resizeToWidth]="data.resizeToWidth ? data.resizeToWidth : 250"
            [cropperMinWidth]="data.cropperMinWidth ? data.cropperMinWidth : 250"
            [cropperMinHeight]="data.cropperMinHeight ? data.cropperMinHeight : 100"
            [onlyScaleDown]="true"
            [roundCropper]="false"
            [canvasRotation]="canvasRotation"
            [transform]="transform"
            
            outputType="base64"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
            [style.display]="showCropper ? null : 'none'"
            alignImage="center"
            [imageQuality]="data.imageQuality ? data.imageQuality : 90"
        ></image-cropper>

        <!-- removed from above shekhar [format]="data.format ? data.format : 'png'"  -->
    </div>
    <div style="text-align: center;">
      <img [src]="croppedImage" />
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="pageFooterSection">
    <button mat-stroked-button color="warn" (click)="closeDialog(true)">Cancel</button>
    <button mat-stroked-button color="primary" (click)="closeDialog(false)" cdkFocusInitial>Done</button>
  </mat-dialog-actions>
</div>