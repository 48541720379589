import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chocolate-supplier',
  templateUrl: './chocolate-supplier.component.html'
 
})
export class ChocolateSupplierComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
