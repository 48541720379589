import { Component, OnInit,Input } from "@angular/core";
import { CandidOffersService } from "src/app/shared/services/candid-offers.service";


@Component({
  selector: "transactionScreen",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.css"],
})
export class IndexComponent implements OnInit {
  pageTitle='TransactionScreen'

  @Input('allowedActions') allowedActions: string = '';

  data:any=''
  public output!:any

  public onError(e:any){
    alert(e)
  }
  constructor(private candidOffersService: CandidOffersService) {}

  homePage() {
    const eventName = "co-master/getVendorObjects";
    const dataObj = { mobile1: "7020850231", city_id: "14" };
    this.candidOffersService.backendAPICall(eventName, dataObj).subscribe(
      (response) => {
        console.log(response);
        this.data=response
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ngOnInit(): void {
    console.log('check if this is good');
    this.homePage()
  }
}
