<!DOCTYPE html>
<html>
  <style>
     .delete-account {
  text-align: left;
  color: white; /* Align text to the left */
}

.small-text {
  font-size: small;
  /* Set font size to small */
}

    .content b {
      font-size: large;
      color: white;
      font-weight: 300;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    }
  </style>
  <Body>
    <section class="home-footer-about-candid">
      <div class="container">
        <div class="d-flex">
          <div class="align-self-center">
            <div class="logo">
              <img
                src="\assets\images\candid-offer-footer-logo.png"
                width="147"
                alt="candid-offer-footer-logo"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="content">
            <h2>Data Retention Policy</h2>
            <br />
            <p><b>Last Updated as on April, 2024</b></p>
            <br />
            <p>
              <b>Data Management Standards : </b>
              Our data management adheres strictly to the highest standards of
              compliance and user privacy protection.
            </p>

            <p>
              <b> Active Data Preservation : </b> Within our Firebase
              infrastructure, only actively utilized data essential for the
              seamless operation of our mobile application is retained.
            </p>
            <br />
            <p>
              <b> Definition of Active Data : </b> Active data encompasses
              critical information pertaining to active sellers, subscribed
              users, ongoing orders, and indispensable master data sets.
            </p>
            <br />
            <p>
              <b> Seller Data Deactivation : </b> In the event that a seller
              fails to place an order within one year of maintaining an active
              subscription, their data will be scheduled for systematic removal
              from our mobile application.
            </p>
            <br />
            <p>
              <b> Subscription Renewal Protocol : </b> Following the lapse of a
              subscription period, if renewal is not initiated within six
              months, the associated data will be earmarked for deletion.
            </p>
            <br />
            <p>
              <b> Champion/Super Champion Engagement Monitoring : </b> Should a
              Champion or Super Champion exhibit no activity for a period
              exceeding 180 days post-renewal, their data will be flagged for
              deletion, with potential refund processes triggered as warranted.
            </p>
            <br />
            <p>
              <b> Deleted Data Scope : </b> The deletion process will encompass
              comprehensive removal of contact details, personal identifiers,
              and any associated documentation attributed to the respective
              seller.
            </p>
            <br />
            <p>
              <b> Customer Activity Tracking : </b> Customers will retain their
              active status for a duration of 180 days following their last
              recorded engagement.
            </p>
            <br />
            <p>
              <b> Prime Customer Data Preservation : </b> Data pertaining to
              Prime customers will be securely retained for a period of 5 years,
              ensuring continuity and personalized service delivery.
            </p>
            <br />
            <p>
              <b> Web Application Data Archiving : </b> Historical data will
              remain accessible through our web application interface for a
              duration of 5 years, facilitating reference and analysis.
            </p>
            <br />
            <p>
              <b> Financial Transaction Integrity : </b> All financial
              transactions will be meticulously recorded and maintained for a
              statutory period of 7 years from the date of transaction,
              facilitating comprehensive auditing and compliance.
            </p>
            <br />
            <p>
              <b> Year-End Compliance Procedures : </b> Annual activities will
              include meticulous reconciliation of all financial transactions,
              ensuring the creation of auditable documentation in alignment with
              regulatory standards.
            </p>
            <br />
            <p>
              <b> Tax Compliance Measures : </b> Taxable transactions will be
              preserved in accordance with the prevailing statutory requirements
              of the jurisdiction, ensuring full regulatory compliance and
              transparency.
            </p>
            <br />

            <br />

            <p>
              Name – Rupesh Kumar<br />
              Address - FLAT 201 RAMA SAHNIWAS,RAM NAGAR,HILL ROAD,<br />
              NR LONDHE JEWELLERS,NAGPUR-440010 <br />
              email id: Rupesh@Candidoffers.com<br />
            </p>
            <br />
            <b>15.Contact us</b><br />
            <p>
              15.1.If you have any queries relating to the processing/ usage of
              information provided by you or the Company's Privacy Policy or if
              you would like to raise any other inquiries, you may email us at
              the contact information provided above under section 14 of this
              privacy policy.
            </p>
            <b>16.Account Delete</b><br />
            <div class="delete-account">
              <p>
                <a href="/account-deletion">
                  <span class="small-text"> Click For Our Data Delete Rentention
                    Form!</span>
                </a>
              </p>
            </div>
            <br />
          </div>
        </div>
      </div>
    </section>
  </Body>
</html>
