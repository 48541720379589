import { HttpClient } from '@angular/common/http';
import { Component, OnInit,Input } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  pageTitle='OfferUploadHistory'

  @Input('allowedActions') allowedActions: string = '';
  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

  
}
