import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AboutusComponent } from "./aboutus/aboutus.component";
import { AccessoriesComponent } from "./accessories/accessories.component";
import { AllComponent } from "./all/all.component";
import { ApparelComponent } from "./apparel/apparel.component";
import { ArtComponent } from "./art&Hobby/art.component";
import { BoutiqueComponent } from "./boutique/boutique.component";
import { ChocolateSupplierComponent } from "./chocolate-supplier/chocolate-supplier.component";
import { CodeComponent } from "./code/code.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { PhoneNumberComponent } from "./components/phone-number/phone-number.component";
//new
import { SendEmailComponent } from "./components/send-mail/send-mail.component";
import { SignInComponent } from "./components/sign-in/sign-in.component";
import { SignUpComponent } from "./components/sign-up/sign-up.component";
import { VerifyEmailComponent } from "./components/verify-email/verify-email.component";
import { ContactUsComponent } from "./contact-us/contact.component";
import { CosmeticsComponent } from "./cosmetics/cosmetics.component";
import { DailyNeedsComponent } from "./daily-needs/daily-needs.component";
import { DownloadsComponent } from "./downloads/downloads.component";
import { HomeComponent } from "./home/home.component";
import { PaymentsComponent } from "./payments/payments.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacypolicy.component";
import { DataRententionPolicyComponent } from "./data-rentention-policy/datarententionpolicy.component";
import { AcceptOfferComponent } from "./vendor/accept-offer/accept-offer.component";
import { CongratulationComponent } from "./vendor/congratulation/congratulation.component";
import { CreateOffersComponent } from "./vendor/create-offers/create-offers.component";
import { EditOffersComponent } from "./vendor/edit-offers/edit-offers.component";
import { ManageOffersComponent } from "./vendor/manage-offers/manage-offers.component";
import { OfferUploadHistoryComponent } from "./vendor/offer-upload-history/offer-upload-history.component";
import { RegisterComponent } from "./vendor/register/register.component";
import { TermsAndConditionComponent } from "./vendor/terms-and-condition/terms-and-condition.component";
import { IndexComponent } from "./vendor/transaction-screen/index.component";
import { VendorProfileComponent } from "./vendor/vendor-profile/vendor-profile.component";
import { VendorSignInComponent } from "./vendor/vendor-sign-in/vendor-sign-in.component";
import { VerifyOtpComponent } from "./vendor/verify-otp/verify-otp.component";
import { ChampionRegComponent } from "./champion-reg/champion-reg.component";
import { ChampionScreenComponent } from "./champion-screen/champion-screen.component";
import { AccountDeleteComponent } from "./DeleteAccount/deleteAccount.component";
import { AccountDeletionComponent } from "./account-deletion/account-deletion.component";
import { sellerRegComponent } from "./seller-reg/seller-reg.component";
import { SellerScreenComponent } from "./seller-screen/seller-screen.component";
import { superchampionRegComponent } from "./superchampion-reg/superchampion-reg.component";
import { ForgotPasswordWebComponent } from "./components/forgot-password-web/forgot-password-web.component";

// route guard

const routes: Routes = [
  { path: "All", component: AllComponent },
  { path: "Apparel", component: ApparelComponent },
  { path: "Accessories", component: AccessoriesComponent },
  { path: "Art&Hobby", component: ArtComponent },
  { path: "Boutique", component: BoutiqueComponent },
  { path: "ChocolateSupplier", component: ChocolateSupplierComponent },
  { path: "Cosmetics", component: CosmeticsComponent },
  { path: "DailyNeeds", component: DailyNeedsComponent },
  // { path: 'PrivacyPolicy', component: PrivacyPolicyComponent},
  // { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: "", component: HomeComponent },
  // { path: "sign-in", component: SignInComponent },
  { path: "register-user", component: SignUpComponent },
  {
    path: "dashboard",
    component: DashboardComponent,
    // canActivate: [AuthGuard],
  },
  { path: "forgot-password", component: ForgotPasswordWebComponent },
  { path: "verify-email-address", component: VerifyEmailComponent },
  { path: "phone", component: PhoneNumberComponent },
  { path: "code", component: CodeComponent },
  //send mail
  { path: "send-mail", component: SendEmailComponent },

  // Vendor
  { path: "accept-offer", component: AcceptOfferComponent },
  { path: "congratulation", component: CongratulationComponent },
  { path: "create-offers", component: CreateOffersComponent },
  { path: "edit-offers", component: EditOffersComponent },
  { path: "transaction-screen", component: IndexComponent },
  { path: "manage-offers", component: ManageOffersComponent },
  { path: "offer-upload-history", component: OfferUploadHistoryComponent },
  { path: "register-vendor", component: RegisterComponent },
  { path: "vendor-profile", component: VendorProfileComponent },
  { path: "sign-in-vendor", component: VendorSignInComponent },
  { path: "terms-and-condition", component: TermsAndConditionComponent },
  { path: "verify-otp", component: VerifyOtpComponent },
  {
    path: "aboutus",
    component: AboutusComponent,
  },
  {
    path: "downloads",
    component: DownloadsComponent,
  },
  {
    path: "register-champion",
    component: ChampionRegComponent,
  },
  {
    path: "deleteAccount",
    component: AccountDeleteComponent,
  },
  {
    path: "seller-screen",
    component: SellerScreenComponent,
  },
  {
    path: "account-deletion",
    component: AccountDeletionComponent,
  },
  {
    path: "register-seller",
    component: sellerRegComponent,
  },
  {
    path: "register-superchampion",
    component: superchampionRegComponent,
  },
  {
    path: "champions",
    component: ChampionScreenComponent,
  },
  {
    path: "privacypolicy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "datarententionpolicy",
    component: DataRententionPolicyComponent,
  },
  {
    path: "payments",
    component: PaymentsComponent,
  },
  {
    path: "contactus",
    component: ContactUsComponent,
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./modules/admin.module").then((m) => m.AdminModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
