import { Component, OnInit, Input } from "@angular/core";
import { CandidOffersService } from "src/app/shared/services/candid-offers.service";
import { MatDialog } from "@angular/material/dialog";
import { TermsAndConditionComponent } from "../terms-and-condition/terms-and-condition.component";
import { FormGroup, Validators, FormControl } from "@angular/forms";

@Component({
  selector: "create_offers",
  templateUrl: "./create-offers.component.html",
  styleUrls: ["./create-offers.component.css"],
})
export class CreateOffersComponent implements OnInit {
  pageTitle = "CreateOffers";

  @Input("allowedActions") allowedActions: string = "";

  showForm:boolean=false
  showCongrats:boolean=false

  offerName = new FormControl("");
  offerDescription = new FormControl("");
  productType = new FormControl("");
  offerType = new FormControl("");
  discountType = new FormControl("");
  discountNo = new FormControl("");
  discountUoM = new FormControl("");
  discountNo2 = new FormControl("");

  registerForm = new FormGroup({
    offerName: this.offerName,
    offerDescription: this.offerDescription,
    productType: this.productType,
    offerType: this.offerType,
    discountType: this.discountType,
    discountNo: this.discountNo,
    discountUoM: this.discountUoM,
    discountNo2: this.discountNo2,
  });

  createOfferForm: FormGroup;
  formMode: string = "A";
  detailsLoaded: boolean = false;

  terms: any;
  data: any;
  photos: string;
  productId: number = 25;
  companyName: string = "uwfhpt";
  offersPackageId: number = 14;
  catId: number = 11;
  pincode: string = "437955";
  contactNo1: string = "3209849046";
  email1: string = "eordfon@gmail.com";
  formData: any = {};

  constructor(
    private candidOffersService: CandidOffersService,
    private dialogRef: MatDialog
  ) {}

  createOffer() {
    console.log(this.offerName);
    const eventName = "co-trans/addProductOffer2";
    this.formData = {
      offerName: this.registerForm.value.offerName,
      offerDescription: this.registerForm.value.offerDescription,
      productType: this.registerForm.value.productType,
      offerType: this.registerForm.value.offerType,
      discountType: this.registerForm.value.discountType,
      discountNo: this.registerForm.value.discountNo,
      discountNo2: this.registerForm.value.discountNo2,
      discountUoM: this.registerForm.value.discountUoM,
      productId: this.productId,
      companyName: this.companyName,
      offersPackageId: this.offersPackageId,
      catId: this.catId,
      pincode: this.pincode,
      contactNo1: this.contactNo1,
      email1: this.email1,
    };
    console.log(this.formData);
    this.candidOffersService.backendAPICall(eventName, this.formData).subscribe(
      (response) => {
        console.log(response);
        this.data = response;
        this.showCongrats=true
      },
      (error) => {
        console.log(error);
      }
    )  
  }

  resetOfferForm() {
    this.createOfferForm.reset();
    this.createOfferForm.patchValue({ encashId: "" });
  }

  onFileSelected(event: any) {
    this.formData.photos = event.target.files[0];
  }

  onCheckBoxChange(event: any) {
    const eventName = "co-master/getVendorPolicy";
    const dataObj = { vendorId: 1 };

    if (event.target.checked == false) {
      this.candidOffersService.backendAPICall(eventName, dataObj).subscribe(
        (response) => {
          this.dialogRef.open(TermsAndConditionComponent, {
            data: response,
          });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  

  ngOnInit(): void {}
}
