import { Component } from '@angular/core';

@Component({
    selector: 'seller-screen',
    templateUrl: './seller-screen.component.html',
    styleUrls: ['./seller-screen.component.css']
  })
export class SellerScreenComponent {

  constructor() { }

}
