import { Component, HostListener, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";

import { OwlOptions } from "ngx-owl-carousel-o";

import { ApiService } from "../service";
import { AlertService } from "../shared/services/alert.service";
import { CommonService } from "../shared/services/common.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  //  styleUrls: ['./home.component.css'],
  styleUrls: ["./style.css"],
})
export class HomeComponent {
  title = "COWebsite";
  MenOffers$: any;
  user: any = {};
  posts: any;
  loading = false;
  enteredName: string = "";
  enteredEmail: string = "";
  enteredMobile: string = "";
  showBackToTopButton = false;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public dialog: MatDialog
  ) {
    this.user = {
      username: "RITU",
      password: "@ritu123",
    };
  }
  @HostListener("window:scroll", [])

  onWindowScroll() {
    if (window.pageYOffset > 100) {
      this.showBackToTopButton = true;
    } else {
      this.showBackToTopButton = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  openModal(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }
  closeDownload() {
    this.enteredName = "";
    this.enteredEmail = "";
    this.enteredMobile = "";
    this.dialog.closeAll();
  }

  downloadFile() {
    this.loading = true;
    if (this.enteredName && this.enteredEmail && this.enteredMobile) {
      const apiURL = "/common/saveDownloader";

      this.commonService
        .backendAPICall(apiURL, {
          name: this.enteredName,
          email: this.enteredEmail,
          mobile: this.enteredMobile,
        })
        .subscribe(
          (result) => {
            console.log(result);

            if (result["status"] == "Success") {
              console.log(result);
              this.loading = false;
              this.closeDownload();
              window.location.href = "/downloads";
            } else {
              this.alertService.create(
                "API Error",
                "error",
                0,
                "State List Loading Failed"
              );
            }
          },
          (error) => {
            console.error("API Error:", error);
            // Handle error: Display a user-friendly message or log it for debugging.
          }
        );
    } else {
      // Use a more user-friendly way to notify the user about missing details
      this.loading = false;
      this.alertService.create(
        "Missing Details",
        "error",
        0,
        "Please enter all details"
      );
    }
  }

  isLoggedIn(): boolean {
    if (
      localStorage.getItem("isLogged") &&
      localStorage.getItem("userToken") !== null
    ) {
      return true;
    }

    return false;
  }

  logoutUser() {
    //console.log("Header Logout Called");
    //this.commonService.logout()
    this.commonService.logout().subscribe(
      (result) => {
        //console.log(result);
        if (result["responseStatus"] == "Ok") {
          /* localStorage.removeItem('userToken');
          localStorage.removeItem('isLogged');
          localStorage.removeItem('userData');
          this.router.navigate(['login']); */
          this.commonService.redirectToLogin();
        } else {
          this.alertService.create(
            "Logout Failed",
            "error",
            0,
            result["message"]
          );
        }
      },
      (error) => {
        //this.alertSerive.create("API Error","error",0,error);
      }
    );
  }

  fetchoffers() {
    this.MenOffers$ = this.apiService.fetchoffers();
  }

  goToAll() {
    this.router.navigate(["/", "All"]);
  }

  goToApparel() {
    this.router.navigate(["/", "Apparel"]);
  }

  goToAccessories() {
    this.router.navigate(["/", "Accessories"]);
  }

  goToArt() {
    this.router.navigate(["/", "Art&Hobby"]);
  }

  goToBoutique() {
    this.router.navigate(["/", "Boutique"]);
  }

  goToCosmetics() {
    this.router.navigate(["/", "Cosmetics"]);
  }

  goToChocolateSupplier() {
    this.router.navigate(["/", "ChocolateSupplier"]);
  }

  goToDailyNeeds() {
    this.router.navigate(["/", "DailyNeeds"]);
  }
  // goToPrivacyPolicy(){
  //   this.router.navigate(['/', 'PrivacyPolicy']);
  // }

  // ngOnInit() {
  //   this.apiService.getPosts()
  //     .subscribe(response => {
  //       this.posts = response;
  //     });

  //     if (localStorage.getItem('userToken') !== 'BKm7qwb3UwrXZO2BSp42utobkUpJK3eV') {
  //       // verify token
  //       this.loading = true;
  //       this.apiService.userTokenAuthentication().subscribe(
  //       (result: { [x: string]: string; }) => {
  //             if(result['responseStatus'] == 'Ok'){
  //                   if(JSON.parse(localStorage.getItem('BKm7qwb3UwrXZO2BSp42utobkUpJK3eV'))){
  //                         this.router.navigate([JSON.parse(localStorage.getItem('BKm7qwb3UwrXZO2BSp42utobkUpJK3eV')]);
  //                   }
  //             }
  //       });
  // }

  // }

  // Carousel

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      1000: {
        items: 1,
      },
      700: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };

  bannerImage = [
    {
      id: 1,
      src: "/assets/images/home-banner.jpg",
      alt: "Image_1",
      title: "Image_1",
    },
    {
      id: 2,
      src: "/assets/images/home-banner.jpg",
      alt: "Image_2",
      title: "Image_3",
    },
    {
      id: 3,
      src: "/assets/images/home-banner.jpg",
      alt: "Image_3",
      title: "Image_3",
    },
    {
      id: 4,
      src: "/assets/images/home-banner.jpg",
      alt: "Image_4",
      title: "Image_4",
    },
  ];
}
