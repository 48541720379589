import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../shared/services/common.service';
import { CandidOffersService } from '../shared/services/candid-offers.service';
import { AlertService } from '../shared/services/alert.service';

@Component({
  selector: 'app-account-delete',
  templateUrl: './deleteAccount.component.html',
})

export class AccountDeleteComponent implements OnInit {
  accountDeleteForm: FormGroup;
  loading = false;
 //for mobile otp
 isOTPButtonDisabled = false;
 showOTPVerification = false;
 isOTPSuccess = false;
 generatedOTP: string;

 //for mail otp
 isMailOTPButtonDisabled = false;
 showMailOTPVerification = false;
 isMailOTPSuccess = false;
 generatedMailOTP: string;

  constructor(private fb: FormBuilder,
    private commonService: CommonService,
    private alertService: AlertService,
    private candidOffersService: CandidOffersService,
     private http: HttpClient) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.isMailOTPButtonDisabled = false;
    this.showMailOTPVerification = false;
    this.isMailOTPSuccess = false;
    this.isOTPButtonDisabled = false;
    this.showOTPVerification = false;
    this.isOTPSuccess = false;
    this.accountDeleteForm = this.fb.group({
      username: ['', Validators.required],
      mobile: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9-]*$"),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      otp: ["" ,[Validators.maxLength(4),]],
      mailOtp: ["",Validators.maxLength(4),],
      email: ["", [Validators.required, Validators.email]],
      reason: ['', Validators.required],
    });
  }

  sendOTP() {
    const mobileNumber = this.accountDeleteForm.get("mobile").value;
    const UserName = this.accountDeleteForm.get("username").value || "User";
    if (mobileNumber == null) {
      window.alert("Enter Mobile Number!");
      return;
    }

    // API to send OTP
    this.candidOffersService
      .backendAPICall("common/sendOtp", {
        name: UserName,
        mobile: mobileNumber,
      })
      .subscribe(
        (response) => {
          this.alertService.create("Success", "success", 0, "OTP sent successfully");
          this.generatedOTP = response['otp'];
          this.isOTPButtonDisabled = true;
          this.showOTPVerification = true;
        },
        (error) => {
          console.error("Error sending OTP", error);
          window.alert(error["message"]);
        }
      );
  }

  verifyOTP() {
    const enteredOTP = this.accountDeleteForm.get("otp").value;

    if (enteredOTP == null) {
      window.alert("Enter OTP!");
      return;
    }
    if (enteredOTP == this.generatedOTP) {
      this.isOTPSuccess = true;
      this.isOTPButtonDisabled = true;
    } else {
      window.alert("OTP is not correct!");
    }
  }
  
  sendMailOTP() {
    const email = this.accountDeleteForm.get("email").value;
    if (email == null) {
      window.alert("Enter email Number!");
      return;
    }
    this.generatedMailOTP =
      Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000 + "";
    // API to send OTP
    const emailSubject = "candid verification.";
    const template = `<!DOCTYPE html>\n<html lang=\"en\">\n<head>\n  <meta charset=\"UTF-8\">\n  <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n  <title>OTP Verification</title>\n  <style>\n    body {\n      font-family: Arial, sans-serif;\n      background-color: #f4f4f4;\n      color: #333;\n    }\n    .container {\n      max-width: 600px;\n      margin: 0 auto;\n      padding: 20px;\n      background-color: #fff;\n      border-radius: 5px;\n      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    }\n    h1 {\n      color: #4285f4;\n    }\n  </style>\n</head>\n<body>\n  <div class=\"container\">\n    <h1>OTP Verification</h1>\n    <p>Hello Candid User,</p>\n    <p>Your OTP (One-Time Password) for verification is: <strong>${this.generatedMailOTP}</strong></p>\n    <p>Please use this OTP to complete the verification process.</p>\n\n    <p>If you didn't request this OTP, please ignore this email.</p>\n\n    <footer>\n      <p>Best regards,<br> Candid Verification Team</p>\n    </footer>\n  </div>\n</body>\n</html>`;

    this.candidOffersService
      .backendAPICall("common/send-mail", {
        sendTo: email,
        emailSubject: emailSubject,
        template: template,
      })
      .subscribe(
        (response) => {
          this.alertService.create("Success", "success", 0, "OTP sent successfully");
          this.isMailOTPButtonDisabled = true;
          this.showMailOTPVerification = true;
        },
        (error) => {
          console.error("Error sending OTP", error);
        }
      );
  }

  verifyMailOTP() {
    const enteredOTP = this.accountDeleteForm.get("mailOtp").value;

    if (enteredOTP == null) {
      window.alert("Enter OTP!");
      return;
    }

    if (enteredOTP == this.generatedMailOTP) {
      this.isMailOTPSuccess = true;
      this.isMailOTPButtonDisabled = true;
    } else {
      window.alert("OTP is not correct!");
    }
  }

  onSubmit(): void {
    if (this.accountDeleteForm.valid) {
      this.loading = true;
  
      const apiURL = '/common/soft-delete';
  
      const payload = {
        mobile: '+91'+this.accountDeleteForm.value.mobile.toString()
      };
      this.commonService.backendAPICall(apiURL, payload)
        .subscribe(response => {
          console.log("Account deletion request submitted successfully:", response);
          this.accountDeleteForm.reset();
          this.isMailOTPButtonDisabled = false;
          this.showMailOTPVerification = false;
          this.isMailOTPSuccess = false;
          this.isOTPButtonDisabled = false;
          this.showOTPVerification = false;
          this.isOTPSuccess = false;
          this.loading = false;
          window.alert("Your account deletion request has been submitted successfully!");
        }, error => {
          console.error("Error submitting account deletion request:", error);
          this.loading = false;
          window.alert("An error occurred while submitting your account deletion request. Please try again later.");
        });
  
    } else {
      console.error("Form is invalid. Please fill in all required fields.");
      window.alert("Please fill in all required fields.");
    }
  }
}
