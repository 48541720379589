<div class="container-fluid form">
    <div class="row form-row ">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div class="card my-5">
                <div class="card-body">
                    <h3 class="card-title text-center">Forgot Password</h3>
                    <div>
                         <!-- <form action=""  (ngSubmit)="submit"> by shekhar -->
                       <form action="" [formGroup]="form" (ngSubmit)="submit"> 
                            <div class="form-group">
                                <input matInput _ngcontent-c0="" class="form-control form-control-lg" placeholder="email"
                                    type="email" formControlName="email1" />
                                    <mat-error *ngIf="form.get('email1').hasError('required')"> Email is required</mat-error>
                                    <mat-error *ngIf="form.get('email1').hasError('email')"> Enter valid email address</mat-error>
                            </div>
                            <div class="form-group">
                                <div>
                                    <button type="submit" class=" btn btn-primary">Reset
                                        Password</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!-- <Form [formGroup]="form" (ngSubmit)="submit()"novalidate>
    <h3 class="text-center">Please Enter Email</h3>
    
        <div class="text-center">
            <input matInput placeholder="Email" formControlName="email1" required>

        </div>
  <div class="text-center">
    <button mat-stroked-button color="primary" type="submit">Reset Password</button>
  </div>
</Form> --> 
