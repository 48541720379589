<!DOCTYPE html>
<html>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Privacy Policy</title>
  <style>
    .content b {
      font-size: large;
      color: white;
      font-weight: 300;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    }
  </style>
  <Body>
    <section class="home-footer-about-candid">
        <div class="container">
          <div class="d-flex">
            <div class="align-self-center">
              <div class="logo">
                <img
                  src="\assets\images\candid-offer-footer-logo.png"
                  width="147"
                  alt="candid-offer-footer-logo"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="content">
              <h1 class="text-center">Privacy Policy</h1>
              <p>Last Updated at May, 2024 </p>
              <!-- Start of the new content -->
              <p>This Privacy Policy outlines the practices of Candid Offers ("we," "us," or "our") regarding the collection, use, and disclosure of personal information when you use our mobile application, website, and related services (collectively, the "Services").</p>
              <h2>Information We Collect</h2>
              <p>We may collect the following types of personal information:</p>
              <ul>
                <li>Personal Identifiers: Name, email address, phone number, postal address, date of birth, and other similar information.</li>
                <li>Account Information: Usernames, passwords, and other credentials used to access our Services.</li>
                <li>Payment Information: Credit card details, billing address, and transaction history for processing payments.</li>
                <li>Usage Data: Information about how you interact with our Services, including pages visited, features used, and time spent on each page.</li>
                <li>Device Information: IP address, device type, operating system, browser type, and other technical information.</li>
                <li>Location Data: GPS coordinates or other location information if you enable location-based features.</li>
              </ul>
              <h2>How We Use Your Information</h2>
              <p>We may use the information we collect for the following purposes:</p>
              <ul>
                <li>Providing, maintaining, and improving our Services.</li>
                <li>Personalizing your experience and delivering relevant content.</li>
                <li>Processing payments and fulfilling orders.</li>
                <li>Communicating with you, including responding to inquiries and providing customer support.</li>
                <li>Analyzing usage trends and measuring the effectiveness of our marketing campaigns.</li>
                <li>Detecting and preventing fraud, security breaches, and other illegal activities.</li>
                <li>Complying with legal obligations and enforcing our policies.</li>
              </ul>
              <h2>Data Sharing and Disclosure</h2>
              <p>We may share your personal information with third parties for the following purposes:</p>
              <ul>
                <li>Service Providers: We may engage third-party service providers to assist with the operation of our Services, such as hosting providers, payment processors, and analytics services.</li>
                <li>Business Partners: We may share information with business partners, affiliates, and sponsors to offer co-branded services or promotions.</li>
                <li>Legal Compliance: We may disclose information if required by law, legal process, or government request, or to protect the rights, property, or safety of our users or others.</li>
                <li>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity.</li>
              </ul>
              <h2>Data Retention</h2>
              <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. We may also retain and use anonymized or aggregated data for analytical purposes.</p>
              <h2>Your Rights and Choices</h2>
              <p>You have certain rights regarding your personal information, including:</p>
              <ul>
                <li>Access: You can request access to the personal information we hold about you and request corrections or deletions as appropriate.</li>
                <li>Opt-Out: You can opt-out of receiving promotional communications from us by following the instructions provided in the communication or contacting us directly.</li>
                <li>Cookies: You can manage your cookie preferences through your browser settings or opt-out of certain tracking technologies.</li>
              </ul>
              <h2>Data Security</h2>
              <p>We employ reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.</p>
              <h2>International Data Transfers</h2>
              <p>Your personal information may be transferred to and processed in countries other than your own, where data protection laws may differ. By using our Services, you consent to the transfer of your information to any country where we operate.</p>
              <h2>Children's Privacy</h2>
              <p>Our Services are not directed to children under the age of 13, and we do not knowingly collect personal information from children. If you become aware that a child has provided us with personal information, please contact us, and we will take steps to delete such information.</p>
              <h2>Changes to this Privacy Policy</h2>
              <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Policy on our website or through other appropriate channels.</p>
              <!-- End of the new content -->
              <p>Name – Rupesh Kumar<br />
              Address - FLAT 201 RAMA SAHNIWAS,RAM NAGAR,HILL ROAD,<br />
              NR LONDHE JEWELLERS,NAGPUR-440010 <br />
              email id: Rupesh@Candidoffers.com<br />
              </p>
              <br />
              <b>Contact us</b><br />
              <p>
                If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at
                <b>support@candidoffers.com</b>.
              </p>
              <br />
            </div>
          </div>
        </div>
      </section>
  </Body>
</html>
