import { HttpClient } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component } from "@angular/core";
import { AlertService } from "../shared/services/alert.service";
import { CommonService } from "../shared/services/common.service";

@Component({
  selector: "champion-screen",
  templateUrl: "./champion-screen.component.html",
})
export class ChampionScreenComponent {
  championForm: FormGroup;
  passHide = true;
  loading = false;

  constructor(
    private commonService: CommonService,
    private alertService: AlertService,
    private http: HttpClient,
    public fb: FormBuilder
  ) {}

  ngOnInit() {
    this.championForm = this.fb.group({
      fullName: ["", Validators.required],
      username: ["", Validators.required],
      password: ["", Validators.required],
      fatherOrHusband: ["", Validators.required],
      relation: ["", Validators.required],
      gender: ["", Validators.required],
      mobile: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9-]*$"),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      email: ["", [Validators.required, Validators.email]],
      panNo: [
        "",
        [Validators.required, Validators.pattern("^[A-Z]{5}[0-9]{4}[A-Z]{1}")],
      ],
      aadharNo: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$"),
        ],
      ],
    });
  }

  // convenience getter for easy access to form fields
  get cf() {
    return this.championForm.controls;
  }

  onSubmit() {
    if (this.championForm.valid) {
      this.loading = true;
  
      const apiURL = "/common/send-mail";
      const payload = {
        sendTo: 'ramchandrakumawat365@gmail.com',
        emailSubject: 'new champion registration.',
        template: `
          <h1>New Champion Registration</h1>
          <p>Hello Candid Admin,</p>
          <p>A new champion wants to register with the following details:</p>
          <ul>
            <li><strong>Name:</strong> ${this.championForm.value.fullName}</li>
            <li><strong>Username:</strong> ${this.championForm.value.username}</li>
            <li><strong>Password:</strong> ${this.championForm.value.password}</li>
            <li><strong>Father/Husband Name:</strong> ${this.championForm.value.fatherOrHusband}</li>
            <li><strong>Relation:</strong> ${this.championForm.value.relation}</li>
            <li><strong>Gender:</strong> ${this.championForm.value.gender}</li>
            <li><strong>Mobile:</strong> ${this.championForm.value.mobile}</li>
            <li><strong>Email:</strong> ${this.championForm.value.email}</li>
            <li><strong>PAN:</strong> ${this.championForm.value.panNo}</li>
            <li><strong>Aadhar:</strong> ${this.championForm.value.aadharNo}</li>
          </ul>
          <p>Please take necessary actions to register.</p>
          <footer>
            <p>Best regards,<br> Candidoffers Team</p>
          </footer>
        `
      };
  
      // Send data to API
      this.commonService.backendAPICall(apiURL, payload).subscribe(
        (result) => {
          console.log(result);
  
          if (result['status'] == 'Success') {
            this.loading = false;
            this.alertService.create(
              'Success',
              'error',
              0,
              'Champion registration successfully'
            );
          } else {
            this.alertService.create(
              'API Error',
              'error',
              0,
              'State List Loading Failed'
            );
          }
        },
        (error) => {
          console.error('API Error:', error);
        }
      );
    }
  }
  
}
