import { Location } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';

import { Config } from '../../../shared/configs/config';
import {
  BranchSelectionComponent,
} from '../../../shared/dialog/branchselection.component';
import {
  ChangePasswordComponent,
} from '../../../shared/dialog/change-password.component';
import {
  CompanySelectionComponent,
} from '../../../shared/dialog/companyselection.component';
import { MenuStructure } from '../../../shared/interfaces/menu-structure';
import { AlertService } from '../../../shared/services/alert.service';
import { CommonService } from '../../../shared/services/common.service';

//import { TabsPanelComponent } from '../tabs/tabspanel.component';
//import { TabEntryComponent } from '../tabs/tabentry.component';
//import { TabsConfigDirective } from "../tabs/tab-config.directive";
@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
})
export class HeaderComponent implements OnInit {
  @ViewChild("sidenav") sideMenu: MatDrawer;
  sideMenuConfig = Config.sideMenuConfig;
  currentUserName: string = "";
  currentModuleCode: string = "";
  allowedModules: any[] = [];
  selectedMenuData: MenuStructure;
  menuItemsArray: any[] = [{ "label": "Loading...", "faIcon": "fas fa-spinner fa-spin" }];
  isLogged: boolean = true;

  constructor(public dialog: MatDialog, private commonService: CommonService, private router: Router, private alertSerive: AlertService, private location: Location) {
    if (localStorage.getItem('isLogged')) {
      this.isLogged = true;
    } else {
      this.isLogged = false
    }
    if (this.isLogged) {
      let userSessionData = JSON.parse(localStorage.getItem('userData'));
      let currentBranchData = {};
      if (localStorage.getItem('currentBranchData') != '') {
        currentBranchData = JSON.parse(localStorage.getItem('currentBranchData'));
      }
      this.currentModuleCode = JSON.parse(localStorage.getItem('currentModuleData'))['moduleCode'];
      this.allowedModules = userSessionData['user']['allowedModules'];
      this.currentUserName = userSessionData['user']['userFullName'];
      let currentModuleData = JSON.parse(localStorage.getItem('currentModuleData'));
      if (currentModuleData != null) {
        this.currentModuleCode = currentModuleData['moduleCode'];
      } else {
        this.alertSerive.create("API Error", "error", 0, "No Module Access, Try Re-Login");
      }
    }

  }
  dialogRef;
  ngOnInit() {
    //setTimeout(() => this.openDialog())
  }
  openDialog() {
    this.dialogRef = this.dialog.open(CompanySelectionComponent, {
      panelClass: 'companySelectionPanel',
      autoFocus: false,
      disableClose: true,
    });
  }

  openBranchSelection() {
    this.dialogRef = this.dialog.open(BranchSelectionComponent, {
      panelClass: 'companySelectionPanel',
      autoFocus: false,
      disableClose: true,
    });
  }
  userProfile() {
    this.router.navigate(['/admin/user-profile']);
  }
  close() {
    this.dialogRef.close();
  }

  changeUserPassword() {
    this.dialogRef = this.dialog.open(ChangePasswordComponent, {
      panelClass: 'companySelectionPanel',
      autoFocus: false,
      disableClose: true,
    });
  }

  logoutUser() {
    //console.log("Header Logout Called");
    //this.commonService.logout()
    this.commonService.logout().subscribe(
      (result) => {
        //console.log(result);
        if (result['responseStatus'] == 'Ok') {
          /* localStorage.removeItem('userToken');
          localStorage.removeItem('isLogged');
          localStorage.removeItem('userData');
          this.router.navigate(['login']); */
          this.commonService.redirectToLogin();
        } else {
          this.alertSerive.create("Logout Failed", "error", 0, result['message']);
        }
      },
      (error) => {
        //this.alertSerive.create("API Error","error",0,error);
      }

    )
  }
  _notificationSideBar: boolean = false;
  _settingSidebar: boolean = false;


  _toggleSidebar() {
    this._notificationSideBar = !this._notificationSideBar;
    //console.log(this._notificationSideBar);
  }
  _settingSideBar() {
    this._settingSidebar = !this._settingSidebar;
  }

  moduleChange(obj) {
    // console.log(obj.value);
    let selectedModule = this.allowedModules.filter(am => am.moduleCode === obj.value)[0];
    // console.log(selectedModule);
    let newCurrentModule = {
      "moduleId": selectedModule.moduleId,
      "moduleName": selectedModule.moduleName,
      "moduleCode": selectedModule.moduleCode,
    }
    console.log('HD', newCurrentModule);
    localStorage.setItem("currentModuleData", JSON.stringify(newCurrentModule));
    let userSessionData = JSON.parse(localStorage.getItem('userData'));
    userSessionData['user']['currentSelectionData']['module'] = newCurrentModule;
    localStorage.setItem('userData', JSON.stringify(userSessionData));
    this.commonService.updateCurrentSelectionData(JSON.stringify(userSessionData['user']['currentSelectionData'])).subscribe(
      (result) => {
        // console.log(result);
        if (result['responseStatus'] == 'Ok') {
          this.router.navigate(['/admin/', selectedModule.moduleName.toLowerCase()]);
        } else {
          this.alertSerive.create("Current Selection Update Failed", "error", 0, result['message']);
        }
      },
      (error) => {
        // console.log("E::"+error);
        //this.alertSerive.create("API Error","error",0,error);
      }
    );
  }
}
