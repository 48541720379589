<html>
<style>
  .feature {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: 4rem;
    font-size: 2rem;
  }
</style>

<Body>
  <!-- Header-->
  <header class="py-5">
    <div class="container px-lg-5">
      <div class="p-4 p-lg-5 bg-light rounded-3 text-center">
        <div class="m-4 m-lg-5">
          <h1 class="display-5 fw-bold">A warm welcome to download!</h1>
        </div>
      </div>
    </div>
  </header>
  <!-- Page Content-->
  <section class="pt-4">
    <div class="container px-lg-5">
      <!-- Page Features-->
      <div class="row gx-lg-5">
        <div class="col-lg-6 col-xxl-4 mb-5">
          <div class="card bg-light border-0 h-100">
            <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
              <div class="feature btn bg-success bg-gradient text-white rounded-3 mb-4 mt-n4"
                (click)="vendorDownloadFile()"><i class="fa fa-download"></i>
              </div>
              <h2 class="fs-4 fw-bold">Download Vendor Application</h2>
              <!-- <p class="mb-0"></p> -->
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xxl-4 mb-5">
          <div class="card bg-light border-0 h-100">
            <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
              <div class="feature btn bg-success bg-gradient text-white rounded-3 mb-4 mt-n4"
                (click)="customerDownloadFile()"><i class="fa fa-download"></i>
              </div>
              <h2 class="fs-4 fw-bold">Download Customer Application</h2>
              <!-- <p class="mb-0"></p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Footer-->
  <footer class="py-5 bg-success">
    <div class="container">
      <p class="m-0 text-center text-white">Copyright &copy; Candidoffers {{currentYear}}</p>
    </div>
  </footer>


</Body>

</html>