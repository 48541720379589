<div class="otp">
  <ng-otp-input
    #ngOtpInput
    (onInputChange)="onOtpChange($event)"
    [config]="config"
  ></ng-otp-input>
  <button class="btn btn-success mt-4" (click)="handleClick()">
    Verify OTP
  </button>
</div>
