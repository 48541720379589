import { Component, OnInit,NgZone } from '@angular/core';
import {FormGroup,Validators,FormControl} from '@angular/forms'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vendor-sign-in',
  templateUrl: './vendor-sign-in.component.html',
  styleUrls: ['./vendor-sign-in.component.css']
})
export class VendorSignInComponent implements OnInit {

  reCaptchaVerifier!: any;

  constructor(private router: Router, private ngZone: NgZone) { }
  phoneNo = new FormControl("",)
  
  loginForm = new FormGroup({
    phoneNo:this.phoneNo
  })

  login(){
    this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
      }
    );
    console.log(this.reCaptchaVerifier);
    console.log(this.loginForm.value)
    firebase
      .auth()
      .signInWithPhoneNumber("+91"+this.loginForm.value.phoneNo, this.reCaptchaVerifier)
      .then((confirmationResult) => {
        localStorage.setItem(
          'verificationId',
          JSON.stringify(confirmationResult.verificationId)
        );
        this.ngZone.run(() => {
          this.router.navigate(['/verify-otp']);
        });
      })
      .catch((error) => {
        console.log(error.message);
        alert(error.message);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      });
  }
  ngOnInit(): void {
  }

}
