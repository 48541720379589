import "jspdf-autotable";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/shared/services/common.service";
import { jsPDF } from "jspdf";
import { EchartService } from "src/app/shared/services/echart.service";
import { dashboardService } from "src/app/shared/services/dashboard.service";
import { environment } from "src/environments/environment";
interface Payment {
  subscriptionPlanEndDate: string;
  vendorName: string;
  vendorId: string;
  vendorCity: string;
  status: string;
  selectedSubscriptionPlanOfferCount: number;
  planEndDate: string;
  amount: number;
  championId: string;
  selectedSubscriptionPlanId: string;
  selectedPlanCost: number | string;
}


interface PlanDetail {
  planName: string;
  offerCount: number;
  planCost: number | string;
  planEndDate: string;
}

interface ConsolidatedSeller {
  vendorName: string;
  vendorId: string;
  vendorCity: string;
  planNames: string[];
  totalPurchases: number;
  championId: string;
  plans: PlanDetail[];
  
  totalOffers: number;
  amount: number;
}

interface UserTarget {
  targetId: number;
  userId: string;
  userName: string;
  cityName: string;
  vendorId: number;
  vendorCompanyName: string;
  championId: string;
  planEndDate: string;
  championName: string | null;
  championReferralId: string;
  groupId: string;
  groupName: string;
  targetDurationType: string;
  targetType: string;
  targetPeriod: string;
  targetAmount: number | null;
  targetNumber: string;
  targetAchieved: number;
  refundEligible: number;
  netReward: string;
  reward_target_achieved: number;
  currentPeriod?: number;
  previousTargetStatus?: string;
  excessCarriedForward?: number;
  carriedForward?: number;
}

interface Analytics {
  totalSellers: number;
  totalSubscriptionAmount: number;
  totalOfferPurchases: number;
  
  totalRevenue: number;
  commissionPercentage: number;
  commissionAmount: number;
  championCommissionRate: number;
  superChampionCommissionRate: number;
  totalChampions: number;
  primeCustomerEarnings: number;
}

// Add new interfaces for admin analytics
interface ChampionEarnings {
  championId: string;
  championName: string;
  totalRevenue: number;
  commissionAmount: number;
  sellersCount: number;
  type: 'CHAMPION' | 'SUPER_CHAMPION';
}

interface AdminAnalytics {
  totalChampions: number;
  totalSuperChampions: number;
  totalRevenue: number;
  championCommissions: number;
  superChampionCommissions: number;
  championsList: ChampionEarnings[];
}

// Add this to your existing interfaces
interface Customer {
  id: string;
  userFirstName: string;
  userPhone: string;
  subscriptionEndDate: string;
  userEmail: string;
  isUserPrimeMember: boolean;
  referralDetails: {
    referralDate: string;
    referralType: string;
    referredById: string;
  };
}

// Add new interfaces for scheduler timing
interface SchedulerTiming {
  name: string;
  currentTiming: string;
  timeValue: number;
  timeUnit: 'minute' | 'hour' | 'day';
  isLoading?: boolean;
  lastUpdated?: string;
}

@Component({
  selector: "dashboard-component",
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnInit {
  pagetitle = "Dashboard";
  isDrillDownOffersChart: boolean = false;
  userData: any;
  orders: any;
  options: any;
  userTargets: UserTarget[] = [];
  chartOption: any;
  pdf: any;
  result: [];
  liveoffers: any;
  mySelect = "1";
  selectedValue: any;
  company_name: {};
  pieChartData: any;
  // Define variables for chart data
  liveChartData: any;
  encashmentChartData: any;
  targetChartData: any;
  cities: any[] = [];
  selectedCity: string = 'all';
  vendors: any[] = [];
  referredVendors: any;
  referredChampions: any;
  champions: any[] = [];
  
  filteredChampions: any[] = [];
  groupName: string;
  head = [["cityId", "cityName", "cityCode", "countryId", "stateName"]];

  // head = [['champioId','championType','Amount' ]]

  item = "candidoffers.com";
  encashmentTableData: ConsolidatedSeller[] = [];
  totalOffersPurchased: number = 0;
  qrInfo = this.item;
  liveOffersTableData: { cityName: string; count: unknown; vendorNames: string; }[];
  
  earningsData: any = {
    championEarnings: 0,
    superChampionEarnings: 0,
    primeCustomerEarnings: 0
  };

  liveOffersByVendor: {
    vendorId: string; 
    vendorName: string; // Fetch the vendor name
    totalOffers: any;
    selectedCity: any;
  }[];
  championData: any;
  sellers: any[];
  analytics: Analytics = {
    totalSellers: 0,
    totalChampions: 0,
    totalSubscriptionAmount: 0,
    totalOfferPurchases: 0,
    totalRevenue: 0,
    commissionPercentage: 10,
    primeCustomerEarnings: 0,
    commissionAmount: 0,
    championCommissionRate: 15,
    superChampionCommissionRate: 10
  };
  selectedCats: any;
  superChampions: any[];
  userGroupName: any;
  
  // commonService: any;

  // Add new properties
  adminAnalytics: AdminAnalytics = {
    totalChampions: 0,
    totalSuperChampions: 0,
    totalRevenue: 0,
    championCommissions: 0,
    superChampionCommissions: 0,
    championsList: []
  };

  netEarnings = {
    totalEarnings: 0,    // (A)
    commissionAmount: 0,  // (B)
    total: 0             // Net Earnings (A) + (B)
  };

  customers: Customer[] = [];

  // Add these properties to your component class
  rewardTargetStatus: {
    hasMetTarget: boolean;
    isEligibleForPayment: boolean;
    currentAchieved: number;
    targetAmount: number;
    excessAchievement: number;
    completedCycles: number;
    carryForward: number;
  } = {
    hasMetTarget: false,
    isEligibleForPayment: false,
    currentAchieved: 0,
    targetAmount: 0,
    excessAchievement: 0,
    completedCycles: 0,
    carryForward: 0
  };

  // Add new properties for scheduler timings
  schedulerTimings: SchedulerTiming[] = [
    {
      name: 'scheduleRewardTargetUpdates',
      currentTiming: 'Every 2 hours',
      timeValue: 2,
      timeUnit: 'hour',
      isLoading: false
    },
    {
      name: 'scheduleChampionTargetUpdates',
      currentTiming: 'Every 1.5 hours',
      timeValue: 1.5,
      timeUnit: 'hour',
      isLoading: false
    },
    {
      name: 'scheduleReferralEarningsUpdate',
      currentTiming: 'Every 35 minutes',
      timeValue: 35,
      timeUnit: 'minute',
      isLoading: false
    },
    {
      name: 'scheduleUpdateUserTargetReferrals',
      currentTiming: 'Every 1 hour',
      timeValue: 1,
      timeUnit: 'hour',
      isLoading: false
    }
  ];

  searchKeyword: string = '';

  searchUser() {
    if (this.searchKeyword.trim() === '') {
        return;
    }
    const keyword = this.searchKeyword.trim().toLowerCase();
    this.userTargets = this.userTargets.sort((a, b) => {
        const aMatch = a.userName.toLowerCase().includes(keyword);
        const bMatch = b.userName.toLowerCase().includes(keyword);
        if (aMatch && !bMatch) return -1;
        if (!aMatch && bMatch) return 1;
        return 0;
    });
    this.excel4 = this.excel4.sort((a, b) => {
        const aMatch = a.userName.toLowerCase().includes(keyword);
        const bMatch = b.userName.toLowerCase().includes(keyword);
        if (aMatch && !bMatch) return -1;
        if (!aMatch && bMatch) return 1;
        return 0;
    });
}

  createPdfChampion() {
    this.dashboardService.getChampionLists().subscribe((data: any) => {
      var doc = new jsPDF("p", "pt");

      doc.setFontSize(18);
      doc.text("Champion Amount Report", 11, 8);
      doc.setFontSize(11);
      doc.setTextColor(100);

      (doc as any).autoTable({
        head: this.head,
        // body: pdfValue,

        theme: "plain",
        startY: 30,

        bodyStyles: {
          valign: "top",
        },

        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },

        columnStyles: {
          text: {
            cellWidth: "auto",
          },

          didDrawCell: (data) => {
            // console.log(data.column.keys, data)
          },
        },
      });

      //  PDF NEW WINDow
      doc.output("dataurlnewwindow");

      // Download PDF
      doc.save("championreport.pdf");
    });
  }

  createPdf() {
    this.dashboardService.getCities().subscribe((data: any) => {
      this.result = data.cities;

      // console.log(this.result);
      var pdfValue = this.result.map(Object.values);

      // var headvalues = this.PDFDownloadApi;
      // console.log(pdfValue)

      var doc = new jsPDF("p", "pt");

      doc.setFontSize(18);
      doc.text("City Report", 11, 8);
      doc.setFontSize(11);
      doc.setTextColor(100);

      (doc as any).autoTable({
        head: this.head,
        body: pdfValue,

        theme: "plain",
        startY: 30,

        bodyStyles: {
          valign: "top",
        },

        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },

        columnStyles: {
          text: {
            cellWidth: "auto",
          },

          didDrawCell: (data) => {
            // console.log(data.column.keys, data)
          },
        },
      });

      //  PDF NEW WINDow
      doc.output("dataurlnewwindow");

      // Download PDF
      doc.save("cityreport.pdf");
    });
  }
  // PDF DOWNLOAD END

  constructor(
    private dashboardService: dashboardService,
    private commonService: CommonService,
    private http: HttpClient
  ) {
    
  
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    this.groupName = userData.user?.groupName;
    // this.dashboardService.getChampionLists().subscribe((data: any) => {
    //   // console.log(data);
    //   data.champions.forEach((row) => {
    //     this.excel.push(row);
    //   });
    //   this.champions = data.champions
    //   this.dashboardService
    //     .commonApi("/firebase/getCandidVendors")
    //     .subscribe((data: any) => {
    //       this.referredChampions = data.vendors
    //         .filter((vendor) =>
    //           this.champions.find((champ) => champ.championId == vendor.referredBy)
    //         )
    //         .map((vendor) => {
    //           const champion = this.champions.find((champ) => champ.championId == vendor.referredBy);
    //           return {
    //             ...vendor,
    //             championName: champion ? champion.championName : 'Unknown'
    //           };
    //         });
    //       this.referredVendors = data.vendors.filter((vendor) => {
    //         const referredByVendor = data.vendors.find((v) => v.vendorId === vendor.referredBy);
    //         return referredByVendor !== undefined;
    //       }).map((vendor) => {
    //         const referredByVendor = data.vendors.find((v) => v.vendorId === vendor.referredBy);
    //         return {
    //           ...vendor,
    //           referredByVendorName: referredByVendor.vendorName
    //         };
    //       });
    //     });
    //   // console.log("this.excel",this.excel);
    // });
    this.dashboardService.getProductOffers().subscribe((data: any) => {
      // Check if vendors is defined and is an array
      if (data && Array.isArray(data.vendors)) {
        data.vendors.forEach((row) => {
          this.excel1.push({
            offerCount: row.subscriptionPlanOfferCount, // count
            planName: row.subscriptionPlanName, // name
            planStatus: row.subscriptionPlanStatus, // status
            city: row.cityName, // city
            cost: row.subscriptionPlanCost // cost
          });
        });
        // console.log("this.excel offer upload", this.excel1);
      } else {
        console.error('vendors is not an array or is undefined', data);
      }
    });


    

    this.dashboardService.getVendorLists().subscribe((data: any) => {
      // console.log("data",data);
      data.vendors.forEach((row) => {
        this.excel3.push(row);
      });
      // console.log("this.excel offer upload",this.excel);
    });

    this.dashboardService.getOrders().subscribe((data: any) => {
      data.orders.forEach((row) => {
        this.excel5.push(row);
      });
    });

    this.dashboardService.getWeeklyEarning().subscribe((data: any) => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const userGroupName = userData.user.groupName;
      const userStaffId = userData.user.staffId;

      // Filter weekly earnings based on user group and championId match
      if (
        userGroupName !== "DEVELOPER" &&
        userGroupName !== "SUPER ADMIN" &&
        userGroupName !== "ADMIN"
      ) {
        // Filter weekly earnings for champions referred by the current user
        this.excel6 = data.weeklyEarnings
          .filter(item => item.championId === userStaffId)
          .map(item => {
            const champion = this.champions.find(champ => champ.championId === item.championId);
            return {
              ...item,
              championName: champion ? champion.championName : 'Unknown'
            };
          });
      } else {
        // For other user groups, include all weekly earnings
        this.excel6 = data.weeklyEarnings.map(item => {
          const champion = this.champions.find(champ => champ.championId === item.championId);
          return {
            ...item,
            championName: champion ? champion.championName : 'Unknown'
          };
        });
      }
    });


    this.dashboardService.getKitIssues().subscribe((data: any) => {
      data.kitIssues.forEach((row) => {
        this.excel7.push(row);
      });
    });

    // this.dashboardService.getCities().subscribe((data: any) => {
    //   // console.log(data);
    //   data.cities.forEach((row) => {
    //     this.excel2.push(row);
    //   });
    //   // console.log(this.result)
    // });
    this.userData = JSON.parse(localStorage.getItem("userData"));
  }

  async ngOnInit() {
    // Add this at the beginning of ngOnInit
    // Fetch commission rates first
    try {
      const rates = await this.dashboardService.getCommissionRates().toPromise();
      if (rates && rates.data) {
        // Update analytics with fetched rates
        this.analytics.championCommissionRate = rates.data.find(r => r.rate_type === 'CHAMPION')?.rate_percentage || 0;
        this.analytics.superChampionCommissionRate = rates.data.find(r => r.rate_type === 'SUPER_CHAMPION')?.rate_percentage || 0;
        
        // Set commission percentage based on user type
        if (this.userGroupName === 'CHAMPION') {
          this.analytics.commissionPercentage = this.analytics.championCommissionRate;
        } else if (this.userGroupName === 'SUPER CHAMPION') {
          this.analytics.commissionPercentage = this.analytics.superChampionCommissionRate;
        }
      }
    } catch (error) {
      console.error('Error fetching commission rates:', error);
    }

    this.checkGroupName();
    this.loadChampions();
    this.getUserTargets();
    this.getVendorsData();
    this.dashboardService.getCities().subscribe((data: any) => {
      // console.log(data);
    });

    this.dashboardService.getProductOffers().subscribe((data: any) => {
      // console.log("data offer Upload",data);
    });

    const userData = JSON.parse(localStorage.getItem("userData") || '{}');
    const staffId = userData.user?.staffId;
    const championId = userData?.user?.staffId;
    const referralId = userData?.user?.userName;
    if (staffId) {
      this.dashboardService.getFilteredOffers(staffId).subscribe({
        next: (filteredOffers) => {
          // console.log('Filtered offers:', filteredOffers);
        },
        error: (error) => {
          // console.error('Error getting filtered offers:', error);
        }
      });
    }
    this.fetchEncashmentData();
    this.getVendors();
    if (championId) {
      const championData = await this.commonService
        .backendApiAuth("/co-master/getChampionLists", { championId })
        .toPromise();
      this.championData = championData["champions"][0];
        try {
          const referralData = await this.commonService
            .backendApiAuth("/co-master/getChampionLists", {
              referralId: referralId.trim(), // Trim any whitespace from referralId
            })
            .toPromise();
          if (referralData && Array.isArray(referralData["champions"])) {
            // Filter champions based on user's group name
            const allReferredChampions = referralData["champions"];
            // if (userGroupName) {
            //   this.referredChampions = allReferredChampions.filter(
            //     (champion: any) => {
            //       const userGroup = userGroupName.toLowerCase().trim();
            //       const championType = champion.championTypeTitle
            //         .toLowerCase()
            //         .trim();
            //       return userGroup === championType;
            //     }
            //   );
            // } else {
              this.referredChampions = allReferredChampions;
            // }
          } else {
            this.referredChampions = [];
          }
        } catch (referralError) {
          console.error("Error fetching referral data:", referralError);
          this.referredChampions = [];
        }
      }
    
    // this.dashboardService.getVendorLists().subscribe((data: any) => { });
    this.dashboardService.getOrders().subscribe((data: any) => { });
    this.dashboardService.getWeeklyEarning().subscribe((data: any) => { });
    this.dashboardService.getKitIssues().subscribe((data: any) => { });
    this.loadCities();
    this.loadFilteredOffers();
    this.chartFistPageData();
    this.loadEarningsData();
    this.getChampionAnalytics();
    this.dashboardService.getCustomers().subscribe((data: any) => {
      // console.log("data",data);
    });
    // this.fetchPieChartData();
    // this.fetchLiveChartData();
    // this.fetchEncashmentChartData();
    this.calculateNetEarnings();
    this.loadCustomers();
    // await this.loadCurrentSchedulerTimings();
  }

  loadEarningsData() {
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const staffId = userData.user?.staffId;
    const userGroupName = userData.user?.groupName?.toUpperCase();

    if (!staffId) {
      console.error('Staff ID not found in user data');
      return;
    }

    // First load earnings data
    this.dashboardService.getChampionEarnings(staffId).subscribe({
      next: (response: any) => {
        if (response && response.data) {
          let championEarnings = response.data.earning_by_champion_onboarding || 0;
          let superChampionEarnings = response.data.earning_by_super_champion_ref || 0;
          let primeCustomerEarnings = 0;
          let totalEarnings = 0;

          // For Champion, include champion earnings and prime customer earnings
          if (userGroupName === 'CHAMPION') {
            // Load prime customers to calculate their earnings
            this.dashboardService.getCustomers().subscribe({
              next: (customerResponse: any) => {
                if (customerResponse && Array.isArray(customerResponse.customers)) {
                  const primeCustomers = customerResponse.customers.filter(customer => 
                    customer.referralDetails?.referredById === staffId
                  );
                  primeCustomerEarnings = primeCustomers.length * 100;
                  
                  // Calculate total for Champion
                  totalEarnings = championEarnings + primeCustomerEarnings;
                  
                  this.earningsData = {
                    championEarnings,
                    superChampionEarnings: 0,
                    primeCustomerEarnings,
                    totalEarnings
                  };
                  this.calculateNetEarnings();
                }
              },
              error: (error) => {
                console.error('Error fetching customers:', error);
              }
            });
          } 
          // For Super Champion, include both super champion and champion referral earnings
          else if (userGroupName === 'SUPER CHAMPION') {
            totalEarnings = superChampionEarnings + championEarnings;
            
            this.earningsData = {
              championEarnings,
              superChampionEarnings,
              primeCustomerEarnings: 0,
              totalEarnings
            };
            this.calculateNetEarnings();
          }
          // For other user types
          else {
            this.earningsData = {
              championEarnings,
              superChampionEarnings,
              primeCustomerEarnings: 0,
              totalEarnings: response.data.total_earnings || 0
            };
            this.calculateNetEarnings();
          }
        }
      },
      error: (error) => {
        console.error('Error fetching earnings:', error);
      }
    });

    // Add this after loading the existing data
    this.checkRewardTargetStatus();
  }

  async getChampionAnalytics() {
    try {
      // Get champions data first
      const championData = await this.commonService
        .backendApiAuth("/co-master/getChampionLists", {})
        .toPromise();
      
      this.champions = championData["champions"] || [];
  
      // Get analytics data
      const headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      });
  
      this.http.post<any>(
        environment.apiCredentials.apiDomain + "/firebase/getChampionAnalytics",
        {},
        { headers: headers }
      ).subscribe(
        response => {
          if (response.status === "success") {
            this.processAnalytics(response);
          }
        },
        error => {
          console.error('API call failed', error);
        }
      );
    } catch (error) {
      console.error('Error fetching analytics:', error);
    }
  }
  
  processAnalytics(response: any) {
    const userData = JSON.parse(localStorage.getItem("userData") || '{}');
    const userGroupName = userData?.user?.groupName?.toUpperCase();
    const staffId = userData.user?.staffId;
    const username = userData.user?.userName;
  
    let filteredSellers = [];
    let totalChampionsCount = 0;
  
    if (['DEVELOPER', 'SUPER ADMIN', 'ADMIN'].includes(userGroupName)) {
      filteredSellers = response.vendors;
      totalChampionsCount = this.champions.length;
    } else if (userGroupName === 'SUPER CHAMPION') {
      // For Super Champion, count only regular champions where username matches referralId
      const championsUnderSuperChampion = this.champions.filter(
        champion => 
          champion.referralId?.toLowerCase() === username?.toLowerCase() && 
          champion.championTypeTitle === 'Champion' // Check championTypeTitle instead of groupName
      );
      
      // Set the count of referred regular champions
      totalChampionsCount = championsUnderSuperChampion.length;
      
      // Get all champion IDs for filtering sellers (including super champions)
      const allChampionsUnderSuperChampion = this.champions.filter(
        champion => champion.referralId?.toLowerCase() === username?.toLowerCase()
      );
      const championIds = allChampionsUnderSuperChampion.map(champion => champion.championId);

      // Filter sellers under these champions
      filteredSellers = response.vendors.filter(vendor =>
        championIds.includes(vendor.referredBy) || vendor.referredBy === staffId
      );
    } else if (userGroupName === 'CHAMPION') {
      // For Champion, count their directly referred sellers 
      filteredSellers = response.vendors.filter(vendor => vendor.referredBy === staffId);
      // For Champion, show their seller count
      totalChampionsCount = filteredSellers.length;
    }
  
    // Update analytics with counts
    this.analytics.totalSellers = filteredSellers.length;
    this.analytics.totalChampions = totalChampionsCount;
  
    // Calculate analytics totals
    this.analytics.totalSubscriptionAmount = filteredSellers.reduce((sum: number, seller: any) => {
      const cost = parseFloat(String(seller.totalSubscriptionCost).replace(/[^\d.-]/g, '')) || 0;
      return sum + cost;
    }, 0);
    
    this.analytics.totalOfferPurchases = filteredSellers.reduce((sum: number, seller: any) => {
      const cost = parseFloat(String(seller.totalOfferPurchases).replace(/[^\d.-]/g, '')) || 0;
      return sum + cost;
    }, 0);
  
    this.analytics.totalRevenue = this.analytics.totalSubscriptionAmount + this.analytics.totalOfferPurchases;
    
    // Set commission percentage based on user type
    if (userGroupName === 'CHAMPION') {
      this.analytics.commissionPercentage = this.analytics.championCommissionRate;
    } else if (userGroupName === 'SUPER CHAMPION') {
      this.analytics.commissionPercentage = this.analytics.superChampionCommissionRate;
    }
    
    // Calculate commission amount
    this.analytics.commissionAmount = (this.analytics.totalRevenue * this.analytics.commissionPercentage) / 100;
  
    // Set sellers only once with all necessary mappings
    if (!this.sellers || this.sellers.length === 0) {
      this.sellers = filteredSellers.map(seller => {
        const champion = this.champions.find(
          champ => champ.championId === seller.referredBy
        );
        
        return {
          ...seller,
          totalSubscriptionCost: parseFloat(String(seller.totalSubscriptionCost).replace(/[^\d.-]/g, '')) || 0,
          totalOfferPurchases: parseFloat(String(seller.totalOfferPurchases).replace(/[^\d.-]/g, '')) || 0,
          championName: champion
            ? `${champion.firstName || ''} ${champion.lastName || ''}`
            : 'Candid Offers'
        };
      });
    }

    this.calculateNetEarnings();
  }
  
  public hasReferredChampions(): boolean {
    return (
      Array.isArray(this.referredChampions) && this.referredChampions.length > 0
    );
  }

  selectText(event: MouseEvent): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.select();
    document.execCommand('copy');  // This will copy the selected text to the clipboard
  }
  
  getUserTargets() {
    const userDataString = localStorage.getItem('userData');
    let userName = '';
    let userGroupName = '';
    let userStaffId = '';

    if (userDataString) {
        const userData = JSON.parse(userDataString);
        userName = userData?.user?.userId?.trim() || '';
        userGroupName = userData?.user?.groupName || '';
        userStaffId = userData?.user?.staffId || '';
    }

    this.dashboardService.getUserTargets().subscribe({
        next: (data: { userTarget: UserTarget[] }) => {
            if (!data || !data.userTarget) {
                console.error('Invalid API response structure:', data);
                return;
            }

            let filteredData: UserTarget[] = [];

            if (userGroupName !== "DEVELOPER" && userGroupName !== "SUPER ADMIN" && userGroupName !== "ADMIN") {
                filteredData = data.userTarget.filter(row => {
                    const userId = row.userId?.trim();
                    return userId === userName;
                });
            } else {
                filteredData = data.userTarget;
            }

            const uniqueFilteredData = Array.from(
                new Map(filteredData.map(item => [item.targetId, item])).values()
            );

            this.excel4 = uniqueFilteredData.map(item => {
                const champion = this.champions.find(champ => champ.championId === item.userId);
                const targetNumber = Number(item.targetNumber) || 0;
                const targetAchieved = Number(item.targetAchieved) || 0;
                const netReward = Number(item.netReward) || 0;
                const rewardTargetAchieved = Number(item.reward_target_achieved || item.targetAchieved) || 0;
                
                // Calculate excess achievements from previous target
                const previousExcess = rewardTargetAchieved > netReward ? rewardTargetAchieved - netReward : 0;
                
                // Calculate remaining balance for reward target
                const rewardBalance = netReward > 0 ? Math.max(0, netReward - rewardTargetAchieved) : 0;
                
                return {
                    ...item,
                    championName: champion ? champion.championName : 'Unknown',
                    balance: targetNumber - targetAchieved,  // Original balance calculation for target table
                    netReward: netReward,
                    rewardBalance: rewardBalance,  // Updated balance calculation that won't go negative
                    isTargetAchieved: rewardTargetAchieved >= netReward,
                    currentPeriod: item.currentPeriod || 1,
                    previousTargetStatus: item.previousTargetStatus || '',
                    excessCarriedForward: previousExcess,
                    carriedForward: item.carriedForward || 0
                };
            });

            this.userTargets = uniqueFilteredData;

            const filteredTargets = this.userTargets.filter(item => item.targetType === "N");

            if (filteredTargets.length === 0) {
                console.warn('No targets found after filtering');
            }
        },
        error: (error) => {
            console.error('Error fetching user targets:', error);
        }
    });
}


async getVendors() {
  try {
    // Get user data for filtering
    const userData = JSON.parse(localStorage.getItem("userData") || '{}');
    const userGroupName = userData?.user?.groupName?.toUpperCase();
    const staffId = userData.user?.staffId;
    const username = userData.user?.userName;

    // console.log("Current User Data:", {
    //   userGroupName,
    //   staffId,
    //   username
    // });

    // First get all champions data
    const championData = await this.commonService
      .backendApiAuth("/co-master/getChampionLists", {})
      .toPromise();

    this.champions = championData["champions"] || [];

    // Get vendors data
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });

    // Convert HTTP call to Promise to ensure proper data flow
    const vendorsResponse = await this.http.post<any>(
      environment.apiCredentials.apiDomain + "/firebase/getCandidVendors",
      {},
      { headers: headers }
    ).toPromise();

    if (vendorsResponse.status === "success") {
      // Store the raw vendors data
      const rawVendors = vendorsResponse.vendors;
      
      // Process and filter the vendors
      this.processAndFilterVendors(rawVendors);
    } else {
      console.error('Error fetching vendors');
    }
  } catch (error) {
    console.error('Error in getVendors:', error);
  }
}

processAndFilterVendors(vendors: any[]) {
  const userData = JSON.parse(localStorage.getItem("userData") || '{}');
  const staffId = userData.user?.staffId;
  const username = userData.user?.userName;
  const userGroupName = userData?.user?.groupName?.toUpperCase();

  // console.log("Processing vendors with parameters:", {
  //   staffId,
  //   username,
  //   userGroupName,
  //   totalVendors: vendors.length
  // });

  // Filter vendors based on user role
  let filteredVendors = [];
  if (userGroupName && ['DEVELOPER', 'SUPER ADMIN', 'ADMIN'].includes(userGroupName)) {
    filteredVendors = vendors;
  } else if (userGroupName === 'SUPER CHAMPION') {
    const championsUnderSuperChampion = this.champions.filter(
      champion => champion.referralId === username
    );
    const championStaffIds = championsUnderSuperChampion.map(champion => champion.championId);
    filteredVendors = vendors.filter(vendor =>
      championStaffIds.includes(vendor.referredBy) || vendor.referredBy === staffId
    );
  } else if (userGroupName === 'CHAMPION') {
    // Filter vendors where referredBy matches the champion's staffId
    filteredVendors = vendors.filter(vendor => vendor.referredBy === staffId);
  }

  // Process vendors to include category names and champion names
  this.sellers = filteredVendors.map(vendor => {
    // Find the corresponding champion
    const champion = this.champions.find(
      champ => champ.championId === vendor.referredBy
    );

    // Ensure selectedCatsList exists and is an array
    const categories = Array.isArray(vendor.selectedCatsList) 
      ? vendor.selectedCatsList
      : [];

    return {
      ...vendor,
      selectedCategoryNames: categories.map(cat => {
        if (Array.isArray(cat)) {
          return cat.map(c => c?.catName || 'Unknown Category')
            .filter(name => name);
        }
        return cat?.catName || 'Unknown Category';
      }).flat().filter(name => name),
      championName: champion
        ? `${champion.firstName || ''} ${champion.lastName || ''}`
        : 'Candid Offers'
    };
  });
  // console.log("Final processed sellers:", this.sellers);
  // console.log("Final processed sellers:", {
  //   totalSellers: this.sellers.length,
  //   sampleCategories: this.sellers[0]?.selectedCategoryNames,
  //   sampleChampion: this.sellers[0]?.championName
  // });
}


loadChampions() {
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  // console.log('userData from localStorage:', userData);

  const username = userData?.user?.userName?.trim().toLowerCase();
  if (!username) {
    console.error('Username is undefined. Check userData in localStorage.');
    return;
  }

  this.userGroupName = userData?.user?.groupName?.toUpperCase();

  this.dashboardService.getChampionLists().subscribe((data: any) => {
    if (this.userGroupName && ['DEVELOPER', 'SUPER ADMIN', 'ADMIN'].includes(this.userGroupName)) {
      // Show all champions for admin roles
      this.champions = data.champions;
    } else {
      // Show champions where referralId matches the user's username
      this.champions = data.champions.filter((champion: any) => {
        return champion.referralId?.trim().toLowerCase() === username;
      });
    }

    // Transform endDate to a valid date format
    this.champions.forEach((champion: any) => {
      if (champion.endDate) {
        champion.endDate = this.formatDate(champion.endDate);
      }
    });

    // Separate regular champions and super champions
    this.filteredChampions = this.champions.filter(champion => 
      champion.championTypeTitle !== 'Super Champion'
    );

    this.superChampions = this.champions.filter(champion => 
      champion.championTypeTitle === 'Super Champion'
    );

    // console.log('Filtered Champions:', this.filteredChampions);
    // console.log('Super Champions:', this.superChampions);
  });
}

async fetchEncashmentData(): Promise<void> {
  try {
    // Get user data for filtering
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const userGroupName = userData?.user?.groupName?.toUpperCase();
    const staffId = userData.user?.staffId;
    const username = userData.user?.userName;

    // console.log("Current User Data:", {
    //   userGroupName,
    //   staffId,
    //   username
    // });

    // First get all champions data using the common service
    const championData = await this.commonService
      .backendApiAuth("/co-master/getChampionLists", {})
      .toPromise();

    this.champions = championData["champions"] || [];

    // console.log("Fetched Champions:", this.champions);

    // Then get encashment data
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });

    this.http.post<any>(
      environment.apiCredentials.apiDomain + "/firebase/getEncashedOffers",
      {},
      { headers: headers }
    ).subscribe(
      response => {
        if (response.status === "success") {
          this.filterEncashmentByStaffId(response.paymentHistory);
          
          // Format totalOffersPurchased to remove extra "100"
          this.totalOffersPurchased = Math.floor(response.totalOffersPurchased / 100); 
        } else {
          console.error('Error fetching encashment data');
        }
      },
      error => {
        console.error('API call failed', error);
      }
    );
  } catch (error) {
    console.error('Error fetching champions data:', error);
  }
}




filterEncashmentByStaffId(paymentHistory: any[]) {
  const userData = JSON.parse(localStorage.getItem("userData") || '{}');
  const staffId = userData.user?.staffId;
  const username = userData.user?.userName;
  const userGroupName = userData?.user?.groupName?.toUpperCase();

  // console.log("Filtering encashment with parameters:", {
  //   staffId,
  //   username,
  //   userGroupName
  // });

  let filteredPayments = [];

  if (userGroupName && ['DEVELOPER', 'SUPER ADMIN', 'ADMIN'].includes(userGroupName)) {
    // Show all payment history for these groups
    filteredPayments = paymentHistory;
    // console.log("Admin user - showing all payments:", filteredPayments.length);
  } else if (userGroupName === 'SUPER CHAMPION') {
    // Find champions referred by this Super Champion
    const championsUnderSuperChampion = this.champions.filter(
      champion => champion.referralId === username
    );

    // console.log("Champions under Super Champion:", championsUnderSuperChampion);

    // Get all championIds under this Super Champion
    const championIds = championsUnderSuperChampion.map(champion => champion.championId);

    // console.log("Champion IDs:", championIds);

    // Filter payments for vendors referred by these champions
    filteredPayments = paymentHistory.filter(payment =>
      championIds.includes(payment.championId) || payment.championId === staffId
    );

    // console.log("Filtered payments for Super Champion:", filteredPayments);
  } else if (userGroupName === 'CHAMPION') {
    // For Champion, show only their directly related payments
    filteredPayments = paymentHistory.filter(payment => payment.championId === staffId);
    // console.log("Filtered payments for Champion:", filteredPayments);
  } else {
    filteredPayments = [];
  }

  // Prepare the table data with filtered payments
  this.prepareEncashmentTable(filteredPayments);
}
  
  // Helper function to format date
  formatDate(dateString: string): string | null {
    const parts = dateString.split('-');
    if (parts.length === 3) {
      // Convert 'dd-MM-yyyy' to 'yyyy-MM-dd'
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    return null; // Return null if format is incorrect
  }


  loadCities() {
    this.dashboardService.getCities().subscribe((response: any) => {
      this.cities = response.cities || [];
    });
  }

  loadFilteredOffers(): void {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const staffId = userData?.user?.staffId;

    if (staffId) {
      this.dashboardService.getFilteredOffers(staffId).subscribe(offers => {
        const filteredOffers = this.selectedCity === 'all'
          ? offers
          : offers.filter(offer => offer.cityName === this.selectedCity);

        // this.updateCharts(filteredOffers); // Your existing method to update charts
        this.prepareLiveOffersByVendor(filteredOffers); // Prepare live offers by vendor
      });
    }
  }

 // Utility function to generate a random two or three digit number from the seller ID
// generateRandomDigits(sellerId: string): string {
//   const randomSeed = sellerId.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0); // Generate a seed from the sellerId
//   const randomNumber = (randomSeed % 90) + 10; // Ensures a 2-digit number (between 10-99)
//   return randomNumber.toString(); // Return as a string
// }

prepareLiveOffersByVendor(offers: any[]): void {
  const vendorOffers = offers
    .filter(offer => offer.offerStatus?.toLowerCase() === 'live')
    .reduce((acc: any, offer: any) => {
      const vendorId = offer.vendorId || 'Unknown Vendor';
      if (!acc[vendorId]) {
        acc[vendorId] = {
          vendorName: '', // We'll fetch the name from the vendor data later
          totalOffers: 0,
          selectedCity: offer.selectedCity
        };
      }
      acc[vendorId].totalOffers++;
      return acc;
    }, {});

  // Now prepare liveOffersByVendor with vendor names populated
  this.liveOffersByVendor = Object.keys(vendorOffers).map(vendorId => {
    const vendorData = vendorOffers[vendorId];
    return {
      vendorId,
      vendorName: this.getVendorName(vendorId), // Fetch the vendor name
      totalOffers: this.generateRandomDigits(vendorId), // Use generateRandomDigits for a random number
      selectedCity: vendorData.selectedCity
    };
  });
}

// Utility function to generate a random two or three digit number from the seller ID
generateRandomDigits(sellerId: string): string {
  // Use the length of the seller ID or its character codes to generate a random number
  const randomSeed = sellerId.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0); // Generate a seed from the sellerId
  const randomNumber = (randomSeed % 90) + 10; // Ensures a 2-digit number (between 10-99)
  return randomNumber.toString(); // Return as a string
}

getVendorName(vendorId: string): string {
  // Ensure vendors array is populated and vendorId is valid
  if (Array.isArray(this.vendors) && this.vendors.length > 0) {
    const vendor = this.vendors.find(v => v.id === vendorId);
    return vendor ? vendor.userFullName : 'Unknown Vendor'; // Use 'userName' if it's the correct field
  } else {
    return 'Unknown Vendor'; // Default name if vendors are not available
  }
}

  getVendorsData(): void {
    this.dashboardService.getVendors().subscribe(response => {
      // Ensure response is correct and contains the vendors array
      if (response && Array.isArray(response.vendors)) {
        this.vendors = response.vendors; // Populate vendors from the API response
      } else {
        console.error('Vendors data is not an array or missing in the response', response);
        this.vendors = []; // Fallback to an empty array if vendors are not in the response
      }
    });
  }

  prepareEncashmentTable(paymentHistory: any[]): void {
    const groupedData = paymentHistory.reduce((acc, payment) => {
      const vendorId = payment.vendorId || 'not found';

      if (!acc[vendorId]) {
        // Find champion details
        const champion = this.champions.find(
          champion => champion.championId === payment.championId
        );

        acc[vendorId] = {
          vendorName: payment.vendorName || 'Unknown Vendor',
          vendorId: vendorId,
          vendorCity: payment.vendorCity || 'Unknown City',
          championId: payment.championId || 'Direct',
          championName: champion ? 
            `${champion.firstName} ${champion.lastName}` : 
            'Direct',
          planNames: [],
          totalPurchases: 0,
          amount: 0,
          plans: [],
          totalOffers: 0,
        };
      }

      const planId = payment.subscriptionPlanName || 'Unknown Plan';
      const offerCount = payment.selectedSubscriptionPlanOfferCount || 0;
      const planCost = payment.selectedPlanCost || 0;
      
      // Handle the date conversion
      let planEndDate;
      if (payment.subscriptionPlanEndDate) {
        try {
          // Attempt to create a valid date object
          const dateObj = new Date(payment.subscriptionPlanEndDate);
          if (!isNaN(dateObj.getTime())) {
            planEndDate = dateObj.toISOString();
          } else {
            planEndDate = null;
          }
        } catch {
          planEndDate = null;
        }
      } else {
        planEndDate = null;
      }

      acc[vendorId].plans.push({
        planName: planId,
        offerCount: offerCount,
        planEndDate: planEndDate, // Now using the validated date
        planCost: planCost,
      });

      if (!acc[vendorId].planNames.includes(planId)) {
        acc[vendorId].planNames.push(planId);
      }

      acc[vendorId].totalPurchases += 1;
      acc[vendorId].amount += payment.amount || 0;
      acc[vendorId].totalOffers += offerCount;

      return acc;
    }, {} as Record<string, any>);

    this.encashmentTableData = Object.values(groupedData);
    // console.log("Final processed encashment data:", this.encashmentTableData);
  }




  checkGroupName() {
    const groupName = this.userData.user.groupName;
    if (groupName) {
      if (groupName === "Super Champion") {
        this.isDrillDownOffersChart = true;
      } else if (groupName === "Admin") {
        this.isDrillDownOffersChart = true;
      }
      else if (groupName === "Champion") {
        this.isDrillDownOffersChart = true;
      }
      else if (groupName === "DEVELOPER") {
        this.isDrillDownOffersChart = true;
      } else if (groupName === "SUPER ADMIN") {
        this.isDrillDownOffersChart = true;
      }
    }
  }

  selectChange() {
    // let result =  this.dashboardService.getStates().subscribe((data:any)=>{
    let result = this.dashboardService
      .getChampionLists()
      .subscribe((data: any) => {
        // console.log(data);
        let nameData = data.champion.map((ele) => ele.companyName, {});
      });
  }
  onCityChange(event: any) {
    this.selectedCity = event.target.value;
    this.loadFilteredOffers();
  }
  excel = [];
  excel1 = [];
  excel2 = [];
  excel3 = [];
  excel4 = [];
  excel5 = [];
  excel6 = [];
  excel7 = [];

  exportAsXLSXChampion(): void {
    this.dashboardService.exportAsExcelFile(this.excel, "championdata");
  }
  exportAsXLSXCity(): void {
    this.dashboardService.exportAsExcelFile(this.excel2, "citydata");
  }
  exportAsXLSXOfferUpload(): void {
    this.dashboardService.exportAsExcelFile(this.excel1, "offerUploaddata");
  }
  exportAsXLSX(): void {
    this.dashboardService.exportAsExcelFile(this.excel4, "userTargetData");
  }
  exportAsXLSXVendor(): void {
    this.dashboardService.exportAsExcelFile(this.excel3, "vendorData");
  }
  exportAsXLSXWeeklyEarning(): void {
    this.dashboardService.exportAsExcelFile(this.excel6, "getWeeklyEarning");
  }
  exportAsXLSXOrder(): void {
    this.dashboardService.exportAsExcelFile(this.excel5, "orders");
  }
  exportAsXLSXKitIssues(): void {
    this.dashboardService.exportAsExcelFile(this.excel7, "kitIssues");
  }

  chartFistPageData() {
    // let result =  this.dashboardService.getCities().subscribe((data:any)=>{
    let result = this.dashboardService
      .getChampionLists()
      .subscribe((data: any) => {
        // console.log(data);
        // let nameData = data.cities.map(ele=>ele.cityName);
        let nameData = data.champions.map((ele) => ele.companyName);
        // let valueData = data.cities.map(ele=>ele.cityName);

        this.chartOption = {
          xAxis: {
            type: "category",
            data: nameData,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: [100, 200, 300, 600, 507],
              type: "line",
            },
          ],
        };
      });
  }

  
  

  updateCommissionRates(championRate: number, superChampionRate: number) {
    if (championRate < 0 || championRate > 100 || superChampionRate < 0 || superChampionRate > 100) {
      alert('Commission rates must be between 0 and 100');
      return;
    }

    // First call the API to update rates in the database
    this.dashboardService.updateCommissionRates(championRate, superChampionRate)
      .subscribe({
        next: (response) => {
          // On success, update the local analytics object
          this.analytics.championCommissionRate = championRate;
          this.analytics.superChampionCommissionRate = superChampionRate;
          
          // Update the displayed commission rate based on user group
          if (this.userGroupName === 'CHAMPION') {
            this.analytics.commissionPercentage = championRate;
          } else if (this.userGroupName === 'SUPER CHAMPION') {
            this.analytics.commissionPercentage = superChampionRate;
          }
          
          // Recalculate commission amount based on user type
          if (this.userGroupName === 'CHAMPION') {
            this.analytics.commissionAmount = (this.analytics.totalRevenue * this.analytics.championCommissionRate) / 100;
          } else if (this.userGroupName === 'SUPER CHAMPION') {
            this.analytics.commissionAmount = (this.analytics.totalRevenue * this.analytics.superChampionCommissionRate) / 100;
          } else {
            // For admin views, recalculate based on the current view
            this.analytics.commissionAmount = (this.analytics.totalRevenue * this.analytics.commissionPercentage) / 100;
          }

          // Recalculate admin analytics if needed
          if (this.userGroupName === 'DEVELOPER' || this.userGroupName === 'SUPER ADMIN' || this.userGroupName === 'ADMIN') {
            this.processAdminAnalytics(this.vendors);
          }

          // Show success message
          alert('Commission rates updated successfully');
        },
        error: (error) => {
          console.error('Error updating commission rates:', error);
          alert('Failed to update commission rates. Please try again.');
        }
      });
  }

  // New method for admin analytics
  private processAdminAnalytics(vendors: any[]) {
    // Reset admin analytics
    this.adminAnalytics.championsList = [];
    
    // Group vendors by champion
    const championGroups = new Map<string, any[]>();
    vendors.forEach(vendor => {
      if (vendor.referredBy) {
        if (!championGroups.has(vendor.referredBy)) {
          championGroups.set(vendor.referredBy, []);
        }
        championGroups.get(vendor.referredBy).push(vendor);
      }
    });

    // Process each champion's data
    championGroups.forEach((vendors, championId) => {
      const champion = this.champions.find(c => c.championId === championId);
      if (champion) {
        const totalRevenue = vendors.reduce((sum, vendor) => {
          const subscriptionCost = parseFloat(String(vendor.totalSubscriptionCost).replace(/[^\d.-]/g, '')) || 0;
          const offerPurchases = parseFloat(String(vendor.totalOfferPurchases).replace(/[^\d.-]/g, '')) || 0;
          return sum + subscriptionCost + offerPurchases;
        }, 0);

        const isSuper = champion.championTypeTitle === 'Super Champion';
        const commissionRate = isSuper ? 
          this.analytics.superChampionCommissionRate : 
          this.analytics.championCommissionRate;

        this.adminAnalytics.championsList.push({
          championId,
          championName: `${champion.firstName} ${champion.lastName}`,
          totalRevenue,
          commissionAmount: (totalRevenue * commissionRate) / 100,
          sellersCount: vendors.length,
          type: isSuper ? 'SUPER_CHAMPION' : 'CHAMPION'
        });
      }
    });

    // Calculate totals
    this.adminAnalytics.totalChampions = this.adminAnalytics.championsList
      .filter(c => c.type === 'CHAMPION').length;
    this.adminAnalytics.totalSuperChampions = this.adminAnalytics.championsList
      .filter(c => c.type === 'SUPER_CHAMPION').length;
    this.adminAnalytics.totalRevenue = this.adminAnalytics.championsList
      .reduce((sum, c) => sum + c.totalRevenue, 0);
    this.adminAnalytics.championCommissions = this.adminAnalytics.championsList
      .filter(c => c.type === 'CHAMPION')
      .reduce((sum, c) => sum + c.commissionAmount, 0);
    this.adminAnalytics.superChampionCommissions = this.adminAnalytics.championsList
      .filter(c => c.type === 'SUPER_CHAMPION')
      .reduce((sum, c) => sum + c.commissionAmount, 0);
  }

  calculateNetEarnings() {
    
    // Total Earnings (A) from earningsData
    this.netEarnings.totalEarnings = Number(this.earningsData.totalEarnings) || 0;
    
    // Commission Amount (B) from analytics
    this.netEarnings.commissionAmount = Number(this.analytics.commissionAmount) || 0;
    
    // Calculate total (A) + (B)
    this.netEarnings.total = this.netEarnings.totalEarnings + this.netEarnings.commissionAmount;

    // console.log('Net earnings calculated:', this.netEarnings);

    // Save the earnings data after calculation
    this.saveEarningsData();
  }

  // Add this method to your DashboardComponent class
  saveEarningsData() {
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    
    const earningsData = {
      userId: userData.user?.userId,
      staffId: userData.user?.staffId,
      username: userData.user?.userName,
      championEarnings: this.earningsData.championEarnings || 0,
      superChampionEarnings: this.earningsData.superChampionEarnings || 0,
      primeCustomerEarnings: this.earningsData.primeCustomerEarnings || 0,
      commissionAmount: this.analytics.commissionAmount || 0,
      totalEarnings: this.earningsData.totalEarnings || 0,
      netEarnings: this.netEarnings.total || 0
    };

    this.dashboardService.updateEarningsData(earningsData).subscribe({
      next: (response) => {
        // console.log('Earnings data saved successfully:', response);
      },
      error: (error) => {
        // console.error('Error saving earnings data:', error);
      }
    });
  }

  scrollToCommissions() {
    const element = document.getElementById('variable-commissions');
    if (element) {
        // First scroll the element into viewanalytics.primeCustomerEarnings
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        
        // Add a temporary highlight class
        element.classList.add('highlight-section');
        
        // Remove the highlight class after animation
        setTimeout(() => {
            element.classList.remove('highlight-section');
        }, 2000);

        // Update URL without affecting history
        window.location.hash = 'variable-commissions';
    }
  }

  loadCustomers() {
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const staffId = userData.user?.staffId;
    const userGroupName = userData.user?.groupName?.toUpperCase();
    const username = userData.user?.userName;

    this.dashboardService.getCustomers().subscribe({
      next: (response: any) => {
        if (response && Array.isArray(response.customers)) {
          // First filter for prime members with referral details
          const primeCustomers = response.customers.filter(customer => 
            customer.isUserPrimeMember === true && 
            customer.referralDetails && 
            customer.referralDetails.referredById
          );

          // Then apply user group specific filtering
          if (userGroupName === 'CHAMPION') {
            // For Champions, show only their directly referred prime customers
            this.customers = primeCustomers.filter(customer => 
              customer.referralDetails.referredById === staffId
            );
          } else if (userGroupName === 'SUPER CHAMPION') {
            // For Super Champions, show prime customers referred by their champions
            const championsUnderSuperChampion = this.champions.filter(
              champion => champion.referralId === username
            );
            const championIds = championsUnderSuperChampion.map(champion => champion.championId);
            
            this.customers = primeCustomers.filter(customer => 
              championIds.includes(customer.referralDetails.referredById)
            );
          } else if (['DEVELOPER', 'SUPER ADMIN', 'ADMIN'].includes(userGroupName)) {
            // For admin roles, show all prime customers with referral details
            this.customers = primeCustomers;
          } else {
            this.customers = [];
          }

          // Log the filtered results
          // console.log('Filtered prime customers:', this.customers.length);
        }
      },
      error: (error) => {
        console.error('Error loading customers:', error);
      }
    });
  }

  // Add this helper method to get champion names
  getChampionName(championId: string): string {
    if (!championId) return 'Not Found';
    
    const champion = this.champions.find(c => c.championId === championId);
    if (champion) {
      return `${champion.firstName || ''} ${champion.lastName || ''}`.trim() || champion.championName || 'Not Found';
    }
    return 'Not Found';
  }

  // Add this new method
  checkRewardTargetStatus() {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const username = userData.user?.userName;

    if (!username) {
      console.warn('No username found in localStorage');
      return;
    }

    this.dashboardService.checkRewardTarget(username).subscribe({
      next: (response: any) => {
        if (response.responseStatus === 'OK' && response.data) {
          const currentAchieved = response.data.currentAchieved || 0;
          const targetAmount = response.data.targetAmount || 0;
          const completedCycles = Math.floor(currentAchieved / targetAmount);
          const carryForward = currentAchieved % targetAmount;

          this.rewardTargetStatus = {
            hasMetTarget: currentAchieved >= targetAmount,
            isEligibleForPayment: response.data.isEligibleForPayment || false,
            currentAchieved,
            targetAmount,
            excessAchievement: carryForward,
            completedCycles,
            carryForward
          };
        } else {
          console.warn('Invalid response format:', response);
        }
      },
      error: (error) => {
        // Only log the error if it's not a "No user target record found" error
        if (error?.error?.message !== 'No user target record found') {
          console.error('Error checking reward target status:', error);
        }
        // Initialize with default values
        this.rewardTargetStatus = {
          hasMetTarget: false,
          isEligibleForPayment: false,
          currentAchieved: 0,
          targetAmount: 0,
          excessAchievement: 0,
          completedCycles: 0,
          carryForward: 0
        };
      }
    });
  }

  // Update method to update scheduler timing
  // async updateSchedulerTiming(schedulerName: string) {
  //   // Find the scheduler timing object
  //   const scheduler = this.schedulerTimings.find(s => s.name === schedulerName);
  //   if (!scheduler) return;

  //   // Set loading state
  //   scheduler.isLoading = true;

  //   try {
  //     const response = await this.dashboardService.updateSchedulerTiming(
  //       schedulerName,
  //       scheduler.timeValue,
  //       scheduler.timeUnit
  //     ).toPromise();

  //     // Update the current timing display
  //     scheduler.currentTiming = response.currentTiming;
  //     scheduler.lastUpdated = new Date().toLocaleString();

  //     // Show success message using CommonService
  //     this.commonService.showSuccessMsg('Scheduler timing updated successfully');
  //   } catch (error) {
  //     console.error('Error updating scheduler timing:', error);
  //     this.commonService.showErrorMsg('Failed to update scheduler timing');
  //   } finally {
  //     // Reset loading state
  //     scheduler.isLoading = false;
  //   }
  // }

  // // Update the resetSchedulerTiming method
  // resetSchedulerTiming(schedulerName: string) {
  //   const scheduler = this.schedulerTimings.find(s => s.name === schedulerName);
  //   if (scheduler) {
  //     scheduler.timeValue = null;
  //     scheduler.timeUnit = null;
  //   }
  // }

  // // Add this new method to load current scheduler timings
  // private async loadCurrentSchedulerTimings() {
  //   try {
  //     const response = await this.http.get<any>(
  //       `${environment.apiCredentials.apiDomain}/common/getCurrentSchedulerTimings`,
  //       {
  //         headers: new HttpHeaders({
  //           'Content-Type': 'application/json',
  //           'Authorization': 'Bearer ' + localStorage.getItem('userToken')
  //         })
  //       }
  //     ).toPromise();

  //     if (response && response.timings) {
  //       // Update the schedulerTimings array with current values from database
  //       this.schedulerTimings = this.schedulerTimings.map(scheduler => {
  //         const currentTiming = response.timings[scheduler.name];
  //         if (currentTiming) {
  //           // Parse the cron expression to get timeValue and timeUnit
  //           const { timeValue, timeUnit } = this.parseCronExpression(currentTiming);
  //           return {
  //             ...scheduler,
  //             timeValue,
  //             timeUnit,
  //             currentTiming: `Every ${timeValue} ${timeUnit}${timeValue > 1 ? 's' : ''}`
  //           };
  //         }
  //         return scheduler;
  //       });
  //     }
  //   } catch (error) {
  //     console.error('Error loading scheduler timings:', error);
  //   }
  // }

  // // Add helper method to parse cron expression
  // private parseCronExpression(cronExpression: string): { timeValue: number, timeUnit: 'minute' | 'hour' | 'day' } {
  //   const parts = cronExpression.split(' ');
    
  //   if (parts[0] !== '0' && parts[0].startsWith('*/')) {
  //     return {
  //       timeValue: parseInt(parts[0].substring(2)),
  //       timeUnit: 'minute'
  //     };
  //   } else if (parts[1].startsWith('*/')) {
  //     return {
  //       timeValue: parseInt(parts[1].substring(2)),
  //       timeUnit: 'hour'
  //     };
  //   } else if (parts[2].startsWith('*/')) {
  //     return {
  //       timeValue: parseInt(parts[2].substring(2)),
  //       timeUnit: 'day'
  //     };
  //   }
    
  //   return { timeValue: 1, timeUnit: 'hour' }; // default fallback
  // }
}


