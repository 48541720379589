import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'congratulation',
  templateUrl: './congratulation.component.html',
  styleUrls: ['./congratulation.component.css']
})
export class CongratulationComponent implements OnInit {
  pageTitle='Congratulation'

  @Input('allowedActions') allowedActions: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
