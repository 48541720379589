<Form [formGroup]="changePasswordForm" (ngSubmit)="changePasswordForm.valid" novalidate>
    <div class="companySelectionWrapper">
        <div class="cmpSelectionLeftBlock">
            <h5>Change Password</h5>
            <div class="cmpInnerBlock">
                <div class="cmpHeadingBlcok">
                    <div class="row">
                        <div class="col">
                            <div class="form-field">
                                <mat-form-field class="full-width">
                                    <mat-label>Old Password </mat-label>
                                    <input matInput type="password" placeholder="Old Password"
                                        formControlName="oldPassword" required>
                                    <mat-error *ngIf="changePasswordForm.get('oldPassword').hasError('required')">Old Password
                                        is <strong>required</strong></mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-field">
                                <mat-form-field class="full-width">
                                    <mat-label>New Password </mat-label>
                                    <input matInput type="password" placeholder="New Password"
                                        formControlName="password" required>
                                    <mat-error *ngIf="changePasswordForm.get('password').hasError('required')">New
                                        Password is <strong>required</strong></mat-error>
                                    <mat-error *ngIf="changePasswordForm.get('password').hasError('minlength')"> Password should be atleast 8 characters long</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-field">
                                <mat-form-field class="full-width">
                                    <mat-label>Confirm Password </mat-label>
                                    <input matInput type="password" placeholder="Confirm Password"
                                        formControlName="confirmPassword" required>
                                    <mat-error *ngIf="changePasswordForm.get('confirmPassword').hasError('required')">
                                        Confirm Password is <strong>required</strong></mat-error>
                                        <mat-error *ngIf="changePasswordForm.get('confirmPassword').hasError('minlength')"> Password should be atleast 8 characters long</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="cmpButtonSection">
                        <button class="btn btn-primary" (click)="changePassword()">Save</button>
                        <button class="btn btn-danger" (click)="closeDialog()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</Form>