import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-daily-needs',
  templateUrl: './daily-needs.component.html'

})
export class DailyNeedsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
