<header class="container-fluid">
      <div class="row">
            <div class="col-lg-2 col-xl-2 logopanel">
                  <img src="../../../assets/images/logo.png" class="img-fluid logoblock" />
            </div>
            <div class="col-sm-8 col-lg-7 col-xl-7 headerCompanyPanel">
                  <div class="row">
                        <div class="col col-sm-6 col-md-8 col-lg-9 header-module-column colblock">
                              <mat-form-field class="form-field-select">
                                    <mat-select [(value)]="currentModuleCode" placeholder="Modules"
                                          (selectionChange)="moduleChange($event)">
                                          <mat-option *ngFor="let module of allowedModules let i = index"
                                                [value]="module.moduleCode"> {{ module.moduleName }}</mat-option>
                                    </mat-select>
                              </mat-form-field>
                        </div>
                  </div>
            </div>
            <div class="col-sm-4 col-lg-3 col-xl-3 headerRightPanel">
                  <a href="javascript:void(0)" class="btn btn-icon" matTooltip="Help ?">
                        <span class="far fa-question-circle"></span>
                  </a>
                  <a class="btn btn-icon" matTooltip="Notifications" (click)="_toggleSidebar()">
                        <span class="far fa-bell"></span>
                  </a>
                  <a class="btn btn-icon" matTooltip="Settings" (click)="_settingSideBar()">
                        <span class="far fa-sun"></span>
                  </a>
                  <a href="javascript:void(0)" matMenu class="btn btn-icon" [matMenuTriggerFor]="usermenu">
                        <span class="user-block"></span>
                  </a>
                  <mat-menu #usermenu="matMenu" class="userMenuPosition">
                        <label class="usercation">{{ currentUserName }}</label>
                        <button mat-menu-item class="logoutbtn" (click)="userProfile()">
                              <span class="fas fa-user"></span>
                              Profile
                        </button>
                        <button mat-menu-item class="logoutbtn" (click)="changeUserPassword()">
                              <span class="fas fa-exchange-alt"></span>
                              Change Pasword
                        </button>
                        <button mat-menu-item class="logoutbtn" (click)="logoutUser()">
                              <span class="fas fa-power-off"></span>
                              Logout
                        </button>
                  </mat-menu>
            </div>
      </div>
</header>
<ng-sidebar-container class="mainNotSidebar">
      <ng-sidebar [(opened)]="_notificationSideBar" position="right" mode="over" [animate]="true"
            sidebarClass="notificationSideBar" [closeOnClickOutside]="true">
            <h4>Notification</h4>
            <div class="notecontainer">
                  <div class="card">
                        <a>
                              <h6>Notification</h6>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </a>
                  </div>
            </div>
      </ng-sidebar>
</ng-sidebar-container>
<ng-sidebar-container class="mainNotSidebar">
      <ng-sidebar [(opened)]="_settingSidebar" position="right" mode="over" [animate]="true"
            sidebarClass="notificationSideBar" [closeOnClickOutside]="true">
            <h4>Settings</h4>
            <div class="notecontainer">
                  <ul>
                        <li>Setting Menu Items</li>
                  </ul>
            </div>
      </ng-sidebar>
</ng-sidebar-container>