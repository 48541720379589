<div id="divPaymentForm">
  <h1>Candid Offer Payment</h1>
  <h3>Please do not press Back Button or Refresh</h3>
  <form
    #paymentForm
    action="{{ postUrl }}"
    method="POST"
    target="paymentProcessTab"
  >
    <input type="hidden" name="pgId" value="e63e330f5687d94206adbde6205ef994" />
    <input
      type="hidden"
      name="pgoId"
      value="e63e330f5687d94206adbde6205ef994"
    />
    <input type="hidden" name="type" value="1" />
    <input type="hidden" name="paymentId" value="{{ paymentData.id }}" />
    <input
      type="hidden"
      name="paymentAmount"
      value="{{ paymentData.amount }}"
    />
    <input type="hidden" name="refId" value="{{ refId }}" />
    <input
      type="hidden"
      name="transactionAmount"
      value="{{ paymentData.amount }}"
    />
    <button type="button" name="btnSubmit" (click)="procesPayment()">
      Payment Submit
    </button>
    <button type="button" name="btnClose" (click)="cancelPayment()">
      Cancel Payment
    </button>
  </form>
</div>
