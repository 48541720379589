import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apparel',
  templateUrl: './apparel.component.html',

})
export class ApparelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
