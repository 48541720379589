<!doctype html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <!-- Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">    
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.css">

    <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap" rel="stylesheet">
    <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous">

    <title>CANDID OFFERS</title>

<style>
  /* start comman style */
body{
    background: #fff;
}
.j-c-space-between{
    justify-content: space-between;
}
.sb-hd {
    color: #111;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
}
.link-txt{
    color: #243caa !important;
    font-weight: 600;
}
section {
    margin-bottom: 20px;
}
/* start header style */
#header-nav {
    background: #243caa;
    padding: 20px;
    border-bottom: 1px solid #eeeeee1a;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    box-shadow: inset 1px 1px 0.12px rgb(255 255 255 / 40%), 1px 1px 3px rgb(0 0 0 / 10%);
}
.logo-img {
    display: flex;
    align-items: center;
    justify-content: center;
}
.toggle-icon {
    display: inline-block;
    margin-left: 10px;
}
.logo-img span {
    color: #fff;
    font-weight: 900;
    margin-left: 10px;
}
.logo-img img {
    width: 30px;
}
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: #eee;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    box-shadow: 0 0 80px rgb(0 0 0 / 20%);
}
  
  .sidenav a {
    padding: 5px 8px 5px 32px;
    text-decoration: none;
    font-size: 18px;
    color: #111;
    display: block;
    transition: 0.3s;
}
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }

  /* start box banner */
  .box-part{
    padding-top: 70px;
    margin-top: 20px;
  }
  .banner-box {
    /* background-image: linear-gradient(45deg, #243caaba, #192454e6); */
    background: #D6DEFF;
    /* border: 1px solid rgba(255, 255, 255, 0.1); */
    /* box-shadow: 0 0 80px rgb(0 0 0 / 20%); */
    border-radius: 5px;
    backdrop-filter: blur(10px);
    padding: 20px 10px;
    display: flex;
    align-items: center;
}

.banner-box img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border-radius: 50%;
    border: 1px solid #eeeeee40;
}
.banner-box span {
    color: #243caa;
    font-size: 13px;
    font-weight: 500;
}
.banner-box h4 {
    font-size: 18px;
    margin-right: 20px;
    color: #243caa;
    margin-bottom: 0;
}
.banner-box p {
    font-size: 14px;
    margin: 0px;
    color: #243caa;
    font-weight: 400;
}

/* start offers */
.sel-offpage select {
    background: #eee;
    border: 0;
    border-radius: 5px;
    height: 42px;
    padding: 10px;
    font-size: 14px;
    width: 100%;
}
.act-box{
    display: block;
    background: #FFEEF2;
    /* background: rgba(255, 255, 255, 0.057); */
    border: 1px solid rgba(255, 255, 255, 0.1);
    /* box-shadow: 0 0 80px rgb(0 0 0 / 20%); */
    border-radius: 5px;
    backdrop-filter: blur(10px);
    padding: 20px ;
    width: 48%;
    text-align: center;
}
.act-box h3 {
    color: #111;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}
.icon-bg {
    color: #fff;
    background: #243caa;
    /* background-image: linear-gradient(45deg, #243caaba, #1a2c77e6); */
    border-radius: 5px;
    padding: 3px 7px;
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 600;
    display: inline-block;
}
.main-wrp {
    display: flex;
    flex-wrap: wrap;
    column-count: 2;
    column-gap: 10px;
    row-gap: 10px;
}
/* .bg-red{
    background: #f4ccd791;
}
.bg-blue{
    background: #eff0fc82;
}
.bg-green{
    background: #dbf7ed94;
}
.bg-yellow{
    background: #fef4dc99;
} */
/* end offers */

/* start scan or btn style */
.scan {
    text-align: center;
    padding: 20px 0 0;
}
.scan img {
    width: 100px;
    height: 100px;
    background: #eee;
    position: relative;
    padding: 10px;
    margin-bottom: 10px;
}
.scan p {
    text-align: center;
    color: #111;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}
.scan p span{
    display: block;
}
.create-btn {
    display: block;
    background: #fff;
    border: 2px solid #243caa;
    box-shadow: 0 0 80px rgb(0 0 0 / 20%);
    border-radius: 5px;
    backdrop-filter: blur(10px);
    padding: 10px 20px;
    color: #111 !important;
    font-weight: 600;
    font-size: 16px;
}
.btn-box{
    text-align: center;
}
.main-btn {
    display: block;
    text-align: center;
    background: #243caa;
    /* box-shadow: 0 0 80px rgb(0 0 0 / 20%); */
    border-radius: 5px;
    backdrop-filter: blur(10px);
    padding: 10px 20px;
    color: #fff !important;
    font-weight: 600;
    font-size: 16px;
    margin: 10px 0;
}
/* start register style */
.form-box {
    border-radius: 5px;
}
.vendor-register {
    padding: 20px 0;
}
.vendor-register p {
    font-size: 14px;
    color: #111;
}
.form-box .form-control {
    background: #eee;
    border: 0;
    border-radius: 5px;
    height: 42px;
    padding: 10px;
    font-size: 14px;
}
.form-box .form-control::placeholder{
    color: #111;
}
.mob-block {
    display: flex;
}
.mob-block select {
    width: 54px;
    margin-right: 5px;
    padding-left: 0 !important;
    border-radius: 0 5px 5px 0 !important;
}

.mob-block img {
    width: 54px;
    height: 42px;
    background: #eee;
    border-radius: 5px 0 0 5px;
}
/* start accept offer */
.offer-banner {
    padding: 10px;
    background: #e21e42;
    border-radius: 5px;
}
.offer-banner h2 {
    font-size: 26px;
    color: #fff;
    margin-bottom: 5px;
}
.offer-banner p {
    font-size: 14px;
    color: #fff;
    margin: 0;
}
.prime-btns{
    display: flex;
    align-items: center;
    justify-content: center;
}
.pr-bnt {
    border: 2px solid #ddd;
    border-radius: 5px;
    padding: 7px 15px;
    font-size: 14px;
    margin-right: 5px;
    font-weight: 700;
    color: #111 !important;
}
.ext-bnt {
    border: 2px solid #ddd;
    border-radius: 5px;
    padding: 7px 15px;
    font-size: 14px;
    font-weight: 700;
    color: #ddd !important;
}
.prim-img img{
    width: 60px;
}
.prim-img {
   text-align: center;
   margin: 10px 0;
}
.of-av-by {
    display: flex;
    border: 2px solid #eee;
    border-radius: 5px;
    padding: 10px;
    align-items: center;
    box-shadow: 0 0 80px rgb(0 0 0 / 20%);
}
.av-desc{
    margin-left: 20px;
}
.av-desc p {
    color: #111;
    font-weight: 500;
    margin: 0;
}
.of-av-by a {
    width: 10%;
}
.av-desc{
    width: 80%;
}
.cal-bg {
    background: #eee;
    border-radius: 5px;
    border: 1px solid #243caa;
}
/* strat manage offer options style */
.mng_live_off a {
    display: block;
    padding: 10px;
    border-radius: 5px;
    width: 30%;
    text-align: center;
    border: 1px solid #eee;
}
.mng_live_off a p {
    font-size: 13px;
    font-weight: 600;
    color: #111;
    margin: 0;
}
.mng_live_off {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}
.mng_live_off a.active {
    border: 1px solid #111;
}
.mng_live_off a:hover {
    border: 1px solid #111;
}
.app_link {
    color: #243caa !important;
    font-size: 12px;
    text-decoration: underline !important;
    font-weight: 500;
}
.mn_outstock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.mn_outstock h4 {
    font-size: 16px;
    margin: 0;
}
.m_sb_off {
    border: 2px solid #ddd;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}
.free_of {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFEEF2;
    padding: 8px;
    border-radius: 5px;
}
.free_of h3 {
    font-size: 14px;
    font-weight: 500;
    color: #243caa;
}
.prv_sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 7px;
}
.free_of h3 {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
}
.free_of p {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    color: #243caa;
}
.prv_sb h4 {
    font-size: 14px;
    font-weight: 700;
    color: #e21e42;
    margin: 0;
}
.prv_sb p {
    font-size: 12px;
    font-weight: 700;
    margin: 0;
}
.free_of .pr-bnt {
    font-size: 12px;
    background: #eee;
    padding: 5px 10px;
}
.free_of .ext-bnt {
    font-size: 12px;
    background:#8526C4;
    padding: 5px 10px;
}
.off_text {
    text-align: center;
    color: #243caa;
    font-weight: 500;
    font-size: 16px;
    background: #fff;
    padding: 5px 0;
    margin: 0 15px 20px;
    border-radius: 5px;
    border: 2px  dashed #243caa;
}
.upload_file_text input[type="file"] {
    color: transparent;
}
.upload_file_text{
    position: relative;
    margin: 10px 0 20px;
}
.hide_file {
    position: absolute;
    z-index: 1000;
    opacity: 0;
    cursor: pointer;
    right: 0;
    top: 0;
    height: 100%;
    font-size: 24px;
    width: 100%;
}
.upload_box {
    width: 50%;
    height: 90px;
    margin: auto;
    background: #ddd;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    border: 1px dashed #e21e42;
}
.upload_box:before {
    position: absolute;
    content: '+';
    left: 50%;
    right: 0;
    margin: auto;
    text-align: center;
    top: 41%;
    font-size: 34px;
    transform: translate(-50%, -50%);
}
.chk_box label {
    margin: 0;
    font-size: 14px;
    margin-left: 5px;
}
.chk_box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
/* start congratulation style */
.v_cong_page {
    padding-top: 80px;
    text-align: center;
}
.v_cong_page h2 {
    font-size: 24px;
    text-align: center;
    margin: 10px 0 20px;
    font-weight: 700;
    color: #111;
}
.v_cong_page h2 span {
    display: block;
}
.share_txt {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
}
.edit_icon {
    align-items: center;
    justify-content: end;
    width: 50%;
    margin: auto;
}

.edit_icon a span{
    font-size: 12px;
    background: #21a544;
    font-weight: 600;
    text-decoration: underline;
    border-radius: 50px;
    width: 10px;
    height: 10px;
    display: inline-block;
}
/* start upload history page style */
.his_detail {
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
    margin-top: 7px;
    justify-content: center;
}
.his_detail h4 {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
}
.his_detail a {
    display: block;
}
.his_detail a img {
    width: 40px;
    height: 40px;
}
.upload_his .m_sb_off {
    padding-bottom: 0;
    box-shadow: 0px 6px 20px 0px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    border: 0;
}
.upload_his .prv_sb h4 {
    color: #008000;
}
/* start profile style */
.pro-blue-bg {
    background: #243caa;
    padding: 20px 10px;
}
.top_pic_box a {
    display: block;
}
.top_pic_box a img {
    border-radius: 50%;
    width: 100px;
    border: 2px solid #fff;
    padding: 3px;
}
.top_pic_box {
    text-align: center;
    position: relative;
}
.p-edit-btn img {
    border-radius: 0 !important;
    width: 20px !important;
    border: 0 !important;
    padding: 0 !important;
    background: #111;
}
.p-edit-btn {
    position: absolute;
    right: 32%;
    top: 31%;
    border-radius: 50px;
    padding: 3px;
}
.top_pic_box h3 {
    font-size: 22px;
    color: #fff;
    margin: 10px 0;
}
.top_pic_box p {
    font-size: 16px;
    color: #fff;
    margin: 0;
}
.profile_opt_box img {
    width: 26px;
    margin-right: 10px;
}
.profile_opt_box h4 {
    font-size: 18px;
    margin: 0;
}
.profile_opt_box a {
    font-size: 13px;
    margin: 0;
    font-weight: 600;
    color: #243caa;
}
.profile_opt_box {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
</style>

  </head>
  <body>
    <!-- HEADER START HERE -->
    <header id="header-nav">
      <div class="container">
        <div class="row j-c-space-between">
            <div class="col-xs-4">
              <a class="logo-img"><img src="assets/images/vendor/logo.png" alt=""><span>CANDID OFFERS</span></a>
            </div>
            <div class="col-xs-4">
              <a><img src="assets/images/vendor/notification.svg" alt=""></a>
              <a class="toggle-icon" onclick="openNav()"><img src="assets/images/vendor/toggle.svg" alt=""></a>
            </div>
        </div>
      </div>
    </header>
    <!-- start menu -->
    <div id="mySidenav" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
      <a href="#">Home</a>
      <a href="#">Home</a>
      <a href="#">Home</a>
      <a href="#">Home</a>
    </div>
    <!-- HEADER END HERE -->

    <!-- START TOP OFFER -->
    <section class="box-part">
      <div class="container">
            <div class="banner-box">
              <img src="assets/images/vendor/off.png" alt="">
              <div style="width: calc(100% - 100px);">
                <span>Fashion</span>
                <h4>{{data.vendorDetails.vendorName}}</h4>
                <p>{{data.vendorDetails.location}}</p>
              </div>
            </div>
      </div>
    </section>
    <!-- END OFFER -->

    <!-- START OFFERS -->
    <section>
      <div class="container">
        <h2 class="sb-hd">Offers</h2>
          <div class="main-wrp">
            <a class="act-box">
              <div class="icon-bg">{{data.offerDetails.offerLive}}</div>
              <h3>Live</h3>
            </a>
            <a class="act-box">
              <div class="icon-bg">{{data.offerDetails.offerAvailable}}</div>
              <h3>Available</h3>
            </a>
            <a class="act-box">
              <div class="icon-bg">{{data.offerDetails.offerExpired}}</div>
              <h3>Expired</h3>
            </a>
            <a class="act-box">
              <div class="icon-bg">{{data.offerDetails.totalEncash}}</div>
              <h3>Total Encash</h3>
            </a>
          </div>
          <!-- strat manage offer options -->
          <div class="mng_live_off">
            <a class="active" style="background: #ffe0cb;">
              <img src="assets/images/vendor/live.svg">
              <p>Live Now</p>
            </a>
            <a style="background: #d0fedf;">
              <img src="assets/images/vendor/out-of-stock.svg">
              <p>Out of stock</p>
            </a>
            <a style="background: #f8ffc3;">
              <img src="assets/images/vendor/exprd.svg">
              <p>Expired</p>
            </a>
          </div>
          <div class="mn_outstock">
            <h4>Live Now</h4>
            <a class="app_link">Click offers to Edit</a>
          </div>
          <!-- start get off -->
          <div class="prev_mng">
            <!-- 1 -->
            <div class="m_sb_off" >
              <div class="prv_sb">
                <h4>Preview</h4>
                <p *ngFor="let offer of data">End Date: {{offer.liveOffers.endDate}}</p>
              </div>
              <div class="free_of">
                <div>
                  <h3>Buy 1 Get 1 Free</h3>
                  <p>For clubs shirts</p>
                </div>
                <div>
                  <a class="pr-bnt">Prime -2%</a>
                  <a class="ext-bnt">Extra -5%</a>
                </div>
              </div>
             
            </div>
            <!-- 2 -->
            <div class="m_sb_off">
              <div class="prv_sb">
                <h4>Preview</h4>
                <p>End Date: 20th, Desc 20232</p>
              </div>
              <div class="free_of">
                <div>
                  <h3>Buy 1 Get 1 Free</h3>
                  <p>For clubs shirts</p>
                </div>
                <div>
                  <a class="pr-bnt">Prime -2%</a>
                  <a class="ext-bnt">Extra -5%</a>
                </div>
              </div>
             
            </div>
          </div>
      </div>
    </section>
    <!-- END OFFERS -->

    <!-- START BUTTONS -->
    <section>
      <div class="container">
        <div class="btn-box">
          <a class="main-btn">Create new offer</a>
        </div>
      </div>
    </section>
    <!-- END BUTTON -->


    <!-- Optional JavaScript -->
    <!-- jQuery first, then Popper.js, then Bootstrap JS -->
    <script src="https://code.jquery.com/jquery-3.4.1.slim.min.js" integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script>

    <script src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"></script>
    <script src="js/custom.js"></script>
    
  </body>
</html>