<div class="displayTable">
    <div class="displayTableCell">
      <div class="authBlock">
        <h3>Sign In</h3>
        <div class="formGroup">
          <input type="text" class="formControl" placeholder="Username" #userName required>
        </div>
        <div class="formGroup">
          <input type="password" class="formControl" placeholder="Password" #userPassword required>
        </div>
        <!-- Calling SignIn Api from AuthService -->
        <div class="formGroup">
          <input type="button" class="btn btnPrimary" value="Log in" (click)="authService.SignIn(userName.value, userPassword.value)">
          <!-- <input type="button" class="btn btnPrimary" value="Log in" (click)="login()"> -->
        </div>
        <div class="formGroup">
          <span class="or"><span class="orInner">Or</span></span>
        </div>
        <!-- Calling GoogleAuth Api from AuthService -->
        <div class="formGroup">
          <button type="button" class="btn googleBtn" (click)="authService.GoogleAuth()">
            <i class="fab fa-google-plus-g"></i>
            Log in with Google
          </button>
        </div>
        <div class="forgotPassword">
          <span routerLink="/forgot-password">Forgot Password?</span>
        </div>
      </div>
      <div class="redirectToLogin">
        <span>Don't have an account?<span class="redirect" routerLink="/register-user"> Sign Up</span></span>
      </div>
      <!-- PhoneNumber -->

       <div class="container-fluid">
        <a class="navbar-brand" routerLink="/phone-number">Mobile Verification</a>
        <ul class="navbar-nav ms-auto flex-nowrap">
          <li class="nav-item">
            <div style="cursor: pointer;" (click)="login()" class="nav-link m-2 menu-item nav-active">Logout</div>
          </li>
        </ul>
      </div>  
      <!-- <nav class="navbar navbar-expand-lg navbar-light bg-light">
        
       </nav> -->
    </div>
    
  </div>
  