<html>
  <style>
    .content b {
      font-size: large;
      color: white;
      font-weight: 300;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    }
  </style>
  <Body>
    <section class="home-footer-about-candid">
      <div class="container">
        <div class="d-flex">
          <div class="align-self-center">
            <div class="logo">
              <img
                src="\assets\images\candid-offer-footer-logo.png"
                width="147"
                alt="candid-offer-footer-logo"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="content">
            <p><b>Last Updated as on Sep, 2023</b></p>
            <br />
            <p>
              <b
                >To avail the services offered at www.candidoffers.com , you
                must agree to the following terms and conditions: Please read
                the term and conditions carefully. While using any current or
                future services offered by www.candidoffers.com, whether or not
                included in the Candidoffers Pvt. Ltd. website, you will abide
                by these Terms & conditions the guidelines and conditions
                applicable to such service or business.
              </b>
            </p>
            <br />
            <p>
              <b>
                The CandidOffers application and website (“Platform”) are made
                available to you by CandidOffers Private Limited and/or its
                affiliates (hereinafter may be referred to as the ‘Company’,
                ‘we’, ‘us’, and ‘our’) respect your privacy and is committed to
                protecting it through its compliance with its privacy policy.
                This policy amongst other things describes: (i) the type of
                information that the Company may collect from you when you
                access or use its websites, applications and other online
                services (hereinafter collectively referred to as the
                ‘Services’); and, (ii) the Company’s practices for collecting,
                using, maintaining, protecting and disclosing that information.
                We encourage you to read this policy carefully to understand the
                Company's policies and practices regarding your information. By
                accessing or using its Services or its Platform, registering an
                account with the Company, becoming a supplier, reseller or
                customer on the Platform, or by attempting to become a supplier,
                reseller or customer, you expressly agree to be bound by the
                terms and conditions of this privacy policy and you are
                consenting to the Company's collection, use, disclosure and
                retention of your personal information as described here. This
                policy may change from time to time, your continued use of the
                Company's Services after it makes any change is deemed to be
                acceptance of those changes, so please check the policy
                periodically for updates.
              </b>
            </p>
            <br />

            <b>1.Privacy Policy</b>
            <p>
              Please review our Privacy Policy, which also governs your visit to
              www.candidoffers.com, to fully understand our practices.
            </p>
            <br />

            <b>2.Electronic Communication</b>

            <p>
              When you visit www.candidoffers.com or send e-mails to us, you are
              communicating with us electronically. By communicating with us,
              you consent to receive communication from us electronically. We
              will communicate with you by e-mail or by posting notices on our
              website. You agree that all agreements, notices, disclosures, and
              other communications that we provide to you electronically satisfy
              any legal requirement that such communication be in writing.
            </p>
            <br />

            <b>3.Prices</b>
            <p>
              All prices posted on this website are subject to change without
              notice. Prices prevailing at commencement of placing the order
              will apply. Posted prices do not include convenience fee. In case
              there are any additional charges or taxes the same will be
              mentioned on the website.
            </p>
            <br />

            <b>4.Payment</b><br />
            <p>
              Online Payments can be done by: Online Payment: Card payment and
              Net Banking Payment via BillDesk Payment Gateway can be done at
              Online Payment link on Make Payment page.
              <br />

              <br />

              <b>5.Changes and Cancellation</b>
            </p>

            <p>Changes and Cancellation not allowed.</p>
            <br />

            <b>6.Refund</b><br />
            <p>
              Refunds initiated will be credited to the account/card from where
              the transaction had initiated.
            </p>
            <br />

            <b>7.License and Website Access</b><br />
            <p>
              7.1.General: www.candidoffers.com grants you a limited license to
              access and make personal use of this website and not to download
              (other than page caching) or modify it, or any portion of it,
              except with express written consent of www.candidoffers.com
              <br />

              <b>No license for commercial sale:</b>
              This license does not include any resale or commercial use of this
              website or its content; any collection and use of any product
              listing, description, or pricing; copying of account information
              for the benefit of another merchant; or any use of data mining, or
              similar data gathering and extraction tools.
              <br />
              <b>No reproduction:</b>
              This website or any portion of this website may not be reproduced,
              duplicated, copies, sold, visited, or otherwise exploited for any
              commercial purpose without express written consent of
              www.candidoffers.com
              <br />
              <b>No framing:</b>
              You may not frame or utilize framing technologies to enclose any
              trademark, logo, or other proprietary information (including
              images, text, page layout, or form) of www.candidoffers.com
              without the express written consent.
              <br />
              <b>Metatags:</b>
              You may not use any metatags or any other 'hidden text' utilizing
              Supreme Enterprises name or trademarks without the express written
              consent of www.candidoffers.com. Any unauthorized use terminates
              the permission or license granted by www.candidoffers.com.
              <br />

              <b> Reviews, Comments, Communications, and other content </b>

              <b> Nature of content: </b>
              Visitors to www.candidoffers.com may post content and other
              communications; and submit suggestions, ideas, comments, questions
              or other information, as long as the content is not illegal,
              obscene, threatening, defamatory, invasive of privacy, infringing
              of intellectual property rights to otherwise injuries to third
              party or objectionable and does not consist of or contains
              software virus, political campaigning, commercial solicitation,
              mass mailing or any form of spam.

              <br />
            </p>

            <p>
              False information: You may not use false email address,
              impersonate any person or entity, or otherwise mislead as to the
              origin of a card or other content. www.candidoffers.com reserves
              the right (but not the obligation) to remove or edit such content
              but does not regularly review posted contents.

              <br />
              Rights Granted: If you do post content or submit material and
              unless we indicate otherwise, you grant www.candidoffers.com a
              non-exclusive, royalty free, perpetual, irrevocable, and fully
              sub-licensed right to use, reproduce, modify, adapt, publish,
              translate, create derivative work from, distribute, and display
              such content throughout the world in any media. You grant
              www.candidoffers.com the right to use the name that you submit in
              connection with such content if www.candidoffers.com chooses.
              <br />
              Right Owned: You represent and warrant that you own all the rights
              or otherwise or control all of the rights to the content that you
              post; that the content is accurate; that the use of the content to
              supply does not violate this policy and will not cause injury to
              any person or entity and that you will indemnify
              www.candidoffers.com for all claims resulting from the content you
              supply. www.candidoffers.com has the right but not the obligation
              to monitor and edit or remove any activity or content.
              www.candidoffers.com takes no responsibility and assumes no
              liability for any content posted by you or any third party.
              <br />
              Site Policies, Modification, and Severability Please review our
              other policies. We reserve the right to make changes to our
              website, policies, and these Terms and Conditions at any time. If
              any of these conditions shall be deemed invalid, void, or for any
              reason unenforceable, that condition shall be deemed severable and
              will not affect the validity and enforceability of any remaining
              conditions.
              <br />
            </p>

            <p>
              <br />
              Intellectual Property Rights Copyright Protection: All content
              included on this site, such as text, graphics, logos, button
              icons, audio clips, digital downloads, data compilations and
              software, is the property of www.candidoffers.com and protected by
              the Indian Copyright law. The compilation of all the content on
              this site is the exclusive property if www.candidoffers.com and
              protected by Indian Copyright law. All software used in this site
              is the property of www.candidoffers.com and is protected under the
              Indian Copyright law. Trademarks: i. Protected Marks:
              www.candidoffers.com, and other marks indicated on
              www.candidoffers.com website are registered trademarks of
              www.candidoffers.com ii. Protected Graphics: All
              www.candidoffers.com graphics, logos, page headers, button icons,
              scripts and service names are trademarks or trade dress of
              www.candidoffers.com. www.candidoffers.com trademarks and trade
              dress may not be used in connections with any product or service
              that is not of www.candidoffers.com. Governing Law and
              Jurisdiction These terms and conditions will be construed only in
              accordance with the laws of India. In respect of all
              matters/disputes arising out of, in connection with or in relation
              to these terms and conditions or any other conditions on this
              website, only the competent Courts at Nagpur, Nagpur shall have
              jurisdiction, to the exclusion of all other courts.
              <br />
            </p>
            <br />

            <b>7.Disclaimer of warranties and Limitation of Liability</b><br />

            <p class="text-capitalize">
              This site is provided by CandidOffers Pvt. Ltd. on an "as is" and
              "as available" basis. CandidOffers Pvt. Ltd. makes no
              representations or warranties of any kind, express or implied, as
              to the operation of this site or the information, content,
              materials, or products included on this site. You expressly agree
              that your use of this site is at your sole risk. To the full
              extent permissible by applicable law, www.candidoffers.com
              disclaims all warranties, express or implied, including, but not
              limited to, implied warranties of merchantability and fitness for
              a particular purpose. www.candidoffers.com does not warrant that
              the site, its servers, or email sent from www.candidoffers.com are
              free of virus or other harmful components. www.candidoffers.com
              will not be liable for any damages of any kind arising from the
              use of this site, including, but not limited to, direct, indirect,
              incidental, punitive, and consequential damages.
            </p>
            <br />
          </div>
        </div>
      </div>
    </section>
  </Body>

  <footer>
    <!DOCTYPE html>

    <!-- Footer start here -->
    <footer></footer>
    <!-- Footer end here -->
  </footer>
</html>
