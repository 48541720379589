import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'acceptOffer',
  templateUrl: './accept-offer.component.html',
  styleUrls: ['./accept-offer.component.css']
})
export class AcceptOfferComponent implements OnInit {
  pageTitle='AcceptOffer'

  @Input('allowedActions') allowedActions: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
