<!-- Top navigation -->
<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
    <a class="navbar-brand col-sm-3 col-md-2 mr-0">
     
    </a>
  </nav>
  <!-- Sidebar navigation -->
  <div class="container-fluid">
    <div class="row">
      <nav class="col-md-2 d-md-block bg-light sidebar">
        <div class="sidebar-sticky">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link active">
                <i class="fas fa-user"></i>User Profile
              </a>
            </li>
            <!-- Calling SignOut() Api from AuthService -->
            <li class="nav-item">
              <a class="nav-link" (click)="authService.SignOut()">
                <i class="fas fa-sign-out-alt"></i>Log out
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <!-- Main content -->
      <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
        <div class="inner-adjust">
          <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">User Profile</h1>
          </div>
          <!-- Show user data when logged in -->
          <div class="row" *ngIf="authService.userData as user">
            <div class="col-md-12">
              <div class="media">
                <img class="align-self-start mr-5 img-thumbnail rounded-circle" src="{{(user.photoURL) ? user.photoURL : '/assets/dummy-user.png'}}"
                  alt="{{user.displayName}}">
                <div class="media-body">
                  <h1>Hello, <strong>{{(user.displayName) ? user.displayName : 'User'}}</strong></h1>
                  <!-- <p>User ID: <strong>{{user.uid}}</strong></p> -->
                  <p>Email: <strong>{{user.email}}</strong></p>
                  <p>Email Verified: <strong>{{user.emailVerified}}</strong></p>
                </div>
              </div>
            </div>
          </div>
        
          
          <h2 class="mt-5">Welcome to the Dashboard user {{userData}} </h2>
        </div>
      </main>
    </div>
  </div>