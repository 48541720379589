import { HttpClient } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component } from "@angular/core";
import { AlertService } from "../shared/services/alert.service";
import { CommonService } from "../shared/services/common.service";
import { Router } from "@angular/router";
import { City } from "../modules/support/interfaces/city-autocomplete.interface";
import { ChampionPackageType } from "../modules/support/interfaces/champion-subscription-package.interface";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "seller-reg",
  templateUrl: "./seller-reg.component.html",
  styleUrls: ["./seller-reg.component.css"],
})
export class sellerRegComponent {
  championForm: FormGroup;
  passHide = true;
  loading = false;
  panPhoto: any;
  aadharPhoto: any;
  bankChequePhoto: any;
  profilePhoto: any;
  //pan verify
  isPanVerified = false;
  panVerMsg: string;
  //BANK verify
  isBankVerified = false;
  BankVerMsg: string;
  citySettings: any = {};
  cities: City[] = [];
  championSubscriptionPackages: ChampionPackageType[] = [];
  championPackageTypeSettings: any = {};
  states: any;
  champions: any;
  tempUserToken: any;
  //adhar verify
  aadharOtpSent = false;
  isAadharVerified = false;
  aadharVerMsg: string;
  aadharRequestid: string;
  aadharTransId: string;
  //for mobile otp
  mobileOtpSent = false;
  isMobileVerified = false;
  mobileVerMsg: string;
  generatedOTP: string;

  //for mail otp
  mailOtpSent = false;
  isMailVerified = false;
  mailVerMsg: string;
  generatedMailOTP: string;

  //payment
  showForm: boolean = false;
  showPayment: boolean = false;
  paymentData: any;

  constructor(
    private commonService: CommonService,
    private alertService: AlertService,
    private http: HttpClient,
    public fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.commonService.getCities().subscribe((data: any) => {
      this.cities = data.cities;
    });
    this.commonService.getStates().subscribe((data: any) => {
      this.states = data.states;
    });
    this.commonService
      .tempAuth()
      .pipe(
        switchMap((data: any) => {
          this.tempUserToken = data.userToken;
          return this.commonService.championSubscriptionPackageRequest(
            this.tempUserToken,
            "/co-trans/getChampionSubscriptionPackages"
          );
        })
      )
      .subscribe((data: any) => {
        this.championSubscriptionPackages =
          data["championSubscriptionPackages"];

        this.paymentData = {
          id: 1,
          amount: this.championSubscriptionPackages[0]['packageCharges'] ,
          token: this.tempUserToken
        };
        
      });

    this.championForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      fatherHusbandName: ["", Validators.required],
      fatherHusbandRelation: ["F", Validators.required],
      gender: ["", Validators.required],
      mobile: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9-]*$"),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      otp: [""],
      mailOtp: [""],
      email: ["", [Validators.required, Validators.email]],
      panNo: [
        { value: "", disabled: this.isPanVerified },
        [Validators.required, Validators.pattern("^[A-Z]{5}[0-9]{4}[A-Z]{1}")],
      ],
      aadharNo: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$"),
        ],
      ],
      aadharOtp: [""],
      bankAccountNo: [
        { value: "", disabled: this.isBankVerified },
        Validators.required,
      ],
      bankName: [{ value: "", disabled: this.isBankVerified }],
      bankBranch: [""],
      bankIFSCCode: [
        { value: "", disabled: this.isBankVerified },
        Validators.required,
        Validators.pattern("/^[A-Z]{4}[0-9]{7}(?:[A-Z]{1})?$/"),
      ],
      state: [""],
      pincode: [""],
      addressLine1: ["", Validators.required],
      addressLine2: [""],
      countryId: [null],
      countryName: [null],
      stateId: [null],
      stateName: [null],
      cityId: [null, [Validators.required]],
      cityName: [null],
      gstType: [""],
      gstNo: [""],
      selectedGSTType: [null],
      profilePhotoData: ["", [Validators.required]],
      panPhotoData: ["", [Validators.required]],
      aadharPhotoData: ["", [Validators.required]],
      bankCancelChqPhotoData: ["", [Validators.required]],
      referralId: [""],
      bankAccountName: [""],
      selectedCity: [""],
      selectedChampionPackageType: [""],
    });

    this.citySettings = {
      singleSelection: true,
      lazyLoading: true,
      text: "City*",
      primaryKey: "cityId",
      labelKey: "cityName",
      enableSearchFilter: true,
      showCheckbox: true,
      enableFilterSelectAll: false,
      searchBy: ["cityName", "cityCode"],
      maxHeight: 200,
      classes: "custome-multi-select",
    };

    // Following setting for the Champion Subscription Package Type Autocomplete select
    this.championPackageTypeSettings = {
      singleSelection: true,
      lazyLoading: true,
      text: "Select Champion Subscription Package Type",
      primaryKey: "championSubscriptionPackageId",
      labelKey: "packageTitle",
      enableSearchFilter: true,
      showCheckbox: true,
      enableFilterSelectAll: false,
      searchBy: ["packageTitle"],
      maxHeight: 200,
      classes: "custome-multi-select",
      selectedItems: [this.championSubscriptionPackages[0]],
    };
  }

  // convenience getter for easy access to form fields
  get cf() {
    return this.championForm.controls;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  // mobile verification
  sendOTP() {
    const mobileNumber = this.championForm.get("mobile").value;
    const UserName = this.championForm.get("firstName").value || "User";
    if (mobileNumber === null || mobileNumber === "") {
      window.alert("Enter Mobile Number!");
      return;
    }
    // Show loading
    this.showLoading();
    // API to send OTP
    this.commonService
      .backendAPICall("/common/sendOtp", {
        name: UserName,
        mobile: mobileNumber,
      })
      .subscribe(
        (response) => {
          this.generatedOTP = response["otp"];
          this.mobileOtpSent = true;
          this.mobileVerMsg = "Mobile Otp sent Successfully";
          // Hide loading
          this.hideLoading();
        },
        (error) => {
          console.error("Error sending OTP", error);
          // Hide loading
          this.hideLoading();
          window.alert("Error sending OTP");
        }
      );
  }

  verifyOTP() {
    const enteredOTP = this.championForm.get("otp").value;
    // Show loading
    if (enteredOTP === null && enteredOTP === "") {
      window.alert("Enter OTP!");
      return;
    }
    this.showLoading();
    if (enteredOTP == this.generatedOTP) {
      this.mobileOtpSent = false;
      this.isMobileVerified = true;
      this.mobileVerMsg = "Mobile number is verified.";
      // Hide loading
      this.hideLoading();
    } else {
      // Hide loading
      this.hideLoading();
      window.alert("OTP is not correct!");
    }
  }

  sendMailOTP() {
    const email = this.championForm.get("email").value;
    if (email === null || email === "") {
      window.alert("Email is not filled");
      return;
    }
    this.generatedMailOTP =
      Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000 + "";
    // Show loading
    this.showLoading();
    // API to send OTP
    const emailSubject = "candid verification.";
    const template = `<!DOCTYPE html>\n<html lang=\"en\">\n<head>\n  <meta charset=\"UTF-8\">\n  <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n  <title>OTP Verification</title>\n  <style>\n    body {\n      font-family: Arial, sans-serif;\n      background-color: #f4f4f4;\n      color: #333;\n    }\n    .container {\n      max-width: 600px;\n      margin: 0 auto;\n      padding: 20px;\n      background-color: #fff;\n      border-radius: 5px;\n      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    }\n    h1 {\n      color: #4285f4;\n    }\n  </style>\n</head>\n<body>\n  <div class=\"container\">\n    <h1>OTP Verification</h1>\n    <p>Hello Candid User,</p>\n    <p>Your OTP (One-Time Password) for verification is: <strong>${this.generatedMailOTP}</strong></p>\n    <p>Please use this OTP to complete the verification process.</p>\n\n    <p>If you didn't request this OTP, please ignore this email.</p>\n\n    <footer>\n      <p>Best regards,<br> Candid Verification Team</p>\n    </footer>\n  </div>\n</body>\n</html>`;

    this.commonService
      .backendAPICall("/common/send-mail", {
        sendTo: email,
        emailSubject: emailSubject,
        template: template,
      })
      .subscribe(
        (response) => {
          this.mailOtpSent = true;
          this.mailVerMsg = "Mail Otp sent Successfully";
          // Hide loading
          this.hideLoading();
        },
        (error) => {
          console.error("Error sending OTP", error);
          // Hide loading
          this.hideLoading();
        }
      );
  }

  verifyMailOTP() {
    const enteredOTP = this.championForm.get("mailOtp").value;

    if (enteredOTP == null) {
      window.alert("Enter OTP!");
      return;
    }
    // Show loading
    this.showLoading();

    if (enteredOTP == this.generatedMailOTP) {
      this.mailOtpSent = false;
      this.isMailVerified = true;
      this.mailVerMsg = "Email is verified.";
      // Hide loading
      this.hideLoading();
    } else {
      // Hide loading
      this.hideLoading();
      window.alert("OTP is not correct!");
    }
  }
  updateChampionsList(cityID: string) {
    // Simulate fetching users based on userType
    if (cityID) {
      let filter = { cityId: cityID };
      this.commonService.getChampions().subscribe((data: any) => {
        this.champions = data.champions.map((champ) => ({
          championId: champ.championId,
          fullName: `${champ.firstName} ${champ.lastName}`,
        }));
      });
    } else {
      this.champions = [];
    }
  }

  verifyPan() {
    const panNumber = this.championForm.get("panNo").value;
    if (panNumber == null || panNumber.trim() === "") {
      window.alert("Enter PAN Number!");
      return;
    }
    // Show loading
    this.showLoading();
    // API to verify PAN
    this.commonService
      .backendAPICall("/common/documentsVerification", {
        verFor: "pan-verification",
        bodyData: {
          requestid: "123456",
          pancard: panNumber,
        },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "SUCCESS") {
            if (response.data.clientStatus == "Valid and Operative") {
              this.isPanVerified = true;
              this.panVerMsg = "Pan Card is Verified";
            } else {
              window.alert("Please,Enter Valid PAN Number!");
            }
          }
          // Hide loading
          this.hideLoading();
        },
        (error) => {
          // Hide loading
          this.hideLoading();
          window.alert("Error verifying PAN card");
        }
      );
  }
  sendAadharOtp() {
    const aadharNumber = this.championForm.get("aadharNo").value.toString();
    if (aadharNumber == null || aadharNumber.trim() === "") {
      window.alert("Enter Aadhar Number!");
      return;
    }
    // Show loading
    this.showLoading();
    // API to verify PAN
    this.commonService
      .backendAPICall("/common/documentsVerification", {
        verFor: "aadhaar-otp",
        bodyData: {
          aadhaar: aadharNumber,
        },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "SUCCESS" || response.status == 200) {
            if (response.data) {
              this.aadharOtpSent = true;
              this.aadharVerMsg = "Aadhar Otp sent Successfully";
              this.aadharTransId = response.data.tran_id;
              this.aadharRequestid = response.requestid;
            } else {
              window.alert("Please,Enter Valid PAN Number!");
            }
          }
          // Hide loading
          this.hideLoading();
        },
        (error) => {
          // Hide loading
          this.hideLoading();
          window.alert("Error verifying PAN card");
        }
      );
  }
  aadharVerify() {
    const aadharOtp = this.championForm.get("aadharOtp").value.toString();
    if (aadharOtp == null || aadharOtp.trim() === "") {
      window.alert("Enter Aadhar Otp!");
      return;
    }
    // Show loading
    this.showLoading();
    // API to verify PAN
    this.commonService
      .backendAPICall("/common/documentsVerification", {
        verFor: "aadhaar-verification",
        bodyData: {
          otp: aadharOtp,
          tran_id: this.aadharTransId,
          request_id: this.aadharRequestid,
        },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "SUCCESS" || response.status == 200) {
            if (response.data) {
              this.aadharOtpSent = false;
              this.isAadharVerified = true;
              this.aadharRequestid = null;
              this.aadharTransId = null;
              this.aadharVerMsg = "Aadhar number is verified.";
              this.championForm.get("firstName").setValue(response.data.name);
              this.championForm.get("gender").setValue(response.data.gender);
              if (response.data.mobile && response.data.email) {
                this.championForm.get("mobile").setValue(response.data.mobile);
                this.championForm.get("email").setValue(response.data.email);
              }

              let addressComponents = response.data.address
                .split(",")
                .map((component) => component.trim())
                .reverse();

              let address = response.data.address
                .split(",")
                .slice(0, -3)
                .join(", ")
                .toString();

              if (addressComponents.length > 0) {
                this.championForm.get("addressLine1").setValue(address);
                // this.championForm
                //   .get("state")
                //   .setValue(addressComponents[1].toString());
                // this.championForm
                //   .get("city")
                //   .setValue(addressComponents[2].toString());
                this.championForm.get("pincode").setValue(addressComponents[0]);
              }
            } else {
              window.alert("Please,Enter Valid PAN Number!");
            }
          }
          // Hide loading
          this.hideLoading();
        },
        (error) => {
          // Hide loading
          this.hideLoading();
          window.alert("Error verifying PAN card");
        }
      );
  }
  verifyBankAccount() {
    const bankIFSCCode = this.championForm.get("bankIFSCCode").value.toString();
    const bankAccountNo = this.championForm
      .get("bankAccountNo")
      .value.toString();
    if (
      bankIFSCCode == null ||
      (bankIFSCCode.trim() === "" && bankIFSCCode == null) ||
      bankAccountNo.trim() === ""
    ) {
      window.alert("Enter Account Number and IFSC!");
      return;
    }
    // Show loading
    this.showLoading();
    // API to verify PAN
    this.commonService
      .backendAPICall("/common/documentsVerification", {
        verFor: "bank-verification",
        bodyData: {
          acc_nummber: bankAccountNo,
          ifsc_number: bankIFSCCode,
        },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "SUCCESS") {
            if (response.data.verification_status == "VERIFIED") {
              this.isBankVerified = true;
              this.BankVerMsg = "Bank Account is Verified";
            } else {
              window.alert("Please,Enter Valid bank details!");
            }
          } 
          // Hide loading
          this.hideLoading();
          this.isBankVerified = true;
          this.BankVerMsg = "Bank Account is Verified";
        },
        (error) => {
          console.log("error");
          console.log(error);

          // Hide loading
          this.hideLoading();
          window.alert("Error verifying bank details");
        }
      );
    // Hide loading
    this.hideLoading();
    this.isBankVerified = true;
    this.BankVerMsg = "Bank Account is Verified";
  }
  selectPanPhoto(event: any) {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileType === "application/pdf"
      ) {
        this.readFile(file, "pan");
      } else {
        // Handle invalid file type error
        alert("Please select a JPG/PNG/PDF file for PAN photo.");
        event.target.value = ""; // Clear the file input
      }
    }
  }

  // Method to handle selection of Aadhar photo
  selectAadharPhoto(event: any) {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileType === "application/pdf"
      ) {
        this.readFile(file, "aadhar");
      } else {
        // Handle invalid file type error
        alert("Please select a JPG/PNG/PDF file for Aadhar photo.");
        event.target.value = ""; // Clear the file input
      }
    }
  }
  selectBankChequePhoto(event: any) {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileType === "application/pdf"
      ) {
        this.readFile(file, "bankcheque");
      } else {
        alert("Please select a JPG/PNG/PDF file for Bank Cheque photo.");
        event.target.value = "";
      }
    }
  }
  selectProfilePhoto(event: any) {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileType === "application/pdf"
      ) {
        this.readFile(file, "profilePhoto");
      } else {
        alert("Please select a JPG/PNG/PDF file for Bank Cheque photo.");
        event.target.value = "";
      }
    }
  }

  // Method to read file and set photo
  readFile(file: File, type: string) {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      if (type === "pan") {
        this.panPhoto = e.target.result;
        this.championForm.controls["panPhotoData"].setValue(e.target.result);
      } else if (type === "bankcheque") {
        this.bankChequePhoto = e.target.result;
        this.championForm.controls["bankCancelChqPhotoData"].setValue(
          e.target.result
        );
      } else if (type === "profilePhoto") {
        this.profilePhoto = e.target.result;
        this.championForm.controls["profilePhotoData"].setValue(
          e.target.result
        );
      } else {
        this.championForm.controls["aadharPhotoData"].setValue(e.target.result);
        this.aadharPhoto = e.target.result;
      }
    };
    reader.readAsDataURL(file);
  }

  onCitySelect(city: City) {
    this.championForm.get("cityId").setValue(city.cityId);
    this.championForm.get("cityName").setValue(city.cityName);
  }

  onCityDeSelect(city: City) {
    this.championForm.get("cityId").setValue(null);
    this.championForm.get("cityName").setValue(null);
  }

  onSubmit() {
    if (this.championForm.valid) {
      this.loading = true;
      const apiURL = "/co-master/addChampion";
      this.commonService
        .addChampion(this.tempUserToken, apiURL, this.championForm.value)
        .subscribe(
          (result) => {
            if (result["responseStatus"] == "Ok") {
              window.alert("Champion registration request sent successfully");
              this.router.navigate(["/"]);
            } else {
              window.alert("Something went wrong! Try again later.");
            }
            this.loading = false;
          },
          (error) => {
            window.alert("Something went wrong! Try again later.");
            console.error("API Error:", error);
            this.loading = false;
          }
        );
    } else {
      alert("Enter Details properly");
      console.log(this.championForm.errors);
    }
  }

  closePayment() {
    this.showPayment = false;
  }

  onChampionPackageTypeSelect(
    championSubscriptionPackages: ChampionPackageType
  ) {
    this.championForm
      .get("championSubscriptionPackageId")
      .setValue(championSubscriptionPackages.championSubscriptionPackageId);
    this.championForm
      .get("packageTitle")
      .setValue(championSubscriptionPackages.packageTitle);
    console.log(championSubscriptionPackages);
  }

  onChampionPackageTypeDeSelect(
    championSubscriptionPackages: ChampionPackageType
  ) {
    this.championForm.get("championSubscriptionPackageId").setValue(null);
    this.championForm.get("packageTitle").setValue(null);
  }
}
