<div class="companySelectionWrapper">
        <div class="cmpSelectionLeftBlock">
              <h5>Branch Selection</h5>
              <div class="cmpInnerBlock">
                    <div class="cmpHeadingBlcok">
                          <span class="title">Branch</span>
                          <!--<a href="javscript:void(0)">Create New Company</a>-->
                    </div>
                    <div class="listOfCompany">
                          <mat-nav-list>
                                <mat-list-item *ngFor="let branch of allowedBranches let i = index" [ngClass]="{'selectedCompany': i === selectedBranchIndex }" (click)="branchSelection(i)">
                                      <a>{{ branch.branchName }}</a>
                                </mat-list-item>
                          </mat-nav-list>
                    </div>
                    <div class="cmpButtonSection">
                          <button class="btn btn-primary" (click)="newBranchSelection()">Select</button>
                          <button class="btn btn-danger" (click)="closedDialog()">Close</button>
                    </div>
              </div>
        </div>
  </div>