import { Component, OnInit,Input } from '@angular/core';
import { CandidOffersService } from 'src/app/shared/services/candid-offers.service';

@Component({
  selector: 'manageOffers',
  templateUrl: './manage-offers.component.html',
  styleUrls: ['./manage-offers.component.css']
})
export class ManageOffersComponent implements OnInit {
  pageTitle='ManageOffers'

  @Input('allowedActions') allowedActions: string = '';
  data:any

  constructor(private candidOffersService: CandidOffersService) { }

  homePage() {
    const eventName = "co-master/manageOffers";
    const dataObj = { mobile1: "7020850231", city_id: "14" };
    this.candidOffersService.backendAPICall(eventName, dataObj).subscribe(
      (response) => {
        console.log(response);
        this.data=response
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ngOnInit(): void {
    console.log('check if this is good');
    this.homePage()
  }
}
