import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cosmetics',
  templateUrl: './cosmetics.component.html'
 
})
export class CosmeticsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
