<html>
  <style>
    .content b {
      font-size: large;
      color: white;
      font-weight: 300;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    }
  </style>
  <Body>
    <section class="home-footer-about-candid">
      <div class="container">
        <div class="d-flex">
          <div class="align-self-center">
            <div class="logo">
              <img
                src="\assets\images\candid-offer-footer-logo.png"
                width="147"
                alt="candid-offer-footer-logo"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="content">
            <p><b>About US</b></p>
            <br />
            <p>
              <b>
                Candid Offers is the marketplace for real deals in real time
              </b>
            </p>
            <br />
            <p>
              Candid Offers makes the sellers, specially those with limited
              online presence have the digital footprints with their offers
              available to people accessing the systems. Candid offers have
              people on the street and is available in cities near you. Our
              champions are the touch points for sellers who curate their offers
              and present it to the customers. Candid offers also provides
              specialized services for our prime customers. Our Prime customers
              get the value of their money through better discounts than the
              regular customers
            </p>
            <br />

            <b>1.I am a customer, what do I get ?</b>
            <p>
              As a customer of digitized services, you often face the choice
              between a faceless service and better discounts. With Candid
              Offers, you can have both. You enjoy the local connection and
              relationship of a shop near you, along with the convenience of
              home services or enhanced discounts during festive seasons. What's
              more? As a prime customer, you are entitled to superior discounts
              and access to more categories than regular customers.
            </p>
            <br />

            <b>2.I am a seller, why should I enroll with Candid offers ?</b>

            <p>
              As a seller, you have your thriving business by local presence.
              However you are also competing with large eSellers who are putting
              the power of digitized economy and digitizally empowered customers
              who would like to order online. More often than not, your
              eCommerce aggregator takes a lion's share of your profit in lieu
              of larger reach. However with candid offers, you get the outreach
              to large customers without compromising the bottom line of the
              business.
            </p>
            <br />

            <b>3. Who we are ?</b><br />
            <p>
              We are the marketplace headquartered in Nagpur but present in many
              cities. We provide local support to the business and are very
              vocal for the local.

              <br />
            </p>

            <p></p>
          </div>
        </div>
      </div>
    </section>
  </Body>

  <footer>
    <!DOCTYPE html>

    <!-- Footer start here -->
    <footer></footer>
    <!-- Footer end here -->
  </footer>
</html>
s
