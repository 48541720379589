import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Alert } from "../interfaces/alert";

@Injectable({
    providedIn: 'root'
  })

export class AlertService{
    alertSettings$ = new Subject<Alert>()

    constructor() { }

    create( title: string,type:string,time:number,body:string,callback?:(params?:any)=>void){
        //console.log("alert create");
        this.alertSettings$.next({
            title,
            type,
            time,
            body,
            callback
        });
    }
}