<div>
    <div>
        <b>Delivery Terms</b>
        <ol>
            <li>{{data.deliveryTerms.one}}</li>
            <!-- <li>{{data.deliveryTerms.two}}</li> -->
        </ol>

        <b>Payment Terms</b>
        <ol>
            <li>{{data.paymentTerms.one}}</li>
            <!-- <li>{{data.paymentTerms.two}}</li> -->
        </ol>

        <b>Refund Terms</b>
        <ol>
            <li>{{data.refundTerms.one}}</li>
            <!-- <li>{{data.refundTerms.two}}</li> -->
        </ol>
        <b>Offer Duration</b>
        <p>from {{data.startDate}} to {{data.endDate}}</p>
    </div>
    <div class="buttons">
        <div >
            <button class="button-txt">Only For This Order</button>
        </div>
        <br/>
        <div>
            <button class="button-txt">For All Orders</button>
        </div>
        <br/>
        <div>
            <button class="button-txt">Edit</button>
        </div>
        <div>
            <button class="button-txt" (click)="back()">Back</button>
        </div>
    </div>
</div>