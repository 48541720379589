<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="stylesheet" href="style.css" />
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
      rel="stylesheet"
    />
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css"
      integrity="sha384-4LISF5TTJX/fLmGSxO53rV4miRxdg84mZsxmO8Rx5jGtp/LbrixFETvWa5a6sESd"
      crossorigin="anonymous"
    />
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>
    <title>candid offers</title>
  </head>

  <body>
    <div class="d-flex py-3 px-5 align-items-center justify-content-between">
      <a class="" href="/">
        <img
          class="img-fluid"
          style="width: 10rem"
          src="../../assets/images/logo.svg"
          alt=""
        />
      </a>
      <style>
        .Btn {
          width: 40px;
          height: 40px;
          background: linear-gradient(#ec1e24, #00badd);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;
          position: fixed;
          bottom: 1rem;
          right: 1rem;
          z-index: 999999;
          border: none;
        }

        .arrow path {
          fill: white;
        }

        .Btn:hover .arrow {
          animation: slide-in-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
        }

        @keyframes slide-in-bottom {
          0% {
            transform: translateY(10px);
            opacity: 0;
          }

          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      </style>
      <button
        class="Btn"
        id="backToTopBtn"
        *ngIf="showBackToTopButton"
        (click)="scrollToTop()"
      >
        <svg height="1.2em" class="arrow" viewBox="0 0 512 512">
          <path
            d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
          ></path>
        </svg>
      </button>

      <div class="d-flex justify-content-start gap-3">
        <a
          href="/champions"
          class="rounded-pill text-light text-decoration-none p-3"
          style="background-color: #7f39b8 !important"
          >For Champions
        </a>

        <a
          href="#section1"
          class="rounded-pill text-light text-decoration-none p-3"
          style="background-color: #ec1e24 !important"
          >For Customers
        </a>
        <a
          href="seller-screen"
          class="rounded-pill text-light text-decoration-none p-3"
          style="background-color: #00badd !important"
        >
          For Sellers
        </a>
      </div>
    </div>
    <div *ngIf="loading" class="loading-container">
      <div class="spinner"></div>
      <p>Loading...</p>
    </div>
    <ng-template #modalTemplate>
      <div>
        <h2>Application Download</h2>
        <form (ngSubmit)="downloadFile()">
          <input
            class="form-control my-2"
            placeholder="Name"
            type="name"
            name="name"
            id="password"
            [(ngModel)]="enteredName"
            required
          />
          <input
            class="form-control my-2"
            placeholder="Email"
            type="email"
            name="email"
            id="email"
            [(ngModel)]="enteredEmail"
            required
          />
          <input
            class="form-control my-2"
            placeholder="Mobile"
            type="text"
            name="moblie"
            id="moblie"
            [(ngModel)]="enteredMobile"
            required
          />
          <button class="btn btn-info float-left" type="submit">
            Download
          </button>
        </form>
        <button class="btn btn-danger float-right" (click)="closeDownload()">
          Close
        </button>
      </div>
    </ng-template>
    <!-- -------------------------------banner--------------------------------------------- -->
    <div class="banner d-flex">
      <div class="col-sm-6">
        <h1 class="title text-white">
          Real Deals in Real<br />
          Time on everything<br />
          on Candid Offers.
        </h1>
      </div>
      <div class="col-sm-6">
        <img src="../../assets/images/img3.png" alt="" />
        <img class="ban2" src="../../assets/images/Group 7104.png" alt="" />
      </div>
    </div>

    <!-- ------------------------------section1-------------------------------------------- -->
    <div id="section1">
      <div class="container">
        <h1 class="fw-bloder text-center">
          Explore Candid Offers for Shopping Customers
        </h1>

        <div class="d-flex justify-content-evenly gap-3 mt-5">
          <div class="col-md-4 ram-card">
            <img src="../../assets/images/img4.png" alt="" />

            <span>Best Offers 24X7 around the clock.<br /><br /></span>
            <p class="info">
              Unbeatable deals, available 24/7 around you, ensuring you enjoy
              savings at any hour, day or night.
            </p>
          </div>
          <div class="col-md-4 ram-card">
            <img src="../../assets/images/img5.png" alt="" />

            <span
              >Wide range of services and products , brands, budding and
              businesses.</span
            >
            <p class="info">
              Choose from a diverse range of services and products around you
              that cater to all your needs.
            </p>
          </div>
          <div class="col-md-4 ram-card">
            <img src="../../assets/images/img6.png" alt="" />

            <span>Support local brands and artists .<br /><br /></span>
            <p class="info">
              Choose from a diverse range of services and products around you
              that cater to all your needs.
            </p>
          </div>
          <!-- <div class="col-md-4 text-start">
            <img src="../../assets/images/img4.png" alt="" />
            <h2>Best Offers 24X7 around the clock.</h2>
            <p class="fs-6">
              Unbeatable deals, available 24/7 around you,<br />
              ensuring you enjoy savings <br />
              at any hour, day or night.
            </p>
          </div> -->

          <!-- <div class="col-md-4 text-start">
            <img src="../../assets/images/img5.png" />
            <h2>
              Wide range of services and products , brands, budding and
              businesses.
            </h2>
            <p class="fs-6">
              Choose from a diverse range of services and products around you
              that cater to all your needs.
            </p>
          </div> -->

          <!-- <div class="col-md-4 text-start">
            <img src="../../assets/images/img6.png" />
            <h2>Support local brands and artists .</h2>
            <p class="fs-6">
              Opportunity to empower local economy by choosing local talent and
              businesses.
            </p>
          </div> -->
        </div>
      </div>
    </div>

    <!--  ===============================section2-------------------------------------------------- -->
    <div class="container-fluid">
      <div id="section2">
        <div class="col-sm-5" id="jabru">
          <h2 class="jabru text-white">
            Scan or Download Candid<br />
            Offers Customer App
          </h2>
          <p class="jabru text-white">
            Scan the QR code now to unlock candid deals! <br />
            Download the Candid Offers Customer App for a seamless <br />
            shopping experience and personalized discounts.
          </p>
          <div
            style="cursor: pointer"
            class="box4 app-download"
            title="download appplication"
            (click)="openModal(modalTemplate)"
          >
            <img src="../../assets/images/img1.png" alt="" />
            <img src="../../assets/images/img2.png" alt="" />
          </div>
        </div>

        <div class="col-sm-2" id="jabru">
          <img src="../../assets/images/img7.png" alt="" />
        </div>

        <div class="col-sm-5" id="jabru">
          <img src="../../assets/images/img8.png" alt="" class="imagic" />
        </div>

        <div class="section">
          <div class="box10">
            <img src="../../assets/images/img8.png" alt="" class="imagic" />
          </div>

          <div class="box11">
            <h2 class="jabru text-white">
              Scan or Download<br />
              Candid Offers<br />
              for Sellers App
            </h2>

            <p class="jabru text-white">
              Lorem ipsum dolor sit amet, consectetur<br />
              adipiscing elit, sed do eiusmod tempor<br />
              incididunt ut labore et dolore magna aliqua.<br />
              Ut enim ad minim veniam.
            </p>

            <img src="../../assets/images/img1.png" alt="" />
            <img src="../../assets/images/img2.png" alt="" />
          </div>
          <div class="box12">
            <img src="../../assets/images/img7.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- -----------------------------section3--------------------------------------------- -->
    <div id="section3">
      <div class="container">
        <h1 class="fw-bloder text-center">
          Explore Candid Offers Local Sellers and Businesses
        </h1>
        <div class="d-flex justify-content-evenly gap-4 mt-5">
          <div class="col-md-4 ram-card">
            <img src="../../assets/images/img9.png" alt="" />

            <span>Zero commission.<br /><br /></span>
            <p class="info">
              Enjoy maximum profits with Candid Offers – zero commission means
              your earnings stay with you.
            </p>
          </div>
          <div class="col-md-4 ram-card">
            <img src="../../assets/images/img10.png" alt="" />

            <span>Complete control over offers and discounts.</span>
            <p class="info">
              Ability to decide your promotions. Candid Offers gives you
              complete control for offering personalized deals.
            </p>
          </div>
          <div class="col-md-4 ram-card">
            <img src="../../assets/images/img11.png" alt="" />

            <span>Minimal registration cost<br /><br /></span>
            <p class="info">
              Join Candid Offers with ease. Minimal registration costs
              translates to a seller-friendly marketplace
            </p>
          </div>
          <!-- <div class="col-md-4 text-start">
            <img src="../../assets/images/img9.png" alt="" />
            <h2>Zero commission.</h2>
            <p class="fs-6">
              Enjoy maximum profits with Candid Offers – zero commission means
              your earnings stay with you.
            </p>
          </div> -->

          <!-- <div class="col-md-4 text-start">
            <img src="../../assets/images/img10.png" alt="" />
            <h2>Complete control over offers and discounts.</h2>
            <p class="fs-6">
              Ability to decide your promotions. Candid Offers gives you
              complete control for offering personalized deals.
            </p>
          </div> -->

          <!-- <div class="col-md-4 text-start">
            <img src="../../assets/images/img11.png" alt="" />
            <h2>Minimal registration cost</h2>
            <p class="fs-6">
              Join Candid Offers with ease. Minimal registration costs
              translates to a seller-friendly marketplace
            </p>
          </div> -->
        </div>
      </div>
    </div>

    <!--  ===============================section4-------------------------------------------------- -->
    <div class="container-fluid">
      <div id="section4">
        <div class="col-sm-5" id="jabru">
          <h2 class="text-white">
            Scan or Download Candid<br />
            Offers for sellers App
          </h2>
          <p class="text-white">
            Unlock your business potential Scan to download <br />
            the Candid Offers Seller App for registration,<br />
            management, exclusive features, and growth opportunities. <br />
            <a href="/register-seller" class="text-white">
              What are You waiting for ? Please register here.
            </a>
          </p>

          <div
            style="cursor: pointer; margin-bottom: 5rem"
            class="box4 app-download"
            title="download application"
            (click)="openModal(modalTemplate)"
          >
            <img src="../../assets/images/img1.png" alt="" />
            <img src="../../assets/images/img2.png" alt="" />
          </div>
        </div>
        <div class="col-sm-2" id="jabru">
          <img src="../../assets/images/img7.png" alt="" />
        </div>

        <div class="col-sm-5" id="jabru">
          <img src="../../assets/images/img8.png" alt="" class="imagic" />
        </div>

        <div class="section">
          <div class="box10">
            <img src="../../assets/images/img8.png" alt="" class="imagic" />
          </div>

          <div class="box11">
            <h2 class="jabru text-white">
              Scan or Download<br />
              Candid Offers Seller<br />
              App
            </h2>
            <p class="jabru text-white">
              Lorem ipsum dolor sit amet, consectetur<br />
              adipiscing elit, sed do eiusmod tempor<br />
              incididunt ut labore et dolore magna aliqua.<br />
              Ut enim ad minim veniam.
            </p>

            <img src="../../assets/images/img1.png" alt="" />
            <img src="../../assets/images/img2.png" alt="" />
          </div>
          <div class="box12">
            <img src="../../assets/images/img7.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- --------------section5----------------------------------- -->

    <div class="container">
      <div id="section5">
        <div class="col-sm-9">
          <h3 class="mt-4 fs-3 fw-bloder">What is Candid Offers</h3>
          <p class="mt-2">
            Candid Offers is a special online marketplace for local businesses
            to thrive. It helps businesses grow without extra fees and allows
            consumers to reach to the right local business.
          </p>
        </div>
        <div class="col-sm-3">
          <img src="../../assets/images/img12.png" alt="" />
        </div>
      </div>
    </div>

    <!-- --------------section6----------------------------------- -->
    <div class="container">
      <div id="section6">
        <video src="../../assets/video/candid_video.mp4" controls></video>
      </div>
    </div>

    <!-- --------------section7----------------------------------- -->
    <div class="container-fluid">
      <div id="section7">
        <div class="box5 d-flex">
          <img src="../../assets/images/img13.png" alt="" />
          <img src="../../assets/images/img14.png" alt="" />
        </div>
        <div class="box6">
          <h3 class="heading text-white text-center">
            Champions Grow With Candid Offers.<br />
            Apply Here
          </h3>
          <p class="text text-center text-white">
            Join Candid Offers today, become a Champion, and grow with us! Apply
            now to be part of a friendly community, learn, and succeed together.
          </p>
          <!-- <div class="btn3 mt-5 text-center">
            <a href="/register-champion">Apply Now</a>
          </div> -->
        </div>
      </div>
    </div>

    <!-- Footer start here -->
    <footer>
      <div class="container mt-3">
        <!-- Footer links start here -->
        <div class="footer-links">
          <div class="row">
            <div class="col-sm-6 col-md-3 col-lg-3">
              <section class="footer-link">
                <h3>Company</h3>
                <ul class="list-unstyled">
                  <li><a href="../aboutus">About Us</a></li>
                  <li><a href="#">Best Offers</a></li>
                  <li><a href="#">Blog</a></li>
                  <li><a href="../payments">Terms and Conditions</a></li>
                  <li><a href="/assets/privacy-policy.html">Privacy Policy</a></li>
                </ul>
              </section>
            </div>

            <div class="col-sm-6 col-md-2 col-lg-3">
              <section class="footer-link">
                <h3>Help</h3>
                <ul class="list-unstyled">
                  <li><a href="#">My Account</a></li>
                  <li>
                    <a href="../payments"
                      >Terms and Conditions Online Payment</a
                    >
                  </li>
                  <li><a href="#">Wish List</a></li>
                  <li><a href="../contactus">Contact Us</a></li>
                  <li>
                    <a href="../datarententionpolicy">Data Rentention Policy</a>
                  </li>
                </ul>
              </section>
            </div>
            <div class="col-sm-6 col-md-5 col-lg-3">
              <section class="footer-link">
                <h3>Customer Care</h3>
                <div class="contact-details d-flex align-items-center">
                  <div class="icon">
                    <img
                      src="\assets\images\headphone-white-icon.png"
                      alt="headphone-white-icon"
                    />
                  </div>
                  <div class="content">
                    <span class="title">Hotline Free 24 X 7 </span>
                    <a
                      class="phone-number text-decoration-none text-light"
                      href="tel:+918600799991"
                      >+91 8600799991</a
                    >
                  </div>
                </div>
                <div class="contact-details d-flex align-items-center">
                  <div class="icon">
                    <img
                      src="\assets\images\at-the-rate-white-icon.png"
                      alt="at-the-rate-white-icon"
                    />
                  </div>
                  <div class="content">
                    <span class="title">Email</span>
                    <span class="email"
                      ><a
                        class="text-decoration-none"
                        href="mailto:support@candidoffers.com"
                        >support</a
                      ></span
                    >
                  </div>
                </div>
              </section>
            </div>
            <div class="col-sm-6 col-md-2 col-lg-3">
              <section class="footer-link">
                <h3>Follow Us</h3>
                <div
                  class="social-links justify-content-center d-flex align-items-center"
                >
                  <div class="icon">
                    <a href="https://www.facebook.com">
                      <i class="bi bi-facebook" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div class="icon">
                    <a
                      href="https://www.instagram.com/candidoffers?igsh=Z3lxdHRrbm54dXU2"
                    >
                      <i class="bi bi-instagram" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <!-- Footer links end here -->
      </div>
      <!-- Copyright start here -->
      <div class="copyright">
        <div class="container text-center">
          Copyright &copy; candidoffers.com India Private Limited. All rights
          reserved.
        </div>
      </div>
      <!-- Copyright end here -->
    </footer>
    <!-- Footer end here -->
    <!-- Optional JavaScript -->
    <!-- jQuery first, then Popper.js, then Bootstrap JS -->
    <script
      src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
      integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
      crossorigin="anonymous"
    ></script>
    <script
      src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
      integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
      crossorigin="anonymous"
    ></script>
    <script
      src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
      integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
      crossorigin="anonymous"
    ></script>
    <script
      src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"
      integrity="sha512-bPs7Ae6pVvhOSiIcyUClR7/q2OAsRiovw4vAkX+zJbw3ShAeeqezq50RIIcIURq7Oa20rW2n2q+fyXBNcU9lrw=="
      crossorigin="anonymous"
      referrerpolicy="no-referrer"
    ></script>
    <!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"></script> -->
  </body>
</html>
