import { Component, Output } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from "../services/alert.service";
import { CommonService } from "../services/common.service";
@Component({
      templateUrl: 'branchselection.component.html'
})
export class BranchSelectionComponent{
    allowedBranches:any[] = [];
    selectedBranchIndex:number = null; 
    constructor(public dialogRef: MatDialogRef<BranchSelectionComponent>, private location: Location, private alertSerive: AlertService, private commonService: CommonService ){
        let userSessionData = JSON.parse(localStorage.getItem('userData'));
        let currentCompanyData = JSON.parse(localStorage.getItem('currentCompanyData'));
        let allowedCompanies = userSessionData['user']['allowedCompanies'];
        this.allowedBranches = allowedCompanies.filter(ac => ac.companyId === currentCompanyData['companyId'])[0]['allowedBranches'];
        if(this.allowedBranches.length === 1){
            //this.selectedBranchIndex = 0;
      }
    }

    closedDialog(): void {
        this.dialogRef.close()
    }

    branchSelection( forIndex ){
        this.selectedBranchIndex = forIndex;
    }

    newBranchSelection():void{
        if(this.selectedBranchIndex === null){
            this.alertSerive.create("Branch Selection Error","error",0,"Please select branch");
        }else{
            // Update the current branch parameter.
            let newCurrentBranchData = {
                "branchId":this.allowedBranches[this.selectedBranchIndex]['branchId'],
                "branchName":this.allowedBranches[this.selectedBranchIndex]['branchName'],
                "branchCode":this.allowedBranches[this.selectedBranchIndex]['branchCode'],
            }
            localStorage.setItem('currentBranchData',JSON.stringify(newCurrentBranchData));
            let userSessionData = JSON.parse(localStorage.getItem('userData'));
            userSessionData['user']['currentSelectionData']['branch'] = newCurrentBranchData;
            localStorage.setItem('userData',JSON.stringify(userSessionData));
            this.commonService.updateCurrentSelectionData(JSON.stringify(userSessionData['user']['currentSelectionData'])).subscribe(
                (result) => {
                      if(result['responseStatus'] == 'Ok'){
                            location.reload();
                      }else{
                            this.alertSerive.create("Current Selection Update Failed","error",0,result['message']);
                      }
                },
                (error) => {
                      //console.log("E::"+error);
                      this.alertSerive.create("API Error","error",0,error);
                }
          );
            //location.reload();
        }
    }
}