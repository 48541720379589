<div class="noBottomSpace pageWrapper">
  <div class="container-fluid">
    <div class="innerPageWrapper pdf">
      <div class="row">
        <div class="col-md-12">
          <div class="pdf-block">
            <h3>Report Download</h3>
            <div class="row m-l-0 m-r-0">
              <div class="col-lg-12 p-l-0 p-r-0 bgd-white">
                <div class="card">
                  <div class='card-body m-t-0'>
                    <div class="table-responsive clearfix">
                    </div>
                    <div class="container">
                      <button (click)="createPdf()" class="btn btn-info mb-4 mt-2">Download PDF</button>
                      <table class="table table-striped table-bordered table-hover">
                        <tr>
                          <th>City Code</th>
                          <th>City</th>
                          <th>Country</th>
                          <th>State</th>
                        </tr>
                        <tr *ngFor="let item of result">
                          <td>{{item.cityCode}}</td>
                          <td>{{item.cityName}}</td>
                          <td>{{item.countryName}}</td>
                          <td>{{item.stateName}}</td>
                        </tr>
                      </table>