import {
  Component,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'downloads',
  templateUrl: './downloads.component.html',

})
export class DownloadsComponent implements OnInit {
  currentYear: number;
  vendorFileUrl: string = 'https://drive.google.com/file/d/14_MsHZhpZJdkuGc4XCB9RSB_rel-rDDX/view?usp=sharing';
  customerFileUrl: string = 'https://drive.google.com/file/d/13GP5JqulcTEGI9WQq2AJsY1nq1FirxAb/view?usp=sharing';
  constructor() {
    this.currentYear = new Date().getFullYear();
  }
  vendorDownloadFile() {
    window.location.href = this.vendorFileUrl;
  }
  customerDownloadFile() {
    window.location.href = this.customerFileUrl;
  }
  ngOnInit(): void {
  }
}