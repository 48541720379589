import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SystemService } from "../../../shared/services/system.service";
import { AlertService } from "../../../shared/services/alert.service";
import { ToastrService } from 'ngx-toastr';
import { ListActionsComponentService } from "../../../shared/services/list-actions-component.service";
import { MenuAction } from "../../system/interfaces/menu-actions.interface";
// import { ifError } from 'assert';
@Component({
    selector: 'form-actions',
    templateUrl: './form-actions.component.html',
})

export class FormActionsComponent implements OnInit {
    @Input('allowedActions') allowedActions: string = '';
    @Input('actionDataForMenu') actionDataForMenu: string = '';

    menuActionData: Array<MenuAction> = [];
    applicableActionData:Array<MenuAction> = [];

    constructor(private systemService: SystemService, private listComponentService: ListActionsComponentService,  private alertService: AlertService, private toastr: ToastrService) {

    }

    ngOnInit() {
        this.menuActionData = JSON.parse(localStorage.getItem("menuActionsData"));
        if(this.menuActionData === null){
            this.getMenuActions();
        }else{
            this.filterMenuActionData();
        }
    }

    onClickEvent(eventName){
        this.listComponentService.publish(eventName);
    }

    getMenuActions(){
        this.systemService.getMenuActions({}).subscribe(
            (result) => {
                if(result['responseStatus'] == 'Ok'){
                    this.menuActionData = result['menuActions'];
                    localStorage.setItem("menuActionsData", JSON.stringify(this.menuActionData));
                    this.filterMenuActionData();
                }else{
                    this.alertService.create("API Error","error",0,"Menu Action List Loading Failed");
                }
            },
            (error) => {
                //console.log("E::"+error);
            }
        )
    }

    filterMenuActionData(){
        let filteredAllowedActionsArr = this.allowedActions.split(',').filter(a=> (this.actionDataForMenu[a]));
        if(filteredAllowedActionsArr.length > 0 && this.menuActionData != null){
            this.applicableActionData = this.menuActionData.filter(a => { return filteredAllowedActionsArr.indexOf(a.menuActionCode) !== -1; } );
        }
        if(this.actionDataForMenu.hasOwnProperty('0')){
            this.applicableActionData.push({
                menuActionCode:"0",
                menuActionIcon:"fas fa-arrow-left",
                menuActionId:"0",
                menuActionName:"Back",
                sortOrder:"99",
                status:"A"
            });
        }
    }
}