import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { SupportService } from '../../shared/services/support.service';
import { AlertService } from '../../shared/services/alert.service';

@Component({
  selector: 'app-city-launch-dialog',
  templateUrl: './cityLaunchDialog.Component.html',
  styleUrls: ['./city-launch-dialog.component.scss']
})
export class CityLaunchDialogComponent implements OnInit {
  cityLaunchForm: FormGroup;
  countries: any[] = [];
  states: any[] = [];
  districts: any[] = [];
  cities: any[] = [];
  
  dropdownSettings = {
    singleSelection: true,
    text: "Select",
    enableSearchFilter: true,
    showCheckbox: true,
    enableFilterSelectAll: false,
    maxHeight: 200,
    classes: 'custome-multi-select',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    filterSelectAllText: 'Select all filtered results',
    filterUnSelectAllText: 'UnSelect all filtered results',
    lazyLoading: true,
    searchPlaceholderText: 'Search',
    position: 'bottom',
    badgeShowLimit: 1,
    noDataLabel: 'No data available'
  };

  countrySettings = {
    ...this.dropdownSettings,
    text: "Select Country",
    labelKey: 'countryName',
    primaryKey: 'countryId',
    searchBy: ['countryName'],
    noDataLabel: 'No countries available'
  };

  stateSettings = {
    ...this.dropdownSettings,
    text: "Select State",
    labelKey: 'stateName',
    primaryKey: 'stateId',
    searchBy: ['stateName'],
    noDataLabel: 'No states available'
  };

  districtSettings = {
    ...this.dropdownSettings,
    text: "Select District",
    labelKey: 'districtName',
    primaryKey: 'districtId',
    searchBy: ['districtName'],
    noDataLabel: 'No districts available'
  };

  citySettings = {
    ...this.dropdownSettings,
    text: "Select City",
    labelKey: 'cityName',
    primaryKey: 'cityId',
    searchBy: ['cityName'],
    noDataLabel: 'No cities available'
  };

  constructor(
    public dialogRef: MatDialogRef<CityLaunchDialogComponent>,
    private fb: FormBuilder,
    private supportService: SupportService,
    private alertService: AlertService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getCountries();
    this.setupFormSubscriptions();
  }

  createForm() {
    this.cityLaunchForm = this.fb.group({
      // Location Details
      countryId: ['', Validators.required],
      stateId: ['', Validators.required],
      districtId: [''],
      cityId: ['', Validators.required],
      selectedCountry: [null, Validators.required],
      selectedState: [null, Validators.required],
      selectedDistrict: [null],
      selectedCity: [null, Validators.required],

      // Super Champions Configuration
      superChampionSecurityDeposit: ['', [Validators.required, Validators.min(0)]],
      superChampionTargets: ['', [Validators.required, Validators.min(0)]],
      superChampionFixedCommission: ['', [Validators.required, Validators.min(0)]],
      superChampionVariableCommission: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      superChampionSellerOnboarding: ['', [Validators.required, Validators.min(0)]],
      superChampionOffers: ['', [Validators.required, Validators.min(0)]],

      // Champions Configuration
      championSecurityDeposit: ['', [Validators.required, Validators.min(0)]],
      championTargets: ['', [Validators.required, Validators.min(0)]],
      championFixedCommission: ['', [Validators.required, Validators.min(0)]],
      championVariableCommission: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      championSellerOnboarding: ['', [Validators.required, Validators.min(0)]],
      championOffers: ['', [Validators.required, Validators.min(0)]],
      championPrimeCustomerSubscription: ['', [Validators.required, Validators.min(0)]],
      championToChampionReferral: ['', [Validators.required, Validators.min(0)]],

      // Sellers Configuration
      sellerOnboardingCharges: ['', [Validators.required, Validators.min(0)]],
      sellerPerOfferPrice: ['', [Validators.required, Validators.min(0)]],
      sellerToPrimeReferral: ['', [Validators.required, Validators.min(0)]],
      sellerToSellerReferral: ['', [Validators.required, Validators.min(0)]],
      sellerReferralInKind: ['', [Validators.required, Validators.min(0)]]
    });
  }

  setupFormSubscriptions() {
    this.cityLaunchForm.get('countryId').valueChanges.subscribe(value => {
      if (value) {
        this.getStates();
      }
    });

    this.cityLaunchForm.get('stateId').valueChanges.subscribe(value => {
      if (value) {
        this.getDistricts();
        this.getCities();
      }
    });
  }

  getCountries() {
    this.spinner.show();
    this.supportService.backendAPICall('getCountries', {}).subscribe(
      (result) => {
        this.spinner.hide();
        if (result['responseStatus'] === 'Ok') {
          this.countries = result['countries'];
        } else {
          this.alertService.create('Error', 'error', 0, 'Failed to load countries');
        }
      },
      (error) => {
        this.spinner.hide();
        this.alertService.create('Error', 'error', 0, 'Failed to load countries');
      }
    );
  }

  getStates() {
    if (!this.cityLaunchForm.get('countryId').value) return;
    
    this.spinner.show();
    const filter = {
      countryId: this.cityLaunchForm.get('countryId').value,
      status: 'A'
    };

    this.supportService.backendAPICall('getStates', filter).subscribe(
      (result) => {
        this.spinner.hide();
        if (result['responseStatus'] === 'Ok') {
          this.states = result['states'];
        } else {
          this.alertService.create('Error', 'error', 0, 'Failed to load states');
        }
      },
      (error) => {
        this.spinner.hide();
        this.alertService.create('Error', 'error', 0, 'Failed to load states');
      }
    );
  }

  getDistricts() {
    if (!this.cityLaunchForm.get('stateId').value) return;

    this.spinner.show();
    const filter = {
      stateId: this.cityLaunchForm.get('stateId').value,
      status: 'A'
    };

    this.supportService.backendAPICall('getDistricts', filter).subscribe(
      (result) => {
        this.spinner.hide();
        if (result['responseStatus'] === 'Ok') {
          this.districts = result['districts'];
        } else {
          this.alertService.create('Error', 'error', 0, 'Failed to load districts');
        }
      },
      (error) => {
        this.spinner.hide();
        this.alertService.create('Error', 'error', 0, 'Failed to load districts');
      }
    );
  }

  getCities() {
    if (!this.cityLaunchForm.get('stateId').value) return;

    this.spinner.show();
    const filter = {
      stateId: this.cityLaunchForm.get('stateId').value,
      status: 'A'
    };

    this.supportService.backendAPICall('getCities', filter).subscribe(
      (result) => {
        this.spinner.hide();
        if (result['responseStatus'] === 'Ok') {
          this.cities = result['cities'];
        } else {
          this.alertService.create('Error', 'error', 0, 'Failed to load cities');
        }
      },
      (error) => {
        this.spinner.hide();
        this.alertService.create('Error', 'error', 0, 'Failed to load cities');
      }
    );
  }

  onCountrySelect(country: any) {
    this.cityLaunchForm.patchValue({
      countryId: country.countryId
    });
  }

  onStateSelect(state: any) {
    this.cityLaunchForm.patchValue({
      stateId: state.stateId
    });
  }

  onDistrictSelect(district: any) {
    this.cityLaunchForm.patchValue({
      districtId: district.districtId
    });
  }

  onCitySelect(city: any) {
    this.cityLaunchForm.patchValue({
      cityId: city.cityId
    });
    this.loadCityConfig(city.cityId);
  }

  loadCityConfig(cityId: string) {
    this.spinner.show();
    this.supportService.getCityLaunchConfig(cityId).subscribe(
      (result) => {
        this.spinner.hide();
        if (result['responseStatus'] === 'Ok') {
          const config = result['config'];
          if (config) {
            this.cityLaunchForm.patchValue({
              superChampionSecurityDeposit: config.superChampionSecurityDeposit,
              superChampionTargets: config.superChampionTargets,
              superChampionFixedCommission: config.superChampionFixedCommission,
              superChampionVariableCommission: config.superChampionVariableCommission,
              superChampionSellerOnboarding: config.superChampionSellerOnboarding,
              superChampionOffers: config.superChampionOffers,
              championSecurityDeposit: config.championSecurityDeposit,
              championTargets: config.championTargets,
              championFixedCommission: config.championFixedCommission,
              championVariableCommission: config.championVariableCommission,
              championSellerOnboarding: config.championSellerOnboarding,
              championOffers: config.championOffers,
              championPrimeCustomerSubscription: config.championPrimeCustomerSubscription,
              championToChampionReferral: config.championToChampionReferral,
              sellerOnboardingCharges: config.sellerOnboardingCharges,
              sellerPerOfferPrice: config.sellerPerOfferPrice,
              sellerToPrimeReferral: config.sellerToPrimeReferral,
              sellerToSellerReferral: config.sellerToSellerReferral,
              sellerReferralInKind: config.sellerReferralInKind
            });
          } else {
            // Reset form to default values if no config exists
            this.cityLaunchForm.patchValue({
              superChampionSecurityDeposit: '',
              superChampionTargets: '',
              superChampionFixedCommission: '',
              superChampionVariableCommission: '',
              superChampionSellerOnboarding: '',
              superChampionOffers: '',
              championSecurityDeposit: '',
              championTargets: '',
              championFixedCommission: '',
              championVariableCommission: '',
              championSellerOnboarding: '',
              championOffers: '',
              championPrimeCustomerSubscription: '',
              championToChampionReferral: '',
              sellerOnboardingCharges: '',
              sellerPerOfferPrice: '',
              sellerToPrimeReferral: '',
              sellerToSellerReferral: '',
              sellerReferralInKind: ''
            });
          }
        } else {
          this.alertService.create('Error', 'error', 0, result['message'] || 'Failed to load city configuration');
        }
      },
      (error) => {
        this.spinner.hide();
        console.error('Error loading city config:', error);
        this.alertService.create('Error', 'error', 0, error.error?.message || 'Failed to load city configuration');
      }
    );
  }

  onSubmit() {
    if (this.cityLaunchForm.valid) {
      const formData = this.cityLaunchForm.value;
      this.spinner.show();
      
      // Ensure all numeric values are properly formatted
      const numericFields = [
        'superChampionSecurityDeposit',
        'superChampionTargets',
        'superChampionFixedCommission',
        'superChampionVariableCommission',
        'superChampionSellerOnboarding',
        'superChampionOffers',
        'championSecurityDeposit',
        'championTargets',
        'championFixedCommission',
        'championVariableCommission',
        'championSellerOnboarding',
        'championOffers',
        'championPrimeCustomerSubscription',
        'championToChampionReferral',
        'sellerOnboardingCharges',
        'sellerPerOfferPrice',
        'sellerToPrimeReferral',
        'sellerToSellerReferral',
        'sellerReferralInKind'
      ];

      numericFields.forEach(field => {
        if (formData[field]) {
          formData[field] = Number(formData[field]);
        }
      });
      
      this.supportService.saveCityLaunchConfig(formData).subscribe(
        (result) => {
          this.spinner.hide();
          if (result['responseStatus'] === 'Ok') {
            this.toastr.success('City configuration saved successfully');
            this.dialogRef.close(result);
          } else {
            this.alertService.create('Error', 'error', 0, result['message'] || 'Failed to save city configuration');
          }
        },
        (error) => {
          this.spinner.hide();
          console.error('Error saving city config:', error);
          this.alertService.create('Error', 'error', 0, error.error?.message || 'Failed to save city configuration');
        }
      );
    } else {
      Object.keys(this.cityLaunchForm.controls).forEach(key => {
        const control = this.cityLaunchForm.get(key);
        if (control.invalid) {
          control.markAsTouched();
        }
      });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
