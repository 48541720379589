import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'privacypolicy',
    templateUrl: './privacypolicy.component.html',
  
  })
  export class PrivacyPolicyComponent implements OnInit {

    constructor() { }

  ngOnInit(): void {
  }
}