import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boutique',
  templateUrl: './boutique.component.html'
 
})
export class BoutiqueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
