import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
//import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';

import { Router } from '@angular/router';
import { ApiService } from './service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
})
export class AppComponent {
  title = 'COWebsite';
  MenOffers$: any;
  user: any = {};
  posts: any;
  loading = false;
  constructor(private apiService: ApiService, private router: Router) {
    this.user = {
      username: 'RITU',
      password: '@ritu123',
    };
  }

  fetchoffers() {
    this.MenOffers$ = this.apiService.fetchoffers();
  }

  goToAll() {
    this.router.navigate(['/', 'All']);
  }

  goToApparel() {
    this.router.navigate(['/', 'Apparel']);
  }

  goToAccessories() {
    this.router.navigate(['/', 'Accessories']);
  }

  goToArt() {
    this.router.navigate(['/', 'Art&Hobby']);
  }

  goToBoutique() {
    this.router.navigate(['/', 'Boutique']);
  }

  goToCosmetics() {
    this.router.navigate(['/', 'Cosmetics']);
  }

  goToChocolateSupplier() {
    this.router.navigate(['/', 'ChocolateSupplier']);
  }

  goToDailyNeeds() {
    this.router.navigate(['/', 'DailyNeeds']);
  }
  goToPrivacyPolicy(){
    this.router.navigate(['/', 'PrivacyPolicy']);
  }

  // ngOnInit() {
  //   this.apiService.getPosts()
  //     .subscribe(response => {
  //       this.posts = response;
  //     });

  //     if (localStorage.getItem('userToken') !== 'BKm7qwb3UwrXZO2BSp42utobkUpJK3eV') {
  //       // verify token
  //       this.loading = true;
  //       this.apiService.userTokenAuthentication().subscribe(
  //       (result: { [x: string]: string; }) => {
  //             if(result['responseStatus'] == 'Ok'){
  //                   if(JSON.parse(localStorage.getItem('BKm7qwb3UwrXZO2BSp42utobkUpJK3eV'))){
  //                         this.router.navigate([JSON.parse(localStorage.getItem('BKm7qwb3UwrXZO2BSp42utobkUpJK3eV')]);
  //                   }
  //             }
  //       });
  // }

  // }

  // Carousel

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      1000: {
        items: 1,
      },
      700: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };

  bannerImage = [
    {
      id: 1,
      src: '/assets/images/home-banner.jpg',
      alt: 'Image_1',
      title: 'Image_1',
    },
    {
      id: 2,
      src: '/assets/images/home-banner.jpg',
      alt: 'Image_2',
      title: 'Image_3',
    },
    {
      id: 3,
      src: '/assets/images/home-banner.jpg',
      alt: 'Image_3',
      title: 'Image_3',
    },
    {
      id: 4,
      src: '/assets/images/home-banner.jpg',
      alt: 'Image_4',
      title: 'Image_4',
    },
  ];
}
