import { Component, OnInit,NgZone } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService } from '../../shared/services/alert.service';
import { CommonService } from '../../shared/services/common.service';
// import { AngularFireAuth } from '@angular/fire/auth';


// export class SignInComponent implements OnInit {
//   userData: any;
//   constructor(public authService: AuthService,  private afAuth: AngularFireAuth,
//     private router: Router,
//     private ngZone: NgZone) {}
//   ngOnInit() {
//     var data = JSON.parse(localStorage.getItem('user_data') || '{}');
//     this.userData = data.user.phoneNumber;
//     console.log(this.userData);
//   }
//   logout() {
//     return this.afAuth.signOut().then(() => {
//       this.ngZone.run(() => {
//         this.router.navigate(['phone']);
//       });
//     });

//   }}



@Component({
  selector: 'sign-in',
  templateUrl: './sign-in.component.html',
 
})


export class SignInComponent implements OnInit {
  title = 'Login Component';
user: any = {};
loading = false;
error = '';
constructor(
  //public dialog: MatDialog,
  private router: Router,
  private commonService: CommonService,
  private alertSerive: AlertService,
  public authService: AuthService
) {
  this.user = {
    username: '',
    password: '',
  };
}

  ngOnInit() {
    if (
      localStorage.getItem('isLogged') &&
      localStorage.getItem('userToken') !== null
    ) {
      // verify token if token is active then redirect to admin
      this.loading = true;
      this.commonService.userTokenAuthentication().subscribe((result: any) => {
        if (result['responseStatus'] == 'Ok') {
          if (
            JSON.parse(localStorage.getItem('currentModuleData'))['moduleName']
          ) {
            this.router.navigate([
              JSON.parse(localStorage.getItem('currentModuleData'))[
                'moduleName'
              ].toLowerCase(),
            ]);
          } else {
            this.commonService.redirectToLogin();
          }
        }
      });
    }



   }


login() {
  this.loading = true;
  this.commonService.userAuthentication(this.user).subscribe(
    (result) => {
      console.log(result);
      if (result['responseStatus'] == 'Ok') {
        localStorage.setItem('userToken', result['userToken']);
        localStorage.setItem('isLogged', 'true');
        localStorage.setItem('userData', JSON.stringify(result['userData']));
        localStorage.setItem(
          'currentModuleData',
          JSON.stringify(
            result['userData']['user']['currentSelectionData']['module']
          )
        );
        // Set Current BranchData ( Default Branch )
        //location.reload();
        let defaultModule =
          result['userData']['user']['currentSelectionData']['module'][
            'moduleName'
          ].toLowerCase();
        this.router.navigate([defaultModule]);
      } else {
        this.alertSerive.create(
          'Authentication Failed',
          'error',
          0,
          result['message']
        );
      }
      this.loading = false;
    },
    (error) => {
      //console.log("E::"+error);
      this.loading = false;
      this.alertSerive.create('API Error', 'error', 0, error);
    }
  );
}
}