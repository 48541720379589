<div class="container-fluid form">
    <div class="row form-row ">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <!-- <div class="authBlock"> -->
        <div class="card my-5">
          <div class="card-body">
        <h3 class="card-title text-center">Sign Up</h3>
        <div>
        <div class="formGroup">
          <input type="email" class="form-control form-control-lg" placeholder="Email ID" #userEmail required />
        </div>
        <div class="formGroup">
          <input type="password" class="form-control form-control-lg" placeholder="Create Password" #userPwd required />
        </div>
        <div class="formGroup">
          <input type="password" class="form-control form-control-lg" placeholder="Confirm Password" formControlName="confirmPassword" #userconfirmPwd required />
        </div>
        <div class="formGroup">
          <input class="form-control form-control-lg" placeholder="Mobile No." type="mobile1" formControlName="mobile1" />
      </div>
      <div class="formGroup">
        <input class="form-control form-control-lg" placeholder="CityId"
            type="cityId" formControlName="cityId" />
    </div>
    <div class="form-field row">
      <h4 class="col-md-3 ml-1 float-left"> Gender </h4>
      <mat-radio-group formControlName="gender" aria-labelledby="radio-group-label" class="ml-5">
          <mat-radio-button value="M" class="mr10">Male</mat-radio-button>
          <mat-radio-button value="F">Female</mat-radio-button>
          <mat-radio-button value="O">Other</mat-radio-button>
      </mat-radio-group>
  </div>
  <div class="form-field row">
    <h4 class="col-md-3 ml-1 float-left"> Login As </h4>
    <mat-radio-group formControlName="userrole" aria-labelledby="radio-group-label" class="ml-5">
        <mat-radio-button value="C" class="mr10">Customer</mat-radio-button>
        <mat-radio-button value="V">Vendor</mat-radio-button>
        <mat-radio-button value="H">Champion</mat-radio-button>
    </mat-radio-group>
</div>
    <br>
    <div class = "text float-left">
        <div class="formGroup">
          <input
            type="button"
            class="btn btn-danger"
            value="Sign Up"
            (click)="authService.SignUp(userEmail.value, userPwd.value)"
          />
        </div>
      
        <div class="formGroup">
          <span class="or"><span class="orInner">Or</span></span>
        </div>
        <!-- Continue with Google -->
        <div class="formGroup">
          <button
            type="button"
            class="btn googleBtn"
            class="btn btn btn-outline-primary"
            (click)="authService.GoogleAuth()"
          >
            <i class="fab fa-google-plus-g"></i>
            Continue with Google
          </button>
        </div>
      <!-- </div> -->
      <div class="redirectToLogin">
        <span
          >Already have an account?
          <span class="redirect" routerLink="/sign-in">Log In</span></span
        >
      </div>
    </div>
      </div>
      </div>
      </div>
      </div>
    </div>
  </div>