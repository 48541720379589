import { AppCommonModule } from './modules/common/app-common.module';
import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from './shared/modules/material/material.module';
import { CommonService } from './shared/services/common.service';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CarouselModule } from 'ngx-owl-carousel-o';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ArtComponent } from './art&Hobby/art.component';
import { BoutiqueComponent } from './boutique/boutique.component';
import { ChocolateSupplierComponent } from './chocolate-supplier/chocolate-supplier.component';
import { CosmeticsComponent } from './cosmetics/cosmetics.component';
import { DailyNeedsComponent } from './daily-needs/daily-needs.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
//new
import { SendEmailComponent } from './components/send-mail/send-mail.component';

import { FormsModule } from '@angular/forms';
import { CodeComponent } from './code/code.component';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { AuthService } from './shared/services/auth.service';

import { CommonModule } from '@angular/common';
import { NgOtpInputModule } from 'ng-otp-input';
import { HomeComponent } from './home/home.component';

import { RegisterComponent } from './vendor/register/register.component';
import { OfferUploadHistoryComponent } from './vendor/offer-upload-history/offer-upload-history.component';
import { ManageOffersComponent } from './vendor/manage-offers/manage-offers.component';
import { IndexComponent } from './vendor/transaction-screen/index.component';
import { EditOffersComponent } from './vendor/edit-offers/edit-offers.component';
import { CreateOffersComponent } from './vendor/create-offers/create-offers.component';
import { CongratulationComponent } from './vendor/congratulation/congratulation.component';
import { AcceptOfferComponent } from './vendor/accept-offer/accept-offer.component';
import { VendorSignInComponent } from './vendor/vendor-sign-in/vendor-sign-in.component';
import { QRCodeModule } from 'angular2-qrcode';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { TermsAndConditionComponent } from './vendor/terms-and-condition/terms-and-condition.component';
import { VerifyOtpComponent } from './vendor/verify-otp/verify-otp.component';

import firebase from 'firebase/app';
import { CreateOffersFormComponent } from './vendor/create-offers/create-offers-form.component';
import { CreateOffersListComponent } from './vendor/create-offers/create-offers-list.component';
import { ChampionRegComponent } from './champion-reg/champion-reg.component';
import { AccountDeleteComponent } from './DeleteAccount/deleteAccount.component';
import { AccountDeletionComponent } from './account-deletion/account-deletion.component';
import { sellerRegComponent } from './seller-reg/seller-reg.component';
import { superchampionRegComponent } from './superchampion-reg/superchampion-reg.component';
import { PaymentCommonComponent } from './payment-common/payment-common.component';
import { ForgotPasswordWebComponent } from './components/forgot-password-web/forgot-password-web.component';

firebase.initializeApp(environment.firebase);


@NgModule({
  declarations: [
    AppComponent,
    AccountDeleteComponent,
    AccountDeletionComponent,
    sellerRegComponent,
    superchampionRegComponent,
    PhoneNumberComponent,
    CodeComponent,
    ChampionRegComponent,
    ArtComponent,
    BoutiqueComponent,
    ChocolateSupplierComponent,
    CosmeticsComponent,
    DailyNeedsComponent,
    DashboardComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    SendEmailComponent,
    PhoneNumberComponent,
    CodeComponent,
    HomeComponent,
    RegisterComponent,
    // OfferUploadHistoryComponent,
    // ManageOffersComponent,
    // IndexComponent,
    // EditOffersComponent,
    // CreateOffersComponent,
    // CongratulationComponent,
    // AcceptOfferComponent,
    VendorSignInComponent,
    TermsAndConditionComponent,
    VerifyOtpComponent,
    PaymentCommonComponent,
    ForgotPasswordWebComponent
  ],
  imports: [
    CommonModule,
    // BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CarouselModule,
    BrowserAnimationsModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    NgOtpInputModule,
    MaterialModule,
    QRCodeModule,
    NgxScannerQrcodeModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    AppCommonModule
  ],
  exports: [],
  providers: [AuthService, AuthGuard, CommonService],
  schemas: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
