import { Component,Inject, OnInit } from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.css']
})
export class TermsAndConditionComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<TermsAndConditionComponent>,@Inject(MAT_DIALOG_DATA) public data) {
    console.log(data)
   }

   back(){
    this.dialogRef.close();
   }
  ngOnInit(): void {
  }

}
