import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'datarententionpolicy',
    templateUrl: './datarententionpolicy.component.html',
  
  })
  export class DataRententionPolicyComponent implements OnInit {

    constructor() { }

  ngOnInit(): void {
  }
}