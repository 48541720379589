<div class="companySelectionWrapper">
      <div class="cmpSelectionLeftBlock">
            <h5>Company Selection</h5>
            <div class="cmpInnerBlock">
                  <div class="cmpHeadingBlcok">
                        <span class="title">Compay</span>
                        <!--<a href="javscript:void(0)">Create New Company</a>-->
                  </div>
                  <div class="listOfCompany">
                        <mat-nav-list>
                              <mat-list-item *ngFor="let company of allowedCompanies let i = index" [ngClass]="{'selectedCompany': i === selectedCompanyIndex }">
                                    <a (click)="companySelection(i)" >{{ company.companyName }}</a>
                              </mat-list-item>
                        </mat-nav-list>
                  </div>
                  <div class="cmpButtonSection">
                        <button class="btn btn-primary" (click)="newCompanySelection()">Select</button>
                        <button class="btn btn-danger" (click)="closedDialog()">Close</button>
                  </div>
            </div>
      </div>
      <div class="cmpFYearRightBlock">
            <div class="headingSection">
                  Financial Year
            </div>
            <div class="listOfCmpFYearBlock">
                  <mat-nav-list>
                        <mat-list-item *ngFor="let fy of companiesFinancialYears let i = index" [ngClass]="{'selectedYear': i === selectedYearIndex }" (click)="yearSelection(i)">
                              <a >{{ fy.fyear }}</a>
                        </mat-list-item>
                  </mat-nav-list>
            </div>
      </div>

</div>