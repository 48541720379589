<div id="divPaymentForm">
  <h1>Candid Offer Payment</h1>
  <form
    #paymentForm
    action="{{ postUrl }}"
    method="POST"
    target="paymentProcessTab"
  >
    <input type="hidden" name="pgId" value="e63e330f5687d94206adbde6205ef994" />
    <input
      type="hidden"
      name="pgoId"
      value="e63e330f5687d94206adbde6205ef994"
    />
    <input type="hidden" name="type" value="1" />
    <input type="hidden" name="token" value="{{ paymentData.token }}" />
    <input type="hidden" name="paymentId" value="{{ paymentData.id }}" />
    <input
      type="hidden"
      name="paymentAmount"
      value="{{ paymentData.amount }}"
    />
    <input type="hidden" name="refId" value="{{ refId }}" />
    <input
      type="hidden"
      name="transactionAmount"
      value="{{ paymentData.amount }}"
    />
    <input
      type="submit"
      value="Proceed to payment"
      type="button" name="btnSubmit" (click)="procesPayment()"
      class="btn btn-primary btn-block btn-lg"
    />
  </form>
</div>
