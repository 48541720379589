import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'aboutus',
    templateUrl: './aboutus.component.html',
  
  })
  export class AboutusComponent implements OnInit {

    constructor() { }

  ngOnInit(): void {
  }
}