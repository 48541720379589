import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'contactus',
    templateUrl: './contactus.component.html',
  
  })
  export class ContactUsComponent implements OnInit {

    constructor() { }

  ngOnInit(): void {
  }
}