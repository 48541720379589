import { Component, Output,OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from "../services/alert.service";
import { SupportService} from "../services/support.service";
import { CommonService } from "../services/common.service";
@Component({
      templateUrl: 'change-password.component.html'
})
export class ChangePasswordComponent implements OnInit{

    changePasswordForm: FormGroup;
    constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>, public fb: FormBuilder, private location: Location, private alertService: AlertService, private supportService: SupportService, private commonService: CommonService ){
    }

    closeDialog(): void {
        this.dialogRef.close()
    }

    ngOnInit() {
        this.changePasswordForm = this.fb.group({
            oldPassword:['',Validators.required],
            password:['',[Validators.required,Validators.minLength(8)]],
            confirmPassword:['',[Validators.required,Validators.minLength(8)]]
        });
    }

    changePassword(){
        if(this.changePasswordForm.valid){
            if(this.changePasswordForm.get('password').value != this.changePasswordForm.get('confirmPassword').value){
                this.alertService.create("Error", "error", 0, "New Password And Confirm Password Not Match");
                return false;
            }
            if(this.changePasswordForm.get('password').value === this.changePasswordForm.get('oldPassword').value){
                this.alertService.create("Error", "error", 0, "New Password And Old Password Cannot Not Be Same");
                return false;
            }
            let changePasswordDataObj = Object.assign({}, this.changePasswordForm.value);
            this.supportService.backendAPICall('changePassword',changePasswordDataObj).subscribe(
                (result) => {
                    if (result['responseStatus'] == 'Ok') {
                        this.changePasswordForm.reset();
                        this.alertService.create("Success", "success", 0, "Password Changed Successfully. Please login again",()=>{
                            this.closeDialog();
                            this.commonService.logout().subscribe(
                                (result) => {
                                    if (result['responseStatus'] == 'Ok') {
                                        this.commonService.redirectToLogin();
                                    } else {
                                        this.alertService.create("Logout Failed", "error", 0, result['message']);
                                    }
                                },
                                (error) => {
                                    //this.alertService.create("API Error","error",0,error);
                                }
                            )
                        });
                    }else{
                        this.alertService.create("Error", "error", 0, result['message']);
                    }
                }
            )
        }else{
            this.alertService.create("Error", "error", 0, "Invalid / Missing Details");
            return false;
        }
        return false;
    }
}