import {
  Component,
  ViewChild,
  Input,
  HostListener,
  Output,
  EventEmitter,
} from "@angular/core";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "payment",
  templateUrl: "payment.component.html",
  styleUrls: ["payment.component.scss"],
})
export class PaymentComponent {
  @ViewChild("paymentForm") paymentForm;
  @ViewChild("paymentIFrame") paymentIFrame;
  @Output() closePaymentAction = new EventEmitter();
  @Input() paymentData: any;

  transactionAmount: number = 2;
  refId: number = Math.floor(Math.random() * 1000);
  postUrl: string = "";
  paymentSubsbscription;
  tabRef: any;
  constructor() {
    this.postUrl =
      environment.apiCredentials.apiDomain.substring(
        0,
        environment.apiCredentials.apiDomain.lastIndexOf("/")
      )
      + "/api/payment/request";
    this.postUrl += "?token=" + localStorage.getItem("userToken");
    console.log(this.postUrl);
    (<any>window).reciveDataFromIframe = this.reciveDataFromIframe.bind(this);
  }

  @HostListener("window:message", ["$event"])
  reciveDataFromIframe(event: MessageEvent) {
    console.log(event);
    this.load(event.data);
  }

  load(data) {
    console.log("data", data);
    console.log("taRef", this.tabRef);
    //this.closePayment();
  }

  procesPayment() {
    console.log();
    
    this.tabRef = window.open("", "paymentProcessTab");

    this.paymentForm.nativeElement.submit();
  }

  closePayment() {
    console.log("close called");
    this.tabRef.close();
  }

  cancelPayment() {
    this.closePaymentAction.emit(false);
  }
}
