import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CandidOffersService {

  public token: string;
  constructor(private http: HttpClient ,
    private firestore: AngularFirestore) { }
  
  // Following Is Common Event For Database Get / Add / Edit / Delete Activity
  backendAPICall(eventName: string = '', dataObj: any = {}) {
    // console.log('hello service' , eventName);
    if (eventName) {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('userToken') });
      return this.http.post(
        environment.apiCredentials.apiDomain + '/' + eventName.trim(),
        JSON.stringify(dataObj),
        { headers: headers }
      );
    }
  }
   
  getCandidVendorSubscriptionPlans() {
    return this.firestore.collection('candidVendorSubScriptionPlans').snapshotChanges();
  }
  



  // In candid-offers.service.ts
  
  deleteFromFirestore(documentId: string): Observable<any> {
    return from(this.firestore.doc(`candidVendorSubScriptionPlans/${documentId}`).delete());
  }
  
  
  getChampions(filter: object = { }) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-master/getChampionLists",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  getVendors(filter: object = { }) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-master/getVendorLists",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  getStaffs(filter: object = { }) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-master/getcustomerUserLists",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  getRemitTypes(filter: object = { }) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/support/getPaymentGateways",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
}