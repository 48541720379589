import { Component, Output,OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from "../../../shared/services/alert.service";
import { SupportService} from "../../../shared/services/support.service";
import { CommonService } from "../../../shared/services/common.service";
import { HttpClient } from '@angular/common/http';

@Component({
      templateUrl: './forgot.component.html'
})
export class ForgotComponent implements OnInit{


    form: FormGroup;

    constructor(
      private formBuilder: FormBuilder,
      private http: HttpClient,

    ) {
    }
  
    ngOnInit(){
      this.form = this.formBuilder.group({
        email1: '',
      });
    }
  
    submit() {
      this.http.post('http://localhost:3636/api/support/forgotPassword', this.form.getRawValue())
        .subscribe(() =>{
            console.log('success')
        });
    }
  }
