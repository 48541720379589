import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'payments',
    templateUrl: './payments.component.html',
  
  })
  export class PaymentsComponent implements OnInit {

    constructor() { }

  ngOnInit(): void {
  }
}