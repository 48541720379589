<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Account Delete Request</title>
  <!-- Import Angular Material Styles -->
  <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" rel="stylesheet">
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <link href="https://cdnjs.cloudflare.com/ajax/libs/@angular/material/13.2.0/material.min.css" rel="stylesheet">
  <style>
    body {
      font-family: Arial, sans-serif;
      background-color: #f4f4f4;
    }
    .container {
      max-width: 400px;
      margin: 50px auto;
      padding: 20px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    h2 {
      margin-top: 0;
      text-align: center;
      color: #333;
    }
    .form-group {
      margin-bottom: 20px;
    }
    .form-group label {
      display: block;
      font-weight: bold;
      color: #555;
    }
    .form-group mat-form-field {
      width: 100%;
    }
    .btn {
      display: block;
      width: 100%;
      padding: 10px 20px;
      background-color: #007bff;
      color: #fff;
      text-decoration: none;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s ease;
    }
    .btn:hover {
      background-color: #0056b3;
    }
  </style>
</head>
<body>
  <div class="d-flex text-center py-3 px-5 align-items-center">
    <a class="" href="/">
      <img
        class="img-fluid"
        style="width: 10rem"
        src="../../assets/images/logo.svg"
        alt=""
      />
    </a>
  </div>
  <div *ngIf="loading" class="loading-container">
    <div class="spinner"></div>
    <p>Loading...</p>
  </div>
  <div class="container">
    <h2>Account Delete Request</h2>
    <form [formGroup]="accountDeleteForm" (ngSubmit)="onSubmit()" class="form" novalidate>
      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>Username</mat-label>
          <input matInput formControlName="username" required>
          <mat-error *ngIf="accountDeleteForm.get('username').hasError('required')">Username is required</mat-error>
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field class="full-width">
            <input matInput placeholder="Mobile" formControlName="mobile"
                required>
            <mat-error *ngIf="accountDeleteForm.get('mobile').hasError('required')">
                Mobile No. is required</mat-error>
            <mat-error *ngIf="accountDeleteForm.get('mobile').hasError('pattern')">
                Only integer value is allowed</mat-error>
            <mat-error
                *ngIf="accountDeleteForm.get('mobile').hasError('minlength') || 
                accountDeleteForm.get('mobile').hasError('maxlength')">
                Only 10 Digit mobile no. allowed </mat-error>
        </mat-form-field>

        <b mat-raised-button color="primary" class="btn float-right py-0"
        style="width: auto; cursor: pointer;" (click)="sendOTP()">
        Send Mobile OTP
    </b>
    

        <div *ngIf="showOTPVerification">
            <mat-form-field class="full-width">
                <input matInput placeholder="Enter OTP" formControlName="otp"
                    required>
                <mat-error *ngIf="accountDeleteForm.get('otp').hasError('required')">
                    OTP is required</mat-error>
            </mat-form-field>

            <b class="btn float-right py-0" style="width: auto; cursor: pointer;" mat-raised-button color="primary" (click)="verifyOTP()">
              Verify OTP
          </b>

            <div *ngIf="isOTPSuccess"
                class="d-flex align-item-center justify-content-center">
                <mat-icon style="color: green;">check_circle</mat-icon> Mobile
                Verified Successfully!
            </div>
        </div>
    </div>
    <div class="form-field">
        <mat-form-field class="full-width">
            <input matInput placeholder="Email" formControlName="email"
                required>
            <mat-error *ngIf="accountDeleteForm.get('email').hasError('required')">
                Email is required</mat-error>
            <mat-error *ngIf="accountDeleteForm.get('email').hasError('email')">
                Enter valid email address</mat-error>
        </mat-form-field>
        <b mat-raised-button color="primary" class="btn float-right py-0"
    style="width: auto; cursor: pointer;" (click)="sendMailOTP()">
    Send Email OTP
</b>


        <div *ngIf="showMailOTPVerification">
            <mat-form-field class="full-width">
                <input matInput placeholder="Enter OTP" formControlName="mailOtp"
                    required>
                <mat-error *ngIf="accountDeleteForm.get('mailOtp').hasError('required')">
                    OTP is required</mat-error>
            </mat-form-field>

            <b class="btn float-right py-0" style="width: auto; cursor: pointer;" mat-raised-button color="primary" (click)="verifyMailOTP()">
              Verify OTP
          </b>

            <div *ngIf="isMailOTPSuccess"
                class="d-flex align-item-center justify-content-center">
                <mat-icon style="color: green;">check_circle</mat-icon> Email
                Verified Successfully!
            </div>
        </div>
    </div>
      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>Reason for Account Delete</mat-label>
          <textarea matInput formControlName="reason" rows="4" required></textarea>
          <mat-error *ngIf="accountDeleteForm.get('reason').hasError('required')">Reason is required</mat-error>
        </mat-form-field>
      </div>
      <button class="btn" type="submit">Confirm Account Delete</button>
    </form>
  </div>

</body>
</html>


