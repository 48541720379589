<div class="city-launch-dialog">
  <h2 mat-dialog-title>
    <mat-icon>location_city</mat-icon>
    City Launch Configuration
  </h2>

  <form [formGroup]="cityLaunchForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <mat-accordion>
        <!-- Location Details Section -->
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>place</mat-icon>
              Location Details
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="location-grid">
            <!-- Country -->
            <div class="form-field">
              <angular2-multiselect
                [data]="countries"
                [settings]="countrySettings"
                (onSelect)="onCountrySelect($event)"
                formControlName="selectedCountry"
                placeholder="Select Country"
              >
              </angular2-multiselect>
              <mat-error *ngIf="cityLaunchForm.get('selectedCountry').invalid && cityLaunchForm.get('selectedCountry').touched">
                Country is required
              </mat-error>
            </div>

            <!-- State -->
            <div class="form-field">
              <angular2-multiselect
                [data]="states"
                [settings]="stateSettings"
                (onSelect)="onStateSelect($event)"
                formControlName="selectedState"
                placeholder="Select State"
              >
              </angular2-multiselect>
              <mat-error *ngIf="cityLaunchForm.get('selectedState').invalid && cityLaunchForm.get('selectedState').touched">
                State is required
              </mat-error>
            </div>

            <!-- District -->
            <div class="form-field">
              <angular2-multiselect
                [data]="districts"
                [settings]="districtSettings"
                (onSelect)="onDistrictSelect($event)"
                formControlName="selectedDistrict"
                placeholder="Select District"
              >
              </angular2-multiselect>
            </div>

            <!-- City -->
            <div class="form-field">
              <angular2-multiselect
                [data]="cities"
                [settings]="citySettings"
                (onSelect)="onCitySelect($event)"
                formControlName="selectedCity"
                placeholder="Select City"
              >
              </angular2-multiselect>
              <mat-error *ngIf="cityLaunchForm.get('selectedCity').invalid && cityLaunchForm.get('selectedCity').touched">
                City is required
              </mat-error>
            </div>
          </div>
        </mat-expansion-panel>

        <!-- Super Champions Configuration Section -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>stars</mat-icon>
              Super Champions Configuration
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="configuration-grid">
            <!-- Security Deposit -->
            <mat-form-field appearance="outline">
              <mat-label>Refundable Security Deposit</mat-label>
              <input matInput type="number" formControlName="superChampionSecurityDeposit" placeholder="Enter Amount">
              <mat-error *ngIf="cityLaunchForm.get('superChampionSecurityDeposit').invalid">
                Valid security deposit amount is required
              </mat-error>
            </mat-form-field>

            <!-- Targets -->
            <mat-form-field appearance="outline">
              <mat-label>Targets</mat-label>
              <input matInput type="number" formControlName="superChampionTargets" placeholder="Enter Target Value">
              <mat-error *ngIf="cityLaunchForm.get('superChampionTargets').invalid">
                Valid target value is required
              </mat-error>
            </mat-form-field>

            <!-- Fixed Commission -->
            <mat-form-field appearance="outline">
              <mat-label>Fixed Commission</mat-label>
              <input matInput type="number" formControlName="superChampionFixedCommission" placeholder="Enter Fixed Commission">
              <mat-error *ngIf="cityLaunchForm.get('superChampionFixedCommission').invalid">
                Valid fixed commission amount is required
              </mat-error>
            </mat-form-field>

            <!-- Variable Commission -->
            <mat-form-field appearance="outline">
              <mat-label>Variable Commission (%)</mat-label>
              <input matInput type="number" formControlName="superChampionVariableCommission" placeholder="Enter Variable Commission">
              <mat-error *ngIf="cityLaunchForm.get('superChampionVariableCommission').invalid">
                Valid variable commission percentage (0-100) is required
              </mat-error>
            </mat-form-field>

            <!-- Seller Onboarding -->
            <mat-form-field appearance="outline">
              <mat-label>Seller Onboarding</mat-label>
              <input matInput type="number" formControlName="superChampionSellerOnboarding" placeholder="Enter Amount">
              <mat-error *ngIf="cityLaunchForm.get('superChampionSellerOnboarding').invalid">
                Valid seller onboarding amount is required
              </mat-error>
            </mat-form-field>

            <!-- Offers -->
            <mat-form-field appearance="outline">
              <mat-label>Offers</mat-label>
              <input matInput type="number" formControlName="superChampionOffers" placeholder="Enter Amount">
              <mat-error *ngIf="cityLaunchForm.get('superChampionOffers').invalid">
                Valid offers amount is required
              </mat-error>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <!-- Champions Configuration Section -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>emoji_events</mat-icon>
              Champions Configuration
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="configuration-grid">
            <!-- Security Deposit -->
            <mat-form-field appearance="outline">
              <mat-label>Refundable Security Deposit</mat-label>
              <input matInput type="number" formControlName="championSecurityDeposit" placeholder="Enter Amount">
              <mat-error *ngIf="cityLaunchForm.get('championSecurityDeposit').invalid">
                Valid security deposit amount is required
              </mat-error>
            </mat-form-field>

            <!-- Targets -->
            <mat-form-field appearance="outline">
              <mat-label>Targets</mat-label>
              <input matInput type="number" formControlName="championTargets" placeholder="Enter Target Value">
              <mat-error *ngIf="cityLaunchForm.get('championTargets').invalid">
                Valid target value is required
              </mat-error>
            </mat-form-field>

            <!-- Fixed Commission -->
            <mat-form-field appearance="outline">
              <mat-label>Fixed Commission</mat-label>
              <input matInput type="number" formControlName="championFixedCommission" placeholder="Enter Fixed Commission">
              <mat-error *ngIf="cityLaunchForm.get('championFixedCommission').invalid">
                Valid fixed commission amount is required
              </mat-error>
            </mat-form-field>

            <!-- Variable Commission -->
            <mat-form-field appearance="outline">
              <mat-label>Variable Commission (%)</mat-label>
              <input matInput type="number" formControlName="championVariableCommission" placeholder="Enter Variable Commission">
              <mat-error *ngIf="cityLaunchForm.get('championVariableCommission').invalid">
                Valid variable commission percentage (0-100) is required
              </mat-error>
            </mat-form-field>

            <!-- Seller Onboarding -->
            <mat-form-field appearance="outline">
              <mat-label>Seller Onboarding</mat-label>
              <input matInput type="number" formControlName="championSellerOnboarding" placeholder="Enter Amount">
              <mat-error *ngIf="cityLaunchForm.get('championSellerOnboarding').invalid">
                Valid seller onboarding amount is required
              </mat-error>
            </mat-form-field>

            <!-- Offers -->
            <mat-form-field appearance="outline">
              <mat-label>Offers</mat-label>
              <input matInput type="number" formControlName="championOffers" placeholder="Enter Amount">
              <mat-error *ngIf="cityLaunchForm.get('championOffers').invalid">
                Valid offers amount is required
              </mat-error>
            </mat-form-field>

            <!-- Prime Customer Subscriptions -->
            <mat-form-field appearance="outline">
              <mat-label>Prime Customer Subscriptions</mat-label>
              <input matInput type="number" formControlName="championPrimeCustomerSubscription" placeholder="Enter Amount">
              <mat-error *ngIf="cityLaunchForm.get('championPrimeCustomerSubscription').invalid">
                Valid prime customer subscription amount is required
              </mat-error>
            </mat-form-field>

            <!-- Champion to Champion Referral -->
            <mat-form-field appearance="outline">
              <mat-label>Champion to Champion Referral</mat-label>
              <input matInput type="number" formControlName="championToChampionReferral" placeholder="Enter Amount">
              <mat-error *ngIf="cityLaunchForm.get('championToChampionReferral').invalid">
                Valid champion referral amount is required
              </mat-error>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <!-- Sellers Configuration Section -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>store</mat-icon>
              Sellers Configuration
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="configuration-grid">
            <!-- Onboarding Charges -->
            <mat-form-field appearance="outline">
              <mat-label>Onboarding Charges</mat-label>
              <input matInput type="number" formControlName="sellerOnboardingCharges" placeholder="Enter Amount">
              <mat-error *ngIf="cityLaunchForm.get('sellerOnboardingCharges').invalid">
                Valid onboarding charges amount is required
              </mat-error>
            </mat-form-field>

            <!-- Per Offer Price -->
            <mat-form-field appearance="outline">
              <mat-label>Per Offer Price</mat-label>
              <input matInput type="number" formControlName="sellerPerOfferPrice" placeholder="Enter Amount">
              <mat-error *ngIf="cityLaunchForm.get('sellerPerOfferPrice').invalid">
                Valid per offer price is required
              </mat-error>
            </mat-form-field>

            <!-- Seller to Prime Customer Referral -->
            <mat-form-field appearance="outline">
              <mat-label>Seller to Prime Customer Referral</mat-label>
              <input matInput type="number" formControlName="sellerToPrimeReferral" placeholder="Enter Amount">
              <mat-error *ngIf="cityLaunchForm.get('sellerToPrimeReferral').invalid">
                Valid referral amount is required
              </mat-error>
            </mat-form-field>

            <!-- Seller to Seller Referral -->
            <mat-form-field appearance="outline">
              <mat-label>Seller to Seller Referral</mat-label>
              <input matInput type="number" formControlName="sellerToSellerReferral" placeholder="Enter Amount">
              <mat-error *ngIf="cityLaunchForm.get('sellerToSellerReferral').invalid">
                Valid referral amount is required
              </mat-error>
            </mat-form-field>

            <!-- Referral in Kind -->
            <mat-form-field appearance="outline">
              <mat-label>Referral in Kind</mat-label>
              <input matInput type="number" formControlName="sellerReferralInKind" placeholder="Enter Amount">
              <mat-error *ngIf="cityLaunchForm.get('sellerReferralInKind').invalid">
                Valid referral in kind amount is required
              </mat-error>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button type="button" (click)="onCancel()">
        <mat-icon>close</mat-icon>
        Cancel
      </button>
      <button mat-raised-button color="primary" type="submit" [disabled]="cityLaunchForm.invalid">
        <mat-icon>save</mat-icon>
        Save Configuration
      </button>
    </mat-dialog-actions>
  </form>
</div>
