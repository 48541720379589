import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';
import { MaterialModule } from '../../shared/modules/material/material.module';
//import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { AgGridModule } from 'ag-grid-angular';
import { SidebarModule } from 'ng-sidebar';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxEchartsModule } from 'ngx-echarts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { BranchSelectionComponent } from '../../shared/dialog/branchselection.component';
import { CityLaunchDialogComponent } from '../../shared/dialog/cityLaunchDialog.Component';
import { ChangePasswordComponent } from '../../shared/dialog/change-password.component';
import { CompanySelectionComponent } from '../../shared/dialog/companyselection.component';
import { ConfirmDialogComponent } from '../../shared/dialog/confirm-dialog.component';
import { ImageCropperDialogComponent } from '../../shared/dialog/image-cropper-dialog.component';
import { DialogService } from '../../shared/services/dialog.service';
import { GridService } from '../../shared/services/grid.service';
import { FormActionsComponent } from '../common/form-actions/form-actions.component';
import { PaymentComponent } from '../common/payment/payment.component';
import { DynamicTabsDirective } from '../common/tabs/dynamictab.directive';
import { TabsConfigDirective } from '../common/tabs/tab-config.directive';
import { TabComponent } from '../common/tabs/tab.component';
import { TabsPanelComponent } from '../common/tabs/tabspanel.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './footer/footer.component';
import { ForgotComponent } from './forgot/forgot.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { PDFComponent } from './pdf-download/pdf-download.component';

import { QRCodeModule } from 'angular2-qrcode';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    AngularMultiSelectModule,
    QRCodeModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    MaterialModule,
    NgMaterialMultilevelMenuModule,
    //PdfJsViewerModule,
    AgGridModule.withComponents([]),
    FileUploadModule,
    ImageCropperModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    SidebarModule.forRoot(),
    SharedModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    CompanySelectionComponent,
    BranchSelectionComponent,
    ChangePasswordComponent,
    ImageCropperDialogComponent,
    TabComponent,
    TabsPanelComponent,
    DynamicTabsDirective,
    TabsConfigDirective,
    ConfirmDialogComponent,
    FormActionsComponent,
    PaymentComponent,
    PDFComponent,
    ForgotComponent,
    LoginComponent,
  ],
  exports: [
    CommonModule,
    AngularMultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    MaterialModule,
    NgMaterialMultilevelMenuModule,
    //PdfJsViewerModule,
    AgGridModule,
    //Ng2SmartTableModule,
    FileUploadModule,
    ImageCropperModule,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    CompanySelectionComponent,
    BranchSelectionComponent,
    ChangePasswordComponent,
    ImageCropperDialogComponent,
    TabComponent,
    TabsPanelComponent,
    DynamicTabsDirective,
    TabsConfigDirective,
    ConfirmDialogComponent,
    FormActionsComponent,
    PaymentComponent,
    PDFComponent,
    ForgotComponent,
    LoginComponent,
    SharedModule
  ],
  providers: [GridService, DialogService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  entryComponents: [
    TabComponent,
    CompanySelectionComponent,
    BranchSelectionComponent,
    ChangePasswordComponent,
    ImageCropperDialogComponent,
    ConfirmDialogComponent,
  ],
})
export class AppCommonModule {}
