<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Account Delete Request</title>
    <link
      href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css"
      rel="stylesheet"
    />
    <style>
      /* Custom CSS for better UI */
      /* Input field */
      .form-input {
        border-color: #d1d5db;
        border-width: 1px;
        border-radius: .5rem;
        padding: 0.3rem;
      }

      /* Send OTP button */
      .otp-button {
        background-color: #2563eb;
        border-color: #2563eb;
        color: white;
      }

      /* Verify OTP button */
      .verify-otp-button {
        background-color: #10b981;
        border-color: #10b981;
        color: white;
      }

      .loading-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 9999999;
      }

      .spinner {
        border: 4px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        border-top: 4px solid #3498db;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    </style>
  </head>

  <body class="bg-gray-100">
    
    <div *ngIf="loading" class="loading-container">
      <div class="spinner"></div>
      <p>Loading...</p>
    </div>
    <div class="container mx-auto py-8 px-4">
      <div class="max-w-lg mx-auto bg-white rounded-lg shadow-lg p-6">
        <div class="text-center mb-6">
          <img
            src="https://candidoffers.com/assets/images/logo.svg"
            alt="Logo"
            class="mx-auto h-16"
          />
          <h1 class="text-2xl font-semibold mt-2">Forgot Password</h1>
        </div>
        <form
          [formGroup]="deleteForm"
          (ngSubmit)="submitRequest()"
          class="form"
          novalidate
        >
          <!-- Email section -->
          <div class="mb-4">
            <label for="email" class="block text-gray-700 font-semibold"
              >Email *</label
            >
            <div class="flex items-center">
              <input
                type="email"
                id="email"
                formControlName="email"
                class="form-input mt-1 flex-1"
                placeholder="Enter your email address"
                required
              />
              <button
                type="button"
                class="ml-4 otp-button px-4 py-2 rounded font-semibold text-sm"
                (click)="sendEmailOTP()"
                id="sendEmailOTP"
              >
                Send OTP
              </button>
            </div>
            <div id="isMailVerified" class="hidden float-right">
              Email Verified ✅
            </div>
            <div id="emailOTPVerification" class="hidden mt-2">
              <input
                type="text"
                id="emailOTP"
                formControlName="emailOTP"
                maxlength="4"
                class="form-input mt-1 flex-1"
                placeholder="Enter OTP"
              />
              <button
                type="button"
                class="mt-2 ml-3 verify-otp-button px-4 py-2 rounded font-semibold text-sm"
                (click)="verifyEmailOTP()"
                id="verifyEmailOTP"
              >
                Verify OTP
              </button>
            </div>
            <!-- Validation message for email -->
            <div
              *ngIf="cf.email.errors?.required && cf.email.touched"
              class="text-red-500 text-sm mt-1"
            >
              Email is required.
            </div>
            <div
              *ngIf="cf.email.errors?.email && cf.email.touched"
              class="text-red-500 text-sm mt-1"
            >
              Please enter a valid email address.
            </div>
          </div>

          <div class="mb-4">
            <label for="newPassword" class="block text-gray-700 font-semibold"
              >New Password*</label
            >
            <input
              type="password"
              id="newPassword"
              formControlName="newPassword"
              class="form-input mt-1 block w-full"
              placeholder="Enter new Password"
              required
            />
            <!-- Validation message for new password -->
            <div
              *ngIf="cf.newPassword.errors?.required && cf.newPassword.touched"
              class="text-red-500 text-sm mt-1"
            >
              New password is required.
            </div>
            <div
              *ngIf="
                deleteForm.get('newPassword').hasError('minlength')
              "
              class="text-red-500 text-sm mt-1"
            >
              Password should be atleast 8 characters long
            </div>
          </div>
          <div class="mb-4">
            <label
              for="confirmPassword"
              class="block text-gray-700 font-semibold"
              >Confirm Password*</label
            >
            
            <input
              type="password"
              id="confirmPassword"
              formControlName="confirmPassword"
              class="form-input mt-1 block w-full"
              placeholder="Confirm your new password"
              required
            />
            <!-- Validation message for confirm password -->
            <div
              *ngIf="
                cf.confirmPassword.errors?.required &&
                cf.confirmPassword.touched
              "
              class="text-red-500 text-sm mt-1"
            >
              Confirm password is required.
            </div>
            <div
              *ngIf="
                deleteForm.get('confirmPassword').hasError('minlength')
              "
              class="text-red-500 text-sm mt-1"
            >
              Password should be atleast 8 characters long
            </div>
            <div
              *ngIf="
                cf.confirmPassword.errors?.mismatch &&
                cf.confirmPassword.touched
              "
              class="text-red-500 text-sm mt-1"
            >
              Passwords do not match.
            </div>
          </div>
          <div class="mt-6">
            <button
              type="submit"
              class="w-full bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  </body>
</html>
