<!doctype html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <!-- Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">    
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.css">
    

    <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap" rel="stylesheet">
    <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous">
    
    <title>CANDID OFFERS</title>
<style>
  .signin-page header{background: transparent; border: 0;}
  .signin-page .card.form-card {
    border: 0;
}
.signin-page .form-control{
  height: 45px;
  border-radius: 10px;
}
.select-login select{
  color: #898989;
    border-radius: 10px;
    background: #fff;
    height:45px;
    border:1px solid #ced4da;
    padding: .375rem .75rem;
}
.signin-page .login-screen h6 {
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    color: #787878;
    letter-spacing: -1px;
}

select {
    word-wrap: normal;
}
button, select {
    text-transform: none;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
.registration-input-bg:last-child{
  margin: 0;
}
.registration-input-bg {
    border-radius: 0;
    background: #fff;
    height: 40px;
    color: #333;
    border-radius: 10px;
    font-size: 16px;
    margin-right: 5px;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.login-signup .dropdown .btn-theme {
  background-color: #3c4ea2;
  color: #ffffff;
  font-size: 15px;
  padding: .5rem 1rem;
  -webkit-border-radius: 100px 100px 100px 100px;
  -moz-border-radius: 100px 100px 100px 100px;
  -o-border-radius: 100px 100px 100px 100px;
  border-radius: 100px 100px 100px 100px;
}
.login-signup .dropdown .btn-theme:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}
@media (max-width: 576px) {
  .dropdown-login {
    width: 270px;
  }
}

@media (max-width: 576px) {
  .login-signup {
    margin-top: 8px;
  }
}
.mb-50{
  margin-bottom: 50px;
}
.large-blue-btn{
  webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    border:0;
    background-color:#546de5;
    color: #fff;
    font-size:20px;
   padding: .9rem 2.8rem;
    line-height: normal;
    width: 100%;
    border: 1px solid #546de5;
}
.large-blue-btn:hover {
  background: #fff;
  border: 1px solid #de1c4e;
  color: #de1c4e;
  font-weight: 600;
}
.popup-login{
  width: 400px;
    margin: auto;
}
.mb-50{
  margin-bottom: 50px;
}
.login-bg h5 {
    font-size: 30px;
    font-weight: 500;
    color: #243baa;
    padding-bottom: 30px;
    margin: 0;
    text-align: center;
}
.card {
    word-wrap: break-word;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}

    </style>


  </head>
<body class="signin-page">
  <header>
    <div class="logo">
      <div class="text-center">
        <a href="index.html"><img src="assets\images\login-logo.jpg" alt="" class="img-fluid"></a>
        </div>
    </div>

    <div class="mobile-logo d-none">
      <div class="text-center">
        <a href="index.html"><img src="assets\images\candid-offer-footer-logo.png" alt="" class="img-fluid"></a>
        </div>
    </div>
</header>
<div class="page-content">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
        <div class="card form-card mobile-shadow login-screen">
           <div class="card-body">
              <div class="login-bg"><h5>Vendor Login</h5></div>
              <div class="form-row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="popup-login">
                    <form [formGroup]="loginForm">
                      <div class="d-flex">
                        <div class="form-group select-login">
                          <label class="d-flex">Country</label>
                          <select>
                            <option>IN +91</option>
                            <option>US +01</option>
                          </select>
                        </div>
                        <div class="form-group w-100">
                          <label>Mobile Number</label>
                          <input type="text" class="form-control registration-input-bg" id="exampleDropdownFormEmail1" placeholder="Enter Phone Number" [formControl]="phoneNo"/>
                        </div>
                      </div>
                      <div class="form-group text-center">
                        <button type="submit" class="btn btn-primary large-blue-btn mb-50" (click)="login()">Login</button>
                      </div>
                      <div>
                      <h6>By continuing, you agree to Candid Offers
                        Terms & Conditions and Privacy policy. </h6>
                      </div>
                      <div id="sign-in-button"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>

<script src="https://code.jquery.com/jquery-3.4.1.slim.min.js" integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script>

<script src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"></script>
<script src="js/custom.js"></script>               
 
</body>
</html>