<div class="container form">
  <div class="row form-row">
    <!-- <div class="authBlock"> -->
    <div class="col-sm-12 col-md-10 col-lg-10 mx-auto">
      <div class="card my-5">
        <div class="card-body">
          <h3 class="card-title text-center">Reset Password</h3>
          <p class="text-center">
            Please enter your email address to request a password reset.
          </p>
          <div class="formGroup">
            <input
              type="email"
              class="form-control form-control-lg"
              placeholder="Email Address"
              #passwordResetEmail
              required
            />
          </div>
          <!-- Calling ForgotPassword from AuthService Api -->
          <div class="text-center">
            <div class="formGroup">
              <input
                type="submit"
                class="btn btn-danger"
                value="Reset Password"
                (click)="authService.ForgotPassword(passwordResetEmail.value)"
              />
            </div>
          </div>
        </div>
        <div class="text-center">
          <div class="redirectToLogin">
            <span
              >Go back to ?
              <span class="redirect" routerLink="/sign-in">Log In</span></span
            >
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
