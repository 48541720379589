import { Component, Input } from '@angular/core';

@Component({
      selector: 'tab-content',
      template: `
            <div [hidden]="!active" class="pane">
                  <ng-content></ng-content>
                  <ng-container *ngIf="template"
                  [ngTemplateOutlet]="template"
                  >
                  </ng-container>
            </div>
  `
})
export class TabComponent {
      @Input('tabTitle') title: string;
      @Input() active = false;
      @Input() isCloseable = false;
      @Input() template;
      @Input() code: number;
}