import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {dashboardService} from 'src/app/shared/services/dashboard.service';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'pdf-download.component',
  templateUrl: './pdf-download.html'
})


export class PDFComponent {

  pagetitle = 'Report'

 pdf:any;
 result:any[] = [];

// PDF DOWNLOAD

//  head = [['cityId','cityName','cityCode',"countryId","stateName"]]


  createPdf() {
    this.dashboardService.getCities().subscribe((data:any)=>{
      this.result=data.cities;
      console.log(this.result);
      var pdfValue= this.result.map(Object.values)
    var  headvalues = this.result.map(Object.keys)
      console.log(pdfValue)

    var doc = new jsPDF();

    doc.setFontSize(18);
    doc.text('City Report', 11, 8);
    doc.setFontSize(11);
    doc.setTextColor(100);



    (doc as any).autoTable({
      head: headvalues,
      body: pdfValue,
      theme: 'plain',
      startY: 15,

    bodyStyles: {
        valign: 'top'
    },

    styles: {
        cellWidth: 'wrap',
        rowPageBreak: 'auto',
        halign: 'justify'
    },

    columnStyles: {
        text: {
            cellWidth: 'auto'},

      didDrawCell: (data) => {
        console.log(data.column.index,data)
      }
     } })

    //  PDF NEW WINDow
    doc.output('dataurlnewwindow')

    // Download PDF
    doc.save('cityreport.pdf');
  }
    )

  }


       constructor(
        private dashboardService : dashboardService,
        private http: HttpClient
        )

          {
            this.dashboardService.getCities().subscribe((data:any)=>{
              console.log(data);
              data.cities.forEach(row => {

                this.result.push();

              });
              console.log(this.result)
            })



          ;}





      ngOnInit(){

        this.dashboardService.getCities().subscribe((data:any)=>{
          console.log(data);
        })

      }}





    //   createPdf():void {
    //     this.dashboardService.createPdf(this.pdf, this.pdf, 'citydata');
    //  }
