import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'editOffers',
  templateUrl: './edit-offers.component.html',
  styleUrls: ['./edit-offers.component.css']
})
export class EditOffersComponent implements OnInit {
  pageTitle='EditOffers'

  @Input('allowedActions') allowedActions: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
