<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css" rel="stylesheet">
    <style>
        .custom-card {
            margin-bottom: 15px;
            /* Reduced gap between cards */
            text-align: center;
            /* Center the text inside the card */
        }

        .card {
            border: 1px solid #ccc;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            padding: 20px;
            margin: 20px;
        }

        .card-content {
            margin-bottom: 20px;
        }

        .image-container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }

        .image-container img {
            width: calc(50% - 5px);
            /* 5px gap between images */
            height: auto;
        }

        .card-footer {
            background-color: #f0f0f0;
            padding: 10px;
            border-top: 1px solid #ccc;
            border-radius: 0 0 8px 8px;
        }

        .custom-card {
            display: flex;
            align-items: center;
            /* Align items vertically in the center */
        }

        .card {
            width: 100%;
            /* Ensures the card takes full width of column */
            height: auto;
            /* Letting the height adjust according to the image */
        }

        .card-img-top {
            width: 100%;
            /* Make both images take full width of the card */
            height: auto;
            /* Maintain aspect ratio */
        }

        @media (max-width: 767px) {
            .custom-card {
                flex-direction: column;
                /* Stack cards vertically on smaller screens */
            }
        }


        .left-image,
        .right-image {
            float: none;
            /* Removed float to align images inline */
            display: block;
            /* Ensures images are treated as block elements */
        }

        .carousel-item img {
            max-height: 100vh;
            /* Adjust the maximum height of the carousel images */
            object-fit: cover;
            /* Ensures the images cover the entire carousel */
        }

        .centered-image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 58vh;
            /* Full viewport height */
            position: relative;
        }

        .centered-image {
            max-width: 50%;
            /* Adjust the size of the image */
            border-bottom: 5px solid black;
        }

        .carousel-item img {
            max-height: 550px;
            /* Adjust the maximum height of the carousel images */
            width: auto;
            /* Maintain aspect ratio */
        }
    </style>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.min.js"></script>
</head>

<body>
    <div class="d-flex py-3 px-5 align-items-center justify-content-between">
        <a class="" href="/">
            <div class="d-flex align-items-center">
                <img class="img-fluid me-2" style="width: 10rem" src="../../assets/images/logo.svg" alt="" />
            </div>
        </a>
        <span style="color: black; font-weight: bold;font-size: 2em">Want to be a Seller ?</span>
        <div class="d-flex justify-content-start gap-3">
            <!-- Login button -->
            <a href="/register-seller" class="btn btn-outline-primary me-2">Register Now As a Seller</a>
            <!-- Register button -->
            <!-- <a href="/register-superchampion" class="btn btn-outline-secondary">Register</a> -->
        </div>
    </div>

    <!-- Navbar with Login Button -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ms-auto">

                </ul>
            </div>
        </div>
    </nav>

    <div class="row">
        <div class="col"></div> <!-- Add padding to the left side -->
        <div class="col-md-10">
            <!-- Your carousel here -->
            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                        class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                        aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"
                        aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4"
                        aria-label="Slide 5"></button> <!-- Added for the fifth image -->
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="../../assets/images/seller1.png" class="d-block w-100" alt="Image 1">
                        <div class="carousel-caption d-none d-md-block">
                            <h5>Caption Title 1</h5>
                            <p>Description for Image 1</p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../assets/images/seller2.png" class="d-block w-100" alt="Image 2">
                        <div class="carousel-caption d-none d-md-block">
                            <h5>Caption Title 2</h5>
                            <p>Description for Image 2</p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../assets/images/seller3.png" class="d-block w-100" alt="Image 3">
                        <div class="carousel-caption d-none d-md-block">
                            <h5>Caption Title 3</h5>
                            <p>Description for Image 3</p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../assets/images/seller4.png" class="d-block w-100" alt="Image 4">
                        <div class="carousel-caption d-none d-md-block">
                            <h5>Caption Title 4</h5>
                            <p>Description for Image 4</p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../assets/images/seller19.png" class="d-block w-100" alt="Image 5">
                        <div class="carousel-caption d-none d-md-block">
                            <h5>Caption Title 5</h5>
                            <p>Description for Image 5</p>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
        <div class="col"></div> <!-- Add padding to the right side -->
    </div>



    <!-- Two images on white background cards -->
    <div class="container mt-5">
        <div class="row">
            <div class="col-md-6 custom-card">
                <div class="card">
                    <img src="../../assets/images/seller5.png" class="card-img-top" alt="Extra Image 1">
                </div>
            </div>
            <div class="col-md-6 custom-card">
                <div class="card">
                    <img src="../../assets/images/seller6.png" class="card-img-top" alt="Extra Image 2">
                </div>
            </div>
        </div>
    </div>


    <!-- Another two cards with images -->
    <div class="container mt-5"> <!-- mt-5 adds a top margin for spacing -->
        <div class="row">
            <div class="col-md-6 custom-card">
                <div class="card">
                    <img src="../../assets/images/seller7.png" class="card-img-top left-image" alt="Extra Image 3">
                </div>
            </div>
            <div class="col-md-6 custom-card">
                <div class="card">
                    <img src="../../assets/images/seller8.png" class="card-img-top right-image" alt="Extra Image 4">
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-5"> <!-- mt-5 adds a top margin for spacing -->
        <div class="row">
            <div class="col-md-6 custom-card">
                <div class="card">
                    <img src="../../assets/images/seller9.png" class="card-img-top left-image" alt="Extra Image 3">
                </div>
            </div>
            <div class="col-md-6 custom-card">
                <div class="card">
                    <img src="../../assets/images/seller10.png" class="card-img-top right-image" alt="Extra Image 4">
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 custom-card">
            <div class="card">
                <img src="../../assets/images/seller11.png" class="card-img-top left-image" alt="Extra Image 5">
            </div>
        </div>
        <div class="col-md-4 custom-card">
            <div class="card">
                <img src="../../assets/images/seller12.png" class="card-img-top right-image" alt="Extra Image 6">
            </div>
        </div>

        <div class="col-md-4 custom-card">
            <div class="card">
                <img src="../../assets/images/seller13.png" class="card-img-top left-image" alt="Extra Image 7">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 custom-card">
            <div class="card">
                <img src="../../assets/images/seller14.png" class="card-img-top left-image" alt="Extra Image 5">
            </div>
        </div>
        <div class="col-md-4 custom-card">
            <div class="card">
                <img src="../../assets/images/seller15.png" class="card-img-top right-image" alt="Extra Image 6">
            </div>
        </div>

        <div class="col-md-4 custom-card">
            <div class="card">
                <img src="../../assets/images/seller16.png" class="card-img-top left-image" alt="Extra Image 7">
            </div>
        </div>
    </div>

    <!-- Single centered image with bottom border -->
    <div class="card">
        <div class="card-content">
            <div class="image-container">
                <img src="../../assets/images/seller17.png" alt="Image 1">
                <img src="../../assets/images/seller18.png" alt="Image 2">
            </div>
        </div>
    </div>


    <footer>
        <div class="container mt-3">
            <!-- Footer links start here -->
            <div class="footer-links">
                <div class="row">
                    <div class="col-sm-6 col-md-3 col-lg-3">
                        <section class="footer-link">
                            <h3>Company</h3>
                            <ul class="list-unstyled">
                                <li><a href="../aboutus">About Us</a></li>
                                <li><a href="#">Best Offers</a></li>
                                <li><a href="#">Blog</a></li>
                                <li><a href="../payments">Terms and Conditions</a></li>
                                <li><a href="../privacypolicy">Privacy Policy</a></li>
                            </ul>
                        </section>
                    </div>

                    <div class="col-sm-6 col-md-2 col-lg-3">
                        <section class="footer-link">
                            <h3>Help</h3>
                            <ul class="list-unstyled">
                                <li><a href="#">My Account</a></li>
                                <li>
                                    <a href="../payments">Terms and Conditions Online Payment</a>
                                </li>
                                <li><a href="#">Wish List</a></li>
                                <li><a href="../contactus">Contact Us</a></li>
                                <li>
                                    <a href="../datarententionpolicy">Data Rentention Policy</a>
                                </li>
                            </ul>
                        </section>
                    </div>
                    <div class="col-sm-6 col-md-5 col-lg-3">
                        <section class="footer-link">
                            <h3>Customer Care</h3>
                            <div class="contact-details d-flex align-items-center">
                                <div class="icon">
                                    <img src="\assets\images\headphone-white-icon.png" alt="headphone-white-icon" />
                                </div>
                                <div class="content">
                                    <span class="title">Hotline Free 24 X 7 </span>
                                    <a class="phone-number text-decoration-none text-light" href="tel:+918600799991">+91
                                        8600799991</a>
                                </div>
                            </div>
                            <div class="contact-details d-flex align-items-center">
                                <div class="icon">
                                    <img src="\assets\images\at-the-rate-white-icon.png" alt="at-the-rate-white-icon" />
                                </div>
                                <div class="content">
                                    <span class="title">Email</span>
                                    <span class="email"><a class="text-decoration-none"
                                            href="mailto:support@candidoffers.com">support</a></span>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="col-sm-6 col-md-2 col-lg-3">
                        <section class="footer-link">
                            <h3>Follow Us</h3>
                            <div class="social-links justify-content-center d-flex align-items-center">
                                <div class="icon">
                                    <a href="https://www.facebook.com">
                                        <i class="bi bi-facebook" aria-hidden="true"></i>
                                    </a>
                                </div>
                                <div class="icon">
                                    <a href="https://www.instagram.com/candidoffers?igsh=Z3lxdHRrbm54dXU2">
                                        <i class="bi bi-instagram" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <!-- Footer links end here -->
        </div>
        <!-- Copyright start here -->
        <div class="copyright">
            <div class="container text-center">
                Copyright &copy; candidoffers.com India Private Limited. All rights
                reserved.
            </div>
        </div>
        <!-- Copyright end here -->
    </footer>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js"></script>
</body>

</html>