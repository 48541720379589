import { Component, ViewChild, Inject, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { ImageTransform } from 'ngx-image-cropper/lib/interfaces/image-transform.interface';
import { ImageCropperDialog } from "../interfaces/image-cropper-dialog";
@Component({
      templateUrl: 'image-cropper-dialog.component.html'
})
export class ImageCropperDialogComponent implements OnInit, AfterViewInit{
    imageChangedEvent: any = '';
    croppedImage: any = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = true;
    transform: ImageTransform = {};
    dialogResponse:object = {"status":1,"croppedImage":""}
    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
    @ViewChild("fileInput") fileInput: ElementRef;

    constructor(public dialogRef: MatDialogRef<ImageCropperDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ImageCropperDialog ){
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.fileInput.nativeElement.click();
    }

    closeDialog(isCancelled): void {
        if(isCancelled){
            this.dialogResponse['status'] = 1;
            this.dialogResponse['croppedImage'] = "";
        }else{
            this.dialogResponse['status'] = 2;
            this.dialogResponse['croppedImage'] = this.croppedImage;
        }
        this.dialogRef.close(this.dialogResponse);
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        //console.log('filechange');
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        //console.log(event);
    }
    imageLoaded() {
        this.showCropper = true;
        //console.log('Image loaded')
    }
    cropperReady() {
        //console.log('Cropper ready')
    }
    loadImageFailed () {
        //console.log('Load failed');
    }
    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }
    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }
}