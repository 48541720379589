import { Component, Output } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from "../services/alert.service";
import { CommonService} from "../services/common.service";
@Component({
      templateUrl: 'compselection.component.html'
})
export class CompanySelectionComponent{
      companiesFinancialYears:any[];
      allowedCompanies: any[];
      isActive:boolean = false;
      selectedCompanyIndex:number = null;
      selectedYearIndex:number = null;
      constructor(public dialogRef: MatDialogRef<CompanySelectionComponent>, private location: Location, private alertSerive: AlertService, private commonService: CommonService ){
            let userSessionData = JSON.parse(localStorage.getItem('userData'));
            this.allowedCompanies = userSessionData['user']['allowedCompanies'];
            if(this.allowedCompanies.length === 1){
                  this.companiesFinancialYears = this.allowedCompanies[0]['allowedFinancialYears'];
                  this.selectedCompanyIndex = 0;
            }
      }

      closedDialog(): void {
            this.dialogRef.close()
      }

      companySelection( forIndex ){
            this.companiesFinancialYears = this.allowedCompanies[forIndex]['allowedFinancialYears'];
            this.selectedCompanyIndex = forIndex;
      }

      yearSelection(forIndex){
            this.selectedYearIndex = forIndex;
      }

      newCompanySelection():void{
            if(this.selectedCompanyIndex === null || this.selectedYearIndex === null){
                  if(this.selectedCompanyIndex === null){
                        this.alertSerive.create("Company Selection Error","error",0,"Please select company");
                  }
                  if(this.selectedYearIndex === null){
                        this.alertSerive.create("Year Selection Error","error",0,"Please select financial year");
                  }
            }else{
                  // Update the current company parameter and year.
                  let newCurrentCompanyData = {
                        "companyId":this.allowedCompanies[this.selectedCompanyIndex]['companyId'],
                        "companyName":this.allowedCompanies[this.selectedCompanyIndex]['companyName'],
                        "companyCode":this.allowedCompanies[this.selectedCompanyIndex]['companyCode'],
                  }
                  let newFinancialYearData = this.allowedCompanies[this.selectedCompanyIndex]['allowedFinancialYears'][this.selectedYearIndex]
                  
                  localStorage.setItem('currentCompanyData',JSON.stringify(newCurrentCompanyData));
                  localStorage.setItem('currentFinancialYear',JSON.stringify(newFinancialYearData));
                  let userSessionData = JSON.parse(localStorage.getItem('userData'));
                  userSessionData['user']['currentSelectionData']['company'] = newCurrentCompanyData;
                  userSessionData['user']['currentSelectionData']['financialYear'] = newFinancialYearData;
                  localStorage.setItem('userData',JSON.stringify(userSessionData));
                  localStorage.setItem('currentBranchData','');
                  if(this.allowedCompanies[this.selectedCompanyIndex]['isMultiBranch'] === 'Y' && this.allowedCompanies[this.selectedCompanyIndex]['defaultBranch'].hasOwnProperty('branchId')){
                        localStorage.setItem('currentBranchData',JSON.stringify(this.allowedCompanies[this.selectedCompanyIndex]['defaultBranch']));
                  }
                  this.commonService.updateCurrentSelectionData(JSON.stringify(userSessionData['user']['currentSelectionData'])).subscribe(
                        (result) => {
                              if(result['responseStatus'] == 'Ok'){
                                    location.reload();
                              }else{
                                    this.alertSerive.create("Current Selection Update Failed","error",0,result['message']);
                              }
                        },
                        (error) => {
                              //console.log("E::"+error);
                              this.alertSerive.create("API Error","error",0,error);
                        }
                  );
                  //location.reload();
            }
      }
}
